'use strict';

import Component from '_core_ext/components/Component';

export default class LinksSelector extends Component {
    init() {
        super.init();
        this.$items = this.$el.find('.js-airport-selector-items');
        this.event('click', '.js-airport-selector', () => {
            this.$items.toggleClass('h-hidden');
            if (this.$items.hasClass('h-hidden')) {
                this.$el.removeClass('selector--opened');
            } else {
                this.$el.addClass('selector--opened');
            }
        });
    }
}
module.exports = LinksSelector;