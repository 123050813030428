import InputText from '_core_ext/components/forms/InputText';
import eventMgr from '_core_ext/eventMgr';

const brandRefinementEmitter = eventMgr.getEmitter('brandrefinement');

export default class RefinementSearch extends InputText {
    init() {
        super.init();
        this.event('keyup', this.onKeyUp);
        this.event('keypress', this.onSubmitBrandSearch);
    }

    //
    // Event handlers
    //

    onKeyUp() {
        let value = this.getValue(),
            refinementRoot = this.$el.closest('.refinement').find('.js-refinement-root'),
            refinementValues = refinementRoot.find('.js-refinement-value');

        if (value) {
            refinementValues
                .addClass('h-hidden')
                .filter(function () {
                    let refID = $(this).data('ref-id');

                    if (refID.match(new RegExp(value, 'gi'))) {
                        return true;
                    }
                    return false;
                })
                .removeClass('h-hidden');

        } else {
            refinementValues.removeClass('h-hidden');
        }
    }

    onSubmitBrandSearch() {
        let keycode = event.keyCode ? event.keyCode : event.which;
        //if 'enter' keypress is detected, then apply refinements with all matching brands.
        if (keycode == 13) {
            event.preventDefault();

            let refinementRoot = this.$el.closest('.refinement').find('.js-refinement-root'),
                refinementValues = refinementRoot.find('.js-refinement-value'),
                matchingRefimentValues = refinementValues.not('.h-hidden');

            if (matchingRefimentValues.length > 0 && this.getValue()) {
                matchingRefimentValues.addClass('selected');
                matchingRefimentValues.find('input').prop('checked', true);

                brandRefinementEmitter.emit('submit');
            }
        }
    }
}
