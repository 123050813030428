import InputGeneric from './InputGeneric';

export default class InputEmail extends InputGeneric {
    getValue () {
        return this.$field.val() ? this.$field.val().trim() : '';
    }
    setValue (value = '') {
        this.$field.val(value.trim());
    }

}
