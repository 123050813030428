import Block from '../common/Block';
import {each} from 'jquery';

export default class FormGeneric extends Block {
    getSubmitButtonName() {
        return this.submitButtonName;
    }
    init() {
        super.init();
        this.submitButtonName = null;
        if (this.$el.is('form')) {
            this.event('submit', this.onSubmit);
            this.event('click', 'button, input[type="button"], input[type="submit"]', this.saveSubmitButton);
        }

        if (this.config.match) {
            each(this.config.match, (key, item) => {
                this.getById(key, cmp1 => {
                    this.getById(item.field, cmp2 => {
                        cmp1.setMatchCmp(cmp2, item);
                    });
                });
            });
        }

        if (this.config.country) {
            this.getById(this.config.country.main, cmp1 => {
                this.getById(this.config.country.secondary, cmp2 => {
                    cmp1.setSecondaryCmp(cmp2);
                });
            });
        }
    }
    saveSubmitButton(el) {
        this.submitButtonName = el.name;
    }
    submit(actionName) {
        this.$el.find('[name=' + actionName + ']').trigger('click');
    }
    onSubmit() {
        return this.isChildsValid(() => this.emitter.emit('submit'));
    }
    getFormFields() {
        var fields = {};

        this.eachChild(cmp => {
            if (!(cmp.skipSubmission && cmp.skipSubmission())) {
                const name = cmp.getName && cmp.getName();

                if (name) {
                    fields[name] = cmp.getValue();
                }
            }
        });
        return fields;
    }
    isChildsValid(cb) {
        var valid = true;

        this.eachChild(item => {
            if (typeof item.validate === 'function') {
                if (!item.validate()) {
                    if (valid && item.setFocus) {
                        item.setFocus();
                    }
                    valid = false;
                }
            }
        });
        if (valid && typeof cb === 'function') {
            cb();
        }
        return valid;
    }
    validate() {
        return this.isChildsValid();
    }
    isValid() {
        var valid = true;

        this.eachChild(itemCmp => {
            if (typeof itemCmp.isValid === 'function' && !itemCmp.isValid()) {
                valid = false;
            }
        });
        return valid;
    }
    setFocus() {
    }
    onlyValid(fn) {
        return (arg1, arg2) => this.isChildsValid(fn.bind(this, arg1, arg2));
    }
}
