'use strict';
import Component from '_core_ext/components/Component';

export default class Block extends Component {
    init() {
        super.init();
    }
    /**
     * Assign event action for child component
     * @param {String} childID
     * @param {String} eventName
     * @param {function} fn
     */
    onChild(childID, eventName, fn) {
        this.getById(childID, chld => {
            chld.on(eventName, fn.bind(this));
        });
    }
    onChilds(childComponentClass, eventName, fn) {
        this.eachChildDeep(chld => {
            if (chld instanceof childComponentClass) {
                chld.on(eventName, fn.bind(this));
            }
        });
    }
    offChilds(childComponentClass, eventName, fn) {
        this.eachChildDeep(chld => {
            if (chld instanceof childComponentClass) {
                chld.off(eventName, fn.bind(this));
            }
        });
    }
    offChild(childID, eventName, fn) {
        this.getById(childID, chld => {
            chld.off(eventName, fn);
        });
    }
}
