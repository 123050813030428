import InputText from '_core_ext/components/forms/InputText';

export default class InputTextGV extends InputText {
    init () {
        super.init();
    }
    setRequired (isRequired) {
        this.config.required = isRequired;
    }
}
