'use strict';

import Component from '_core_ext/components/Component';

export default class SocialShare extends Component {
    init() {
        super.init();
        this.event('click', '.js-copy-to-clipboard', this.copyToClipboard);
    }

    //
    // Event handlers.
    //
    copyToClipboard(element) {
        var el = document.getElementById('product-url-to-copy'),
            isiOSDevice = navigator.userAgent.match(/ipad|iphone/i),
            range, selection, success;

        if (isiOSDevice) {
            el.readOnly = false;
            el.contentEditable = true;
            range = document.createRange();
            range.selectNodeContents(el);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            el.setSelectionRange(0, 999999);
            success = document.execCommand('copy');
        } else {
            el.select();
            success = document.execCommand('copy');
        }

        //in case it is unsupported browser, allow user to copy selected text manually
        if (success) {
            window.getSelection().removeAllRanges();
            el.readOnly = true;
            el.contentEditable = false;
        }

        $(element).addClass('h-hidden');
        this.$el.find('.js-copied-to-clipboard').removeClass('h-hidden');
    }
}
module.exports = SocialShare;
