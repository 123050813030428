import InputGeneric from './InputGeneric';
import $ from 'jquery';

const lockClass = 'js-locked-select';

export default class InputSelect extends InputGeneric {
    init () {
        super.init();
        this.event('change', this.onChange);
    }
    onChange (el, event) {
        if (!this.config.dontprevent) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.update();
    }
    getSelectedData (property) {
        return this.$el.find('option:selected').data(property);
    }
    setValue (newVal, silently) {
        if (newVal === undefined) {
            newVal = this.initValue;
        }
        super.setValue(newVal, silently);
    }
    getText() {
        return this.$field.find('option:selected').text();
    }
    cleanOptions () {
        this.$field.find('option').remove();
    }
    setOptions (options) {
        this.cleanOptions();
        this.$field.append(options.map(option =>
            `<option
                class="f-select-option"
                label="${option.label}"
                value="${option.value}">
                    ${option.label}
            </option>`
        ).join(''));
    }
    lock () {
        if (!this.locked) {
            super.lock();
            this.$field.attr('disabled', true);
            this.$lockInput =
                $(`<input class="${lockClass}" type="hidden" name="${this.getName() ||
                ''}" value="${this.getValue()}"/>`);
            this.$field.append(this.$lockInput);
        }
    }
    unlock () {
        if (this.locked) {
            super.unlock();
            this.$field.attr('disabled', false);
            this.$lockInput.remove();
        }
    }
}
