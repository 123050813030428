'use strict';

import Component from '_core_ext/components/Component';

export default class ProductSearchResult extends Component {
    init() {
        super.init();
        this.eventMgr('filterform.updatesearchresult', this.updateSearchResult);
        this.eventMgr('sortform.updatesearchresult', this.updateSearchResult);
        this.eventMgr('flightdetails.reloadpage', this.reload);
    }

    //
    // Event handlers.
    //
    reload() {
        location.reload();
    }

    updateSearchResult(html) {
        if (html) {
            this.$el.replaceWith(html);
        }
    }
}
