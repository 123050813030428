'use strict';

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';
import util from '_core_ext/util';
import {redirect as pageRedirect} from '_core_ext/page';

import {assign} from 'lodash';

export default class AccountMenu extends Component {
    init() {
        super.init();

        this.initCache();

        this.config = assign({}, Constants.ACCOUNT_MENU.defaultConfig, this.config);

        this.event('touchstart', this.cache.accountTitleClass, this.onTouch);
        this.event('mouseover', this.onMouseover);
        this.event('mouseout', this.onMouseout);
        this.event('click', this.cache.loginPostponeClass, this.onClick);
        this.event('click', '.js-user-logout', this.clearFlightDetailsCookies);
        this.event('touchstart', '.js-user-logout', this.clearFlightDetailsCookies);
        this.eventMgr('flightdetailslinkaccount.hidemenu', this.onTouch);
    }

    initCache() {
        this.cache = {};
        this.cache.userInfoLinks = this.$el.find('.js-account-links');
        this.cache.accountTitleClass = '.js-account-title';
        this.cache.loginPostponeClass = '.js-login-postpone';
    }

    //
    // Event handlers.
    //

    clearFlightDetailsCookies(element, event) {
        event.preventDefault();
        let url = $(element).prop('href');

        util.removeCookie('fd');
        pageRedirect(url);
    }

    onTouch(element, event) {
        if (event) {
            event.preventDefault();
        }

        if (this.cache.userInfoLinks.is(':visible')) {
            this._fadeOutLinks();
        } else {
            // hide main nav if open:
            $('body').removeClass('nav-active');
            $('.js-mobile-menu-category').removeClass('b-header-category--active');
            $('.js-overlay-menu').removeClass('active');
            // close cart flyout:
            $('.js-mini-cart-content-close').trigger('click');

            this._fadeInLinks();
        }

    }

    onMouseover() {
        this._fadeInLinks();
    }

    onMouseout() {
        this._fadeOutLinks();
    }

    onClick() {
        this._fadeOutLinks();
    }

    //
    // Internally used functions.
    //

    _fadeInLinks() {
        this.cache.userInfoLinks
            .stop(true)
            .fadeIn(this.config.fadeInSpeed);

        this.cache.userInfoLinks.parent().addClass('menu-active');
    }

    _fadeOutLinks() {
        this.cache.userInfoLinks
            .stop(true)
            .fadeOut(this.config.fadeOutSpeed);

        this.cache.userInfoLinks.parent().removeClass('menu-active');
    }
}

module.exports = AccountMenu;
