'use strict';

import Component from '_core_ext/components/Component';

export default class GoBackShipping extends Component {
    init() {
        super.init();
        this.step = this.$el.data('step');
        this.event('click', '.js-billing-button-goback', this.goBack);
    }

    goBack (el, e) {
        e.preventDefault();
        if (this.step) {
            window.localStorage.setItem('fdStep', this.step);
            window.location.href = this.$el.attr('data-url');
        }
    }
}