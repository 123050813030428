'use strict';

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';

import {assign} from 'lodash';
import {appendParamsToUrl} from '_core_ext/util';
import {redirect as pageRedirect} from '_core_ext/page';

let timer;
const eventMgr = require('_core_ext/eventMgr');
const emitter = eventMgr.getEmitter('searchbar');

export default class Search extends Component {
    init() {
        super.init();
        this.initCache();
        this.config = assign({}, Constants.SEARCH.defaultConfig, this.config);

        this.event('keyup focus', '.js-search-field', this.onKeyup);
        this.event('focusout', '.js-search-field', this.onFocusOut);
        this.event('click', null, this.onClick, $document);
        this.event('click', 'button[type="submit"]', this.onSubmit);
        this.event('keypress', this.onSubmitWithKeyPress);
        this.event('click', '.js-clear-search-phrase', this.onClear);
        this.event('click', '.js-cancel-simple-search', this.onCancel);
        this.event('click', '.js-search-field', this.onActive);
        this.event('click', '.js-continue-shopping', function () {
            pageRedirect(Urls.homepageRedirect);
        });
    }

    initCache() {
        this.cache = {};
    }

    onSubmit() {
        let input = this.$el.find('input[type="text"]');

        if (!input.val()) {
            input.focus();
            return false;
        }

        return true;
    }

    //This function handles when enter key is pressed when search is in focus.
    onSubmitWithKeyPress(element, event) {
        let keycode = event.keyCode ? event.keyCode : event.which;

        if (keycode === 13) {
            event.preventDefault();
            if (this.onSubmit()) {
                $(element).find('form').submit();
            }
        }
    }

    onClear(element, event) {
        event.preventDefault();
        $('.js-search-field').val('');
        this._makeAjax();
    }

    onCancel(element, event) {
        event.preventDefault();
        $('div').not('.js-search-container').eq(0).trigger('click');
    }

    onClick(element, event) {
        if ($(event.target).parents('.js-search-suggestions').length === 0) {
            this._patchSuggestions('');
        }

        if ($('.js-search-container').has(event.target).length === 0) {
            this.$el.removeClass('active-search');
            $('body').removeClass('active-search');
            $('div[data-cmp="stickyHeader"]').removeClass('is-active-search');
            $('.js-cancel-simple-search').addClass('d-none');
        }
    }

    onActive() {
        this.$el.addClass('active-search');
        $('body').removeClass('nav-active');
        $('body').addClass('active-search');
        $('div[data-cmp="stickyHeader"]').addClass('is-active-search');
        $('.js-cancel-simple-search').removeClass('d-none');

        //ajax call to render recent and trending search phrases when no search query entered.
        if (!$('.js-search-field').val()) {
            this._makeAjax();
        }
    }

    onKeyup(element) {
        //ajax call to render recent and trending search phrases when no search query entered.
        if (element.value) {
            let value = element.value;

            if (value.match(/[\(\‘;){}\[\]<>]/)) {
                $(element).val(value.replace(/[\(\‘;){}\[\]<>]/g, ''));
                value = $(element).val();
            }
            if (value.length >= this.config.startLetter) {
                let self = this;

                clearTimeout(timer);

                // Ajax with some delay to decrease count of requests while customer
                // is typing text.
                timer = setTimeout(function () {
                    self._makeAjax({
                        q: value
                    });
                }, this.config.ajaxDelay);
            } else {
                this._patchSuggestions('');
            }
            emitter.emit('hidefdinfopopup');
        } else {
            this._makeAjax();
        }
    }

    onFocusOut() {
        emitter.emit('showfdinfopopup');
    }

    _makeAjax(params) {
        let self = this;

        $.ajax({
            url: appendParamsToUrl(Urls.searchsuggest, params)
        }).done(function (data) {
            if (data) {
                self._patchSuggestions(data);
            }
        });
    }

    _patchSuggestions(html) {
        this.$el.find('.js-search-suggestions').html(html);
    }
}

module.exports = Search;
