'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('promotion');

export default class PromotionGtm extends Component {
    init() {
        super.init();

        this.event('click', 'a', this.promotionLickClicks);
    }

    promotionLickClicks() {
        emitter.emit('clicked', this);
    }
}
