module.exports = {
    /**
     * @description starts functions execution from list of provided functions
     * @param functionsList {Array} List of functions names
     * @param ObjectWithFunctions {Object} contains collection of named functions
     */
    initFunctionsFromObject: function (functionsList, ObjectWithFunctions, args) {
        if (functionsList && ObjectWithFunctions) {
            var functionsListLength = functionsList.length,
                i = 0;

            for (; i < functionsListLength; i++) {
                var functionName = functionsList[i];
                if (functionName in ObjectWithFunctions) {
                    ObjectWithFunctions[functionName].apply(this, args);
                }
            }
        }
    }
};