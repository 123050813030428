import InputGeneric from './InputGeneric';
import util from '../../util';

export default class InputTextarea extends InputGeneric {
    init () {
        super.init();
        if (this.config.countChar && this.config.maxlength) {
            this.event('keyup', '.js-input_field', this.handleKeyUp);
            this.event('keydown', this.onKeyDown);
            this.handleKeyUp();
        }

        this.event('blur', '.js-input_field', () => this.emitter.emit('blur', this));
    }
    handleKeyUp () {
        var currentValue = (this.getValue() + '').replace(/\r?\n/g, '\r\n');

        if (currentValue.length > this.config.maxlength) {
            currentValue = currentValue.substring(0, this.config.maxlength);
            this.setValue(currentValue);
        }

        this.$el.find('.f-field-wrapper .f-count_chart').remove();
        this.$el.find('.f-field-wrapper textarea').after(
            `<span class="f-count_chart">
                ${
                    util.format(
                       this.config.countCharText,
                       this.config.maxlength - currentValue.length,
                       this.config.maxlength
                    )
                }
            </span>`);
    }
    onKeyDown (elem, e) {
        e.stopPropagation();
    }
}
