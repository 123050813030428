import Component from '_core_ext/components/Component';

export default class TimePicker extends Component {
    init() {
        super.init();
        this.label = this.$el.data('label');
        this.$value = this.$el.find('.js-timepicker-value');
        this.value = 0;
        this.fillData();
        this.event('click', '.js-timepicker-button-up', this.up);
        this.event('click', '.js-timepicker-button-down', this.down);
    }

    fillData() {
        let now = new Date();

        this.$el.find('.js-timepicker-label').text(this.label);
        if (this.label === 'h') {
            this.value = now.getHours();
        } else {
            this.value = this.getMinutes(now.getMinutes());
        }
        this.render();
    }

    getMinutes(now) {
        let result = 45;

        if (now >= 0 && now < 15) {
            result = 0;
        } else if (now >= 15 && now < 30) {
            result = 15;
        } else if (now >= 30 && now < 45) {
            result = 30;
        }
        return result;
    }

    up () {
        this.value = +this.$el.attr('data-value');

        if (this.label === 'h') {
            if (this.value === 23) {
                this.value = 0;
            } else {
                this.value++;
            }
        } else {
            if (this.value === 45) {
                this.value = 0;
            } else {
                this.value += 15;
            }
        }
        this.render();
    }

    down () {
        this.value = +this.$el.attr('data-value');

        if (this.label === 'h') {
            if (this.value === 0) {
                this.value = 23;
            } else {
                this.value--;
            }
        } else {
            if (this.value === 0) {
                this.value = 45;
            } else {
                this.value -= 15;
            }
        }
        this.render();
    }

    render () {
        this.$value.text(`0${this.value}`.slice(-2));
        this.$el.attr('data-value', `0${this.value}`.slice(-2));
    }
}
