'use strict';

import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('reorder');

export default class Reorder extends Component {
    init () {
        super.init();

        this.event('click', '.js-reorder', this.reorder);
    }

    /**
     * Makes ajax call to add product to the shopping cart with quantity
     * which was in order.
     *
     * And throws two events
     * 1) success -- if product was added to the cart.
     * 2) fail -- if product was not added by some reason.
     */
    reorder(el, event) {
        event.preventDefault();

        let
            $el = $(el),
            addToCartURL = util.ajaxUrl(Urls.addProduct),
            productID = $el.data('product-id'),
            productQty = $el.data('product-qty');

        $.ajax({
            url: addToCartURL,
            type: 'POST',
            data: {
                pid: productID,
                Quantity: productQty,
                cartAction: 'add'
            }
        }).done(function () {
            emitter.emit('success', null, true, true);
        }).fail(function () {
            emitter.emit('fail');
        });
    }
}
