'use strict';

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';
import eventMgr from '_core_ext/eventMgr';
import {assign} from 'lodash';

const emitter = eventMgr.getEmitter('minicarttotal');

export default class MiniCartTotal extends Component {
    init() {
        super.init();

        this.config = assign({}, Constants.MINI_CART_TOTAL.defaultConfig, this.config);

        this.event('mouseover', this.onMouseOver);
        this.event('mouseleave', this.onMouseLeave);
        this.event('touchstart', this.onTouch);
    }

    onTouch(element) {
        event.preventDefault();
        emitter.emit('touchstart', this._getHrefElement(element));
    }

    onMouseOver(element) {
        this.$el.attr('hovered', true);
        emitter.emit('mouseover', this._getHrefElement(element));
    }

    onMouseLeave() {
        this.$el.removeAttr('hovered');
        emitter.emit('mouseleave');
    }

    _getHrefElement(element) {
        return $(element).find('.js-mini-cart-link');
    }
}
