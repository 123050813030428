import eventMgr from '_core_ext/eventMgr';
import debounce from 'lodash/debounce';
import {find} from 'lodash';

const mobileId = 'mobile',
    tabletId = 'tablet',
    desktopId = 'desktop',
    mediaM = `screen and
        (max-width: ${window.Constants.breakpointMobile - 1}px)`,
    mediaT = `screen and (min-width: ${window.Constants.breakpointMobile}px) and
        (max-width: ${window.Constants.breakpointTablet}px)`,
    mediaD = `screen and (min-width: ${window.Constants.breakpointDesktop}px)`,
    emitter = eventMgr.getEmitter('screenDetector'),
    queriesMap = [
        [mobileId, mediaM],
        [tabletId, mediaT],
        [desktopId, mediaD]
    ];

const getCurrentDeviceType = () => {
    const matchQuery = find(queriesMap, ([, query]) => window.matchMedia(query).matches);

    return matchQuery && matchQuery[0];
};

const ScreenDetector = {
    currentDevice: getCurrentDeviceType(),
    MOBILE: mobileId,
    TABLET: tabletId,
    DESKTOP: desktopId,
    getCurrentDeviceType: getCurrentDeviceType,
    init: () => {
        const applyCurrentDeviceType = debounce(() => ScreenDetector.matchViewport(getCurrentDeviceType()), 100);

        queriesMap.forEach(([, query]) => window.matchMedia(query).addListener(applyCurrentDeviceType));
        return this;
    },
    /**
     * @returns {String}
     */
    getDeviceType: () => ScreenDetector.currentDevice,
    isTouchDevice: () => ('ontouchstart' in window) ||
        window.DocumentTouch && document instanceof window.DocumentTouch,
    isMobileView: () => ScreenDetector.getDeviceType() === ScreenDetector.MOBILE,
    isTabletView: () => ScreenDetector.getDeviceType() === ScreenDetector.TABLET,
    isDesktopView: () => ScreenDetector.getDeviceType() === ScreenDetector.DESKTOP,
    matchViewport(newDevice) {
        const previousDevice = this.currentDevice;

        this.currentDevice = newDevice;
        emitter.emit(newDevice, newDevice);
        if (previousDevice !== this.currentDevice) {
            emitter.emit('deviceChange', this.currentDevice);
        }
    }
};

module.exports = ScreenDetector;
