'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('infinitescroll');

export default class InfiniteScroll extends Component {
    init() {
        super.init();
        this.event('click', this.onClick);
        this.clickCounter = 0;
    }

    onClick() {
        let self = this;

        this.clickCounter++;

        if (this.clickCounter !== 1) {
            return;
        }

        $.ajax({
            url: this.config.gridUrl
        }).done(function (html) {
            if (!html) {
                return;
            }

            self.$el.remove();
            self.clickCounter--;

            emitter.emit('updatesearchitems', html);
            emitter.emit('updateparinationinfo', self.config.pageSize);

            return;
        });
    }
}
