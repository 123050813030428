// YouTube player constants
exports.YT = {
    apiLink: '//www.youtube.com/iframe_api',
    ID: 'ytscript',
    defaultConfig: {
        width: 480,
        height: 640,
        autoplay: 1,
        controls: 1,
        disablekb: 1,
        loop: 0,
        modestbranding: 1,
        showinfo: 0,
        color: 'white',
        theme: 'light',
        rel: 0,
        thumbnail: 0,
        mute: 0
    }
};

exports.ACCOUNT_MENU = {
    defaultConfig: {
        fadeInSpeed: 370,
        fadeOutSpeed: 370
    }
};

exports.MINI_CART = {
    defaultConfig: {
        dbTouchDelay: 500, // Delay of detection on double touch.
        delayShowFlyout: 0, // Delay before show flyout to have a time for click.
        updateDelay: 370 // Delay to prevent a lot of ajax calls.
    }
};

exports.MINI_CART_TOTAL = {
    defaultConfig: {
        updateDelay: 370 // Delay to prevent a lot of ajax calls.
    }
};

exports.ACCORDION = {
    defaultConfig: {
        disableTitleLinks: false, // Disable default action if title has link and was clicked.
        keepOne: true, // Keep only one root menu expanded.
        expandedClass: 'expanded',
        collapseChilds: true,
        hiddenClass: 'desktop'
    }
};

exports.GLOBAL = {
    hiddenClass: 'h-hidden',
    hiddenMobileClass: 'h-hidden-mobile',
    spaceChar: '\u0020'
};

exports.CAROUSEL = {
    defaultConfig: {
        autoplay: false,
        customClass: '',
        dots: false,
        infinite: true,
        speed: 300,
        autoplaySpeed: 5000,
        slidesToShow: 4,
        slidesToScroll: 1,
        swipeToSlide: false,
        centerMode: false,
        responsive: [{
            breakpoint: 4000,
            settings: {
                slidesToShow: 4
            }
        }, {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 1
            }
        }]
    }
};

exports.SEARCH = {
    defaultConfig: {
        ajaxDelay: 300, // Delay ajax call to decrease count of calls
        startLetter: 3 // Start search from letter 3
    }
};

exports.STICKY_HEADER = {
    defaultConfig: {
        stickyClass: 'sticky',
        paddingClass: 'pd'
    }
};
