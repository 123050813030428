function getProductData(analyticsData, params) {
    let productData;
    try {
        if (params && params.cmp && params.cmp.config && params.cmp.config.productdetails) {
            productData = params.cmp.config.productdetails;
        } else {
            const productStr = analyticsData.product;
            productData = JSON.parse(productStr);
        }
        if (params && params.quantity) {
            productData.quantity = params.quantity;
        } else {
            productData.quantity = 1;
        }
    } catch (e) {
        productData = {};
    }
    return productData;
}

function getImpressions(listValue) {
    const products = $('.js-product-gtm:not(.js-gtm-send)');
    const impressions = [];
    for (var i = 0; i < products.length; i++) {
        const product = products[i];
        const productData = $(product).data('productdetails');
        productData.position = i + 1;
        productData.list = listValue;
        impressions.push(productData);
    }
    return impressions;
}

function attachPdpData(eventObj, analyticsData) {
    if (eventObj && eventObj.page && eventObj.page.category && eventObj.page.category === 'product - pdp') {
        eventObj.ecommerce = {
            currencyCode: analyticsData.page ? analyticsData.page.currencyCode : '',
            detail: {
                actionField: {"list": "category"},
                products: [getProductData(analyticsData)]
            }
        };
        const impressions = getImpressions('recommendation');
        if (impressions.length) {
            eventObj.ecommerce.impressions = impressions;
        }
    }
}

function attachPlpData(eventObj, analyticsData) {
    if (eventObj && eventObj.page && eventObj.page.category && (eventObj.page.category === 'product - plp' || eventObj.page.category === 'product - search')) {
        const listValue = eventObj.page.category === 'product - search' ? 'search list' : 'category list';
        eventObj.ecommerce = {
            currencyCode: analyticsData.page ? analyticsData.page.currencyCode : '',
            impressions: getImpressions(listValue)
        };
    }
}

function attachPromoView(/*eventObj, analyticsData*/) {

}

function attachCheckoutData(eventObj, analyticsData) {
    if (eventObj && eventObj.page && eventObj.page.category && eventObj.page.category === 'product - checkout') {
        eventObj.ecommerce = {
            checkout: {
                actionField: {step: $('.b-checkout-progress-indicator').data('gtmstep')},
                products: analyticsData.basket.line_items
            },
            currencyCode: analyticsData.page ? analyticsData.page.currencyCode : ''
        };
    }
}

function attachPurchasesData(eventObj, analyticsData) {
    if (eventObj && eventObj.page && eventObj.page.category && eventObj.page.category === 'product - purchase') {
        eventObj.ecommerce = {
            currencyCode: analyticsData.page ? analyticsData.page.currencyCode : '',
            purchase: {
                actionField: {
                    id: analyticsData.transactionId,
                    affiliation: analyticsData.affiliation,
                    revenue: analyticsData.transactionTotal,
                    tax: analyticsData.transactionTax,
                    shipping: analyticsData.transactionShipping,
                    coupon: analyticsData.couponCodes
                },
                products: analyticsData.transactionProducts
            }
        };
    }
}

function getUserActionEvent(event, analyticsData) {
    const target = $(event.target);
    const link = target.closest('a');
    const url = link ? link.get(0).href : null;
    const productData = target.closest('.js-product-gtm').data('productdetails');
    let eventObj;

    if (!(link || productData)) {return;}

    if (productData) {
        eventObj = {
            event: 'userAction',
            click: {
                category: 'product',
                action: 'click',
                label: productData.name || 'Product details'
            },
            ecommerce: {
                currencyCode: analyticsData.page ? analyticsData.page.currencyCode : '',
                click: {
                    actionField: {list: "product list"},
                    products: [productData]
                 }
            }
        };
    } else if (url && url.match(/^mailto:/)) {
        eventObj = {
            event: 'userAction',
            click: {
                category: 'contact',
                action: 'email',
                label: url.replace(/^mailto:/,'')
            }
        };
    } else if (url && url.match(/^tel:/)) {
        eventObj = {
            event: 'userAction',
            click: {
                category: 'contact',
                action: 'phone',
                label: url.replace(/^tel:/,'')
            }
        };
    } else if (target.closest('.b-social-share').length) {
        eventObj = {
            event: 'userAction',
            click: {
                category: 'social',
                action: 'follow',
                label: $(link).text().toString().trim()
            }
        };
    } else if (target.closest('.b-footer').length) {
        eventObj = {
            event: 'userAction',
            click: {
                category: 'footer',
                action: 'click',
                label: $(link).text().toString().trim()
            }
        };
    } else if (target.closest('.b-header-navigation').length) {
        eventObj = {
            event: 'userAction',
            click: {
                category: 'nav bar',
                action: 'click',
                label: $(link).text().toString().trim()
            }
        };
    }

    return eventObj;
}

function getVpvPath(url) {
    const path = window.pageContext.PV.path.replace(/\/$/, '');
    const basePath = path + '?ga_action=view&ga_target=';
    const endpoint = url.replace(/.*\//,'').replace(/\?.*/,'');

    const vpvPathMap = {
        'Cart-ShowAddProductConfirmation': 'add_to_cart_confirmation',
        'FlightDetails-Countries': 'select_language',
        'FlightDetails-Show': 'flight_details'
    };

    return basePath + (vpvPathMap[endpoint] || endpoint);
}

module.exports = {
    attachPdpData: attachPdpData,
    attachPlpData: attachPlpData,
    attachPromoView: attachPromoView,
    attachCheckoutData: attachCheckoutData,
    attachPurchasesData: attachPurchasesData,
    getProductData: getProductData,
    getUserActionEvent: getUserActionEvent,
    getVpvPath: getVpvPath
};