import Dialog from '_core_ext/components/common/Dialog';
import {redirect as pageRedirect} from '_core_ext/page';

export default class AuthipayDialog extends Dialog {
    init() {
        super.init();

        // Event listener for Authipay's iFrame
        window.addEventListener('message', handleMessage, false);
        function handleMessage(event) {
            if (event.origin !== window.location.origin) {
                return;
            }

            if (event.data.redirectURL) {
                pageRedirect(event.data.redirectURL);
            }
        }

        this.eventMgr('dialog.error', function(error) {
            if(error === 'attempts-limit-reached') {
                $('.js-save-credit-cart-limitation-message').removeClass('h-hidden');
            }
        });
    }
    close() {
        return false;
    }
}
