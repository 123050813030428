'use strict';

import Component from '_core_ext/components/Component';
 
export default class DeleteSelectedRefinement extends Component {
    init() {
        super.init(); 
        this.event('click', 'button', this.onClick);
    }
 
    onClick(element) {
        event.preventDefault();
        var valueToUncheck = $(element).closest('li').data('name');
        var allSelectedRefinements = $('.b-refinement__section.refinement').find('input:checked');
        this.uncheckRefinementValue( allSelectedRefinements, valueToUncheck );        
    }
    
    uncheckRefinementValue(refinementValues, valueToUncheck) {
        refinementValues.each(function() {
            let $this = $(this);

            if($this.val() == valueToUncheck) {
                $this.prop('checked', false);
                $this.closest('form').submit();
                return false;
            } 
        });  
    }
}
