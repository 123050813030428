'use strict';

import Component from '_core_ext/components/Component';

export default class CountrySelectListener extends Component {
    init() {
        super.init();
        this.$sel1 = $('.js-fd-connection-item-1');
        this.$sel2 = $('.js-fd-connection-item-2');
        this.$sel3 = $('.js-fd-connection-item-3');
        this.$val1 = $('.js-fd-connection-item-value-1');
        this.$val2 = $('.js-fd-connection-item-value-2');
        this.$val3 = $('.js-fd-connection-item-value-3');
        this.eventMgr('countryselector.change', this.change);
    }

    change () {
        if (this.$sel2.hasClass('h-hidden') && this.$sel3.hasClass('h-hidden')) {
            // one country connection
            if (this.$val1.attr('data-label')) {
                $('.js-fd-button-connection-proceed').removeClass('disabled');
                $('.js-fd-button-billing-cfd').removeClass('disabled');
            }
        } else if (this.$sel3.hasClass('h-hidden')) {
            // two country connections
            if (this.$val1.attr('data-label') && this.$val2.attr('data-label')) {
                $('.js-fd-button-connection-proceed').removeClass('disabled');
                $('.js-fd-button-billing-cfd').removeClass('disabled');
            }
        } else {
            if (this.$val1.attr('data-label') && this.$val2.attr('data-label') && this.$val3.attr('data-label')) {
                $('.js-fd-button-connection-proceed').removeClass('disabled');
                $('.js-fd-button-billing-cfd').removeClass('disabled');
            }
        }
    }
}