const
    eventMgr = require('_core_ext/eventMgr'),
    libs = require('./libs'),
    customEvents = require('./customEvents'),
    caTrack = !!window.pageContext.PV;

module.exports = {
    'productImpression': function (analyticsData) {
        if (!caTrack) {
            eventMgr.on('searchresultitems.updated', function () {
                let $productsList = $('.js-product-gtm:not(.js-gtm-send)');
                libs.productImpression(analyticsData, $productsList);
            });
        }
    },
    'productClick': function (analyticsData) {
        if (!caTrack) {
            eventMgr.on('producttile.clicked', function ($element) {
                libs.productClick(analyticsData, $element);
            });
        }
    },
    'productDetail': function (analyticsData) {
        if (!caTrack) {
            eventMgr.on('productactions.updated', function ($element) {
                libs.productDetail(analyticsData, $element.find('.js-product-gtm'));
            });
        }
    },
    'addToCart': function (analyticsData) {
        eventMgr.on('addtocart.added', function (params) {
            if (caTrack) {
                customEvents.addToCart(analyticsData, params);
            } else {
                libs.addToCart(analyticsData, params);
            }
        });
    },
    'removeFromCart': function (analyticsData) {
        ['cart.itemremoved', 'minicart.itemremoved'].forEach(function (methodName) {
            eventMgr.on(methodName, function (params) {
                if (caTrack) {
                    customEvents.removeFromCart(analyticsData, params);
                } else {
                    libs.removeFromCart(analyticsData, params);
                }
            });
        });
    },
    'promotionClick': function (analyticsData) {
        eventMgr.on('promotion.clicked', function (params) {
            if (caTrack) {
                customEvents.promotionClick(analyticsData, params);
            } else {
                libs.promotionClick(analyticsData, params);
            }
        });
    },
    'dialogOpen': function (analyticsData) {
        eventMgr.on('dialogca.gtm-open', function (params, impressionProduct) {
            customEvents.customVPV(analyticsData, params, impressionProduct);
        });
    },
    'updateProductQty': function(analyticsData) {
        if (caTrack) {
            eventMgr.on('quantityfield.gtm.plus-qty', function(productData) {
                customEvents.incrementProduct(analyticsData, productData);
            });
            eventMgr.on('quantityfield.gtm.minus-qty', function (productData) {
                customEvents.decrementProduct(analyticsData, productData);
            });
        }
    }
};
