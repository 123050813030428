'use strict';

/**
 * This components needs to correctly handle stopPropagation.
 * As for search page we have to delegated events, and can not
 * stopPropagation on child element, events fires on parent element
 * as well.
 * */

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const clearrefinementEmitter = eventMgr.getEmitter('clearrefinement');

export default class ClearRefinement extends Component {
    init() {
        super.init();
        this.event('click', this.clearRefinement);
    }

    clearRefinement(element, event) {
        event.stopPropagation();
        clearrefinementEmitter.emit('clear', element, event);
    }
}
