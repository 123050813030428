import Component from '_core_ext/components/Component';

export default class SearchPagination extends Component {
    init() {
        super.init();
        this.eventMgr('infinitescroll.updateparinationinfo', this.updatePaginationInformation);
        this.eventMgr('document.ready', this.movePaginationDiv);
        this.eventMgr('window.resize', this.movePaginationDiv);
        this.eventMgr('searchpagination.move', this.movePaginationDiv);
        this.eventMgr('searchpaginationdiv.move', this.movePaginationDiv);
    }

    updatePaginationInformation(pageSize) {
        if (pageSize) {
            this.$el.find('.js-count-item').html(pageSize);
        }
    }

    movePaginationDiv() {
        var $pagination = $('.pagination-info');
        var $mobileFilters = $('.b-mobile-filters');
        var $searchResultItems = $('.b-search-results__content');
        var isMobile = $(window).width() < 767;

        //Move the pagination-info div according to device screen width.
        if (isMobile) {
            $pagination.insertBefore($searchResultItems);
        } else {
            $pagination.insertAfter($mobileFilters);
        }
    }
}
