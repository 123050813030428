'use strict';

import Component from '_core_ext/components/Component';

export default class PlpMobileColumnFilter extends Component {
    init() {
        super.init();  
        this.moveInGridAdSlotForMobile();
        this.event('click', '.b-filter__one-column, .b-filter__two-column', this.switchColumns);       
    }

    moveInGridAdSlotForMobile() {
        var $inGridAdSlotLi = $('.in-grid-ad-slot');
        
        if($inGridAdSlotLi.length > 0 && $(window).width() < 767) {
            var targetRow = $inGridAdSlotLi.data('ad-insert-row');
            var adContentLength = $inGridAdSlotLi.data('ad-content-length');
            var mobileNoOfGridInRow = 2;
            
            var indexPositionToMoveAd = (adContentLength <= 2) ? ((mobileNoOfGridInRow * targetRow) - adContentLength) : null;
            if(indexPositionToMoveAd != null) {
                if(indexPositionToMoveAd == 0) {
                    $inGridAdSlotLi.insertBefore( $("li.b-grid-tile").first() );
                } else {
                    var $insertAfterDiv = $("li.b-grid-tile:nth-child(" +indexPositionToMoveAd+ ")");
                    $inGridAdSlotLi.insertAfter( $insertAfterDiv );
                }    
            }
        }
    }
    
    switchColumns(element, event) { 
        if($(element).hasClass('active')) {            
            return false;
        }        
        this.$el.children().toggleClass('active');
        
        var $moreThanOneColumnAdSlot = ($('.in-grid-ad-slot').data('ad-content-length') > 1) ? $('.in-grid-ad-slot') : null;
        var $productTileLi = $('ul.b-search-results__items li').not($moreThanOneColumnAdSlot);      
        $productTileLi.toggleClass('col-6').toggleClass('col-12');
        
        var $twoColumnAdSlot = ($('.in-grid-ad-slot').data('ad-content-length') == 2) ? $('.in-grid-ad-slot') : null;
        if($twoColumnAdSlot) {
            $twoColumnAdSlot.toggleClass('d-none');
        }
    } 
}
