'use strict';

import Component from '_core_ext/components/Component';

export default class MobileMenuToggler extends Component {
    init() {
        super.init();
        this.event('click', '.js-menu-toggle', this.onMenuToggleClick);
        this.event('click', '.js-overlay-menu', this.onOverlayCloseMenu);
        this.initCache();
    }

    onMenuToggleClick() {
        $('body').toggleClass('nav-active');

        // hide account nav if active:
        $('.js-account-links').hide();
        $('.js-account-user-panel').removeClass('menu-active');
        // close cart flyout:
        $('.js-mini-cart-content-close').trigger('click');
        // toggle mobile nav states:
        this.$el.find('.js-mobile-menu-category').toggleClass('b-header-category--active');
        this.$el.find('.js-overlay-menu').toggleClass('active');
        $('.js-mobile-menu-category').css({
            height: 'calc(100vh - ' + this.cache.substrHeight + 'px)'
        });
    }

    onOverlayCloseMenu() {
        this.onMenuToggleClick();
    }

    initCache() {
        this.cache = {};
        this.cache.substrHeight = $('.js-header').outerHeight();
    }
}

module.exports = MobileMenuToggler;

