'use strict';
import Component from '_core_ext/components/Component';
import {Progress} from '_core_ext/progress';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('variationselect');

export default class VariationSelect extends Component {
    init() {
        super.init();
        this.loader = new Progress(this.$el);
        this.event('click', '.js-select-option', this.getProductVariant);
    }

    getProductVariant(element, event) {
        event.preventDefault();
        let varitionLink = element.dataset['variatonLink'];
        varitionLink = varitionLink + '&format=ajax';
        this.loader.show();
        var self = this;

        $.ajax({
            url: varitionLink,
            type: 'GET'
        }).done(function (data) {
            var $newContent = $('.js-product-content');
            $newContent.html(data);
            emitter.emit('variantchanged', $newContent);

            let productNameElement = document.getElementsByClassName('b-product-info__product-name')[0];

            productNameElement.innerHTML = self.decodeHtml(productNameElement.innerHTML);
            self.loader.hide();
        }).fail(() => {
            self.loader.hide();
        });
    }

    //Decodes the encoded HTML tags in product name on PDP, if any.
    decodeHtml(html) {
        let textareaNode = document.createElement('textarea');

        textareaNode.innerHTML = html;
        return textareaNode.value;
    }
}
