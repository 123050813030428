'use strict';

import Component from '_core_ext/components/Component';

export default class MiniWishlist extends Component {
    init() {
        super.init();
        this.eventMgr('wishlist.itemadded', this.updateMiniWishlist);
        this.eventMgr('wishlistpage.itemremoved', this.updateMiniWishlist);
    }

    updateMiniWishlist() {
        let self = this;

        $.ajax({
            url: Urls.wishlistHeaderInfo
        }).done(function (html) {
            if (html) {
                self.$el.replaceWith(html);
            }
        });
    }
}
