'use strict';

import Component from '_core_ext/components/Component';

export default class ColorSelector extends Component {
    init() {
        super.init();
        this.event('click', '.js-color-selector-selected', this.toggle);
    }

    toggle() {
        $('.js-color-selector-options').toggleClass('d-none');
    }
}