'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';
import {appendParamsToUrl} from '_core_ext/util';

const emitter = eventMgr.getEmitter('zoom');

export default class Zoom extends Component {
    init() {
        super.init();
        this.event('click', '.js-p-mainimage', this.imageClick);
    }

    imageClick(element, event) {
        //Prevent Zoom dialog box opening for Mobile devices.
        if($(window).width() < 768) {
            return false;          
        }    
        
        event.preventDefault();

        const $el = $(element);
        const $parent = $el.parent();

        let data = {
            startwith: $parent.data('slick-index') || '0',
            pid: this.$el.find('.js-product-image').data('pid') || '0',
            showarrows: true,
            isZoom: true
        };

        let dialogConfig = JSON.stringify({
            url: appendParamsToUrl(Urls.productImages, $.extend({}, data)),
            options: {
                dialogClass: 'b-popup b-popup--zoom',
                close: function () {
                    emitter.emit('close');
                }
            }
        });

        $('.js-zoom-dialog').attr('data-json-config', dialogConfig).click();
    }

}
