'use strict';

import Component from '_core_ext/components/Component';
import util from '_core_ext/util';

export default class NavSlider extends Component {
    init() {
        super.init();
        this.initCache();

        this.event('click', '.js-slider-level2', this.showLevel2);
        this.event('click', '.js-slider-level2-category', this.triggerNextSlider);
    }

    initCache() {
        this.level2 = {
            $view: $('.js-hamburger-menu-level-2'),
            $title: $('.js-hamburger-menu-level-2-title'),
            $all: $('.js-hamburger-menu-level-2-all'),
            $content: $('.js-hamburger-menu-level-2-content')
        };
        this.level3 = {
            $view: $('.js-hamburger-menu-level-3'),
            $title: $('.js-hamburger-menu-level-3-title'),
            $all: $('.js-hamburger-menu-level-3-all'),
            $content: $('.js-hamburger-menu-level-3-content')
        };
    }

    showLevel2 (item) {
        let topCatName = $(item).attr('data-name');
        let topCatLink = $(item).attr('data-link');
        let content = '<ul class="b-header-category__list">';
        let $level2Category = $(`.js-slider-level2[data-name="${topCatName}"] .js-slider-level2-item`);

        $level2Category.each((i, el) => {
            content += '<li class="b-header-category__link-wrap">' + $(el).find('.js-slider-level2-link').html() +
                       ($(el).find('.js-slider-level2-next').length > 0 ? $(el).find('.js-slider-level2-next').html() : '') +
                       '</li>';
        });

        let $level2Wrapper = $level2Category.closest('.js-main-content-wrap');

        if ($level2Wrapper.length > 0) {
            content += '<li class="b-banner__category-item">' + $level2Wrapper.find('.js-mobile-slot-holder-level2').html() + '</li>';
        }

        content += '</ul>';

        this.level2.$title.text(topCatName);
        this.level2.$all.text(topCatName);
        this.level2.$all.parent().attr('href', topCatLink);
        this.level2.$content.html(content);
        this.level2.$view.removeClass('slider-collapsed');
        this.level2.$view.addClass('active');

        // click on back should play only one time per view showing
        $('.js-hamburger-menu-level-2 .js-hamburger-back-to-level1').one('click', () => {
            this.level2.$view.addClass('slider-collapsed');
            this.level2.$view.removeClass('active');
        });
        // add event listener for every next link to show level3
        this.event('click', '.js-slider-level3', this.showLevel3);
        this.event('click', '.js-slider-level3-category', this.triggerNextSlider);
    }

    showLevel3 (item) {
        let topCatName = $(item).attr('data-name');
        let parentCatName = $(item).attr('data-parent');
        let topCatLink = $(item).attr('data-link');
        let content = '<ul class="b-header-category__list">';

        let $level3Category = $(`.js-slider-level3-wrap[data-name="${topCatName}"][data-parent="${parentCatName}"] .js-slider-level3-item`);

        $level3Category.each((i, el) => {
            content += '<li class="b-header-category__link-wrap">' + $(el).find('.js-slider-level3-link').html() + '</li>';
        });

        let $level3Wrapper = $level3Category.closest('.js-slider-level2-item');

        if ($level3Wrapper.length > 0) {
            content += '<li class="b-banner__category-item">' + $level3Wrapper.find('.js-mobile-slot-holder-level3').html() + '</li>';
        }

        content += '</ul>';

        this.level3.$title.text(topCatName);
        this.level3.$all.text(topCatName);
        this.level3.$all.parent().attr('href', topCatLink);
        this.level3.$content.html(content);
        this.level3.$view.removeClass('slider-collapsed');
        this.level3.$view.addClass('active');
        this.level2.$view.removeClass('active');

        // click on back should play only one time per view showing
        $('.js-hamburger-menu-level-3 .js-hamburger-back-to-level2').one('click', () => {
            this.level3.$view.addClass('slider-collapsed');
            this.level3.$view.removeClass('active');
            this.level2.$view.addClass('active');
        });
    }

    triggerNextSlider (item) {
        if (util.isMobile() || $(window).width() < 1224) {
            event.preventDefault();
            $(item).next().trigger('click');
        }
    }
}

module.exports = NavSlider;
