'use strict';

import Form from '_core_ext/components/forms/Form';

export default class ChangePasswordForm extends Form {
    init() {
        super.init();
        this.event('click', '.js-submit', this.submit);
    }

    submit(element, event) {
        if (!this.isChildsValid()) {
            event.preventDefault();
            return;
        }
    }
}
module.exports = ChangePasswordForm;
