'use strict';

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';

export default class HeaderContentSlot extends Component {
    init() {
        super.init();

        this.eventMgr('flightdetailslink.noflightdetails', this.showSlot);
        this.eventMgr('flightdetails.noflightdetails', this.showSlot);
        this.eventMgr('flightdetails.hasflightdetails', this.hideSlot);
    }

    showSlot() {
        this.$el.removeClass(Constants.GLOBAL.hiddenClass);
    }

    hideSlot() {
        this.$el.addClass(Constants.GLOBAL.hiddenClass);
    }
}
