'use strict';

import Component from '_core_ext/components/Component';
import 'eonasdan-bootstrap-datetimepicker';
var moment = require('moment');

const START_CALENDAR_DATE = {
    default: '01/01/1975',
    'zh-cn': '75-1-1',
    'en-ca': '1975-01-01',
    'fr-ca': '1975-01-01',
    'en-us': '01/01/1975',
    'de-de': '01.01.1975'
};

const LOCALE_FORMAT_MAP = {
    default: 'DD/MM/YYYY',
    'zh-cn': 'YY-M-D',
    'en-ca': 'YYYY-MM-DD',
    'fr-ca': 'YYYY-MM-DD',
    'en-us': 'MM/DD/YYYY',
    'de-de': 'DD.MM.YYYY'
};

require('moment/min/locales.min');

export default class Calendar extends Component {
    init() {
        super.init();
        this.disableBirthdate();
        this.event('click', '.js-date-field', this.open);
        this.event('click', '.js-datepicker', this.saveDateTime);
        this.event('click', this.closeCalendarIfClickOutside, $document);

        if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            this.event('touchstart', '.js-date-field input', function (el) {
                el.blur();
            });
        }
    }

    disableBirthdate() {
        let jsDateInput = this.$el.find('.js-date-field input');
        let locale = this.$el.find('.js-locale').eq(0).val().replace('_', '-').toLowerCase();

        this.format = LOCALE_FORMAT_MAP[locale] || LOCALE_FORMAT_MAP.default;
        this.startCalendarDate = START_CALENDAR_DATE[locale] || START_CALENDAR_DATE.default;
        jsDateInput.attr('readonly', true);
    }

    open() {
        var currentContext = this;

        if (this.$el.find('.js-datepicker').hasClass('h-hidden')) {
            let locale = this.$el.find('.js-locale').eq(0).val().replace('_', '-').toLowerCase();

            if (this.$el.find('.js-date-field input').eq(0).val().trim() === '') { // empty date appears in registration and CO flows
                this.$el.find('.js-date-field input').eq(0).val(this.startCalendarDate);
            }

            this.$el.find('.js-datepicker').removeClass('h-hidden');
            $('.js-datepicker').datetimepicker({
                inline: true,
                format: this.format,
                defaultDate: moment(this.$el.find('.js-date-field input').eq(0).val(), this.format),
                maxDate: new Date(),
                minDate: new Date(1901, 0, 1)
            });

            moment.locale(locale);

            $('.js-datepicker')
            .data('DateTimePicker')
            .locale(moment.locale());
        } else {
            this.saveDateTime();
        }

        $('.js-datepicker').on('dp.change', function () {
            currentContext.saveDateTime();
            $(this).addClass('h-hidden');
        });
    }

    closeCalendarIfClickOutside(element, event) {
        if ($(event.target).closest('.js-calendar').length === 0  && !$('.js-datepicker').hasClass('h-hidden')) {
            this.saveDateTime();
        }
    }

    hidecalendar() {
        this.$el.find('.js-datepicker').addClass('h-hidden');
    }

    validate() {
        return this.items[0].isValid();
    }

    isValid() {
        return this.items[0].isValid();
    }

    setFocus() {
        this.items[0].setFocus();
    }

    validateInputField() {
        this.items[0].validate();
    }

    saveDateTime() {
        let date = $('.js-datepicker')
            .data('DateTimePicker')
            .date()
            .format(this.format);
        let uniDate = $('.js-datepicker')
            .data('DateTimePicker')
            .date()
            .format(LOCALE_FORMAT_MAP.default);

        this.$el.find('.js-date-field input').eq(0).val(date);
        this.$el.find('.js-date-field input').eq(1).val(uniDate);
        this.hidecalendar();
        this.validateInputField();
    }
}

module.exports = Calendar;
