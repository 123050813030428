'use strict';
import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('tryiton');

export default class VtoTryItOn extends Component {
    init() {
        super.init();
        this.initializeModiface();
        this.dialog = this.items[0];
        this.event('click', '.js-startTryOnLive-btn', this.startTryOnLive);
        this.event('click', '.js-startTryOnPhoto-btn', this.startTryOnPhoto);
        this.event('change', '.js-uploadImage-btn', this.uploadImage);
        this.eventMgr('consent.startonlive', this.startTryOnLive);
        this.eventMgr('consent.startonphoto', this.startTryOnPhoto);
        this.eventMgr('consent.closedialog', this.closeDialog);
        this.eventMgr('vtolayout.clickuploadbutton', this.clickUploadButton);
        this.eventMgr('vtolayout.clearuploadbutton', this.clearUploadButton);

    }

    checkConsentCookie() {
        if (util.getCookie('modifaceConsent')) {
            return true;
        } else {
            return false;
        }
    }

    closeDialog() {
        this.dialog.close();
    }

    startTryOnLive(element, event) {
        if (event) {
            event.preventDefault();
        }

        if (this.checkConsentCookie()) {
            emitter.emit('starttryonlive');
        } else {
            this._loadConsentPopup("live");
        }

        return;
    }

    startTryOnPhoto(element, event) {
        if (event) {
            event.preventDefault();
        }

        if (this.checkConsentCookie()) {
            emitter.emit('starttryonphoto');
        } else {
            this._loadConsentPopup("photo");
        }

        return;
    }

    uploadImage(element, event) {
        emitter.emit('uploadimage', event);
    }


    _loadConsentPopup(vtoType) {
        //todo consent check
        const url = util.appendParamsToUrl(Urls.showVtoConsentConfirmation, {
            vtoType: vtoType
        });

        this.dialog.open({
            options: {
                dialogClass: 'b-product-modiface__popup-wrapper'
            },
            url: url
        });
    }

    initializeModiface() {
        emitter.emit('initializemodiface');
    }

    clickUploadButton() {
        this.$el.find('.js-uploadImage-btn').click();
    }

    clearUploadButton() {
        this.$el.find('.js-uploadImage-btn').val('');
    }
}
