'use strict';

import Component from '_core_ext/components/Component';

export default class MobileAccountNavigationToggler extends Component {
    init() {
        super.init();
        this.event('click', '.js-account-menu-toggle', this.onNavigationToggleClick);
        this.event('click', '.js-overlay-menu', this.onOverlayCloseMenu);
        this.eventMgr('stickyheader.stickyheaderclicked', this.onHeaderClick);
    }

    onNavigationToggleClick() {
        this.$el.find('.js-account-menu-toggle').toggleClass('is-active');
        this.$el.find('.js-overlay-menu').toggleClass('active');
    }

    onOverlayCloseMenu() {
        this.onNavigationToggleClick();
    }

    onHeaderClick() {
        if (this.$el.find('.js-account-menu-toggle').hasClass('is-active')){
            this.onNavigationToggleClick();
        }
    }
}

module.exports = MobileAccountNavigationToggler;
