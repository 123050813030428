'use strict';

var eventMgr = require('_core_ext/eventMgr');
var Component = require('_core_ext/components/Component');
var rootCmp = new Component($(document));

var RegistryMgr = {
    components: [],
    getComponents: function () {
        return this.components;
    },
    setComponents: function (components) {
        this.components = components;
    }
};

var inited = false;

function tryFn (fn, failFn) {
    try {
        return fn();
    } catch (e) {
        if (failFn) {
            return failFn(e);
        }
    }
}

function getFirstLayer (origElement, selector) {
    return origElement.find(selector).filter(function (i, el) {
        var nearestMatch = $(el).parent().closest('[data-cmp]:not([data-cmp=""])');

        return !nearestMatch.length || !origElement.find(nearestMatch).length;
    });
}

function initComponent (domNode, cmpName) {
    var currentCmp,
        registredCmps = RegistryMgr.getComponents(),
        $domNode = $(domNode),
        cmpConf = {},
        parent;

    if (!registredCmps[cmpName]) {
        console.error('Cant find component ' + cmpName + ' in registry.');
        return;
    }

    if ($domNode.data('cmp-instance')) {
        currentCmp = $domNode.data('cmp-instance');
        if (currentCmp.$el) {
            initCmps(currentCmp);
        }
    } else {
        if ($domNode.attr('data-json-config')) {
            tryFn(function () {
                cmpConf = $.extend(cmpConf, JSON.parse($domNode.attr('data-json-config')));
                return cmpConf;
            }, function (error) {
                console.error('Error while loading component config', $domNode, error);
            });
        }
        cmpConf = $.extend(cmpConf, $domNode.data());

        currentCmp = new (registredCmps[cmpName].default || registredCmps[cmpName])($domNode, cmpConf);
        currentCmp.cmpName = cmpName;
        $domNode.data('cmp-instance', currentCmp);
        parent = $domNode.parent().closest('[data-cmp]').data('cmp-instance') || rootCmp;

        if (parent) {
            parent.items.push(currentCmp);
        }
        if (currentCmp.$el) {
            initCmps(currentCmp);
        }
        if (typeof currentCmp.init === 'function') {
            currentCmp.init();
        }
    }
}

function initCmps(baseCmp) {
    var cmp = baseCmp || rootCmp,
        $firstLayer = getFirstLayer(cmp.$el, '[data-cmp]');

    $firstLayer.each(function (i, domNode) {
        var cmpName = $.trim($(domNode).data('cmp'));

        if (cmpName) {
            initComponent(domNode, cmpName);
        }
    });

    inited = true;

    return cmp;
}

function releaseCmps(baseCmp) {
    var cmp = baseCmp || rootCmp,
        item,
        i;

    for (i = 0; i < cmp.items.length; ++i) {
        item = cmp.items[i];
        if (item) {
            if (typeof item.isBindedToDom === 'function' &&
                    !item.isBindedToDom() &&
                    typeof item.destroy === 'function') {
                item.destroy();
                delete cmp.items[i];
            } else {
                releaseCmps(item);
            }
        }
    }

    return $.Deferred();
}

function updateComponents(baseCmp) {
    if (inited) {
        releaseCmps(baseCmp);
        initCmps(baseCmp);
    }

    return $.Deferred();
}

eventMgr.registerAction('componentmgr.update', updateComponents);
eventMgr.registerAction('componentmgr.release', releaseCmps);
eventMgr.registerAction('componentmgr.initcmp', initCmps);


$(document).ajaxStop(function () {
    setTimeout(updateComponents, 10);
});

$(document).on('component.reinit', function (event, data) {
    if(data && data.cmpName) {
        initComponent(data.containerSelector,data.cmpName);
    }
});

function ComponentMgr (references) {
    RegistryMgr.setComponents(references);

    return initCmps(rootCmp);
}

module.exports = ComponentMgr;
