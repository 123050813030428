'use strict';

import Form from '_core_ext/components/forms/Form';

export default class RegisterForm extends Form {
    init() {
        super.init();
        this.disableEmail();
        this.initPhoneFieldMandatoryState();
        this.event('click', '.js-submit', this.submit);
        this.event('change', '.js-contact-preference-news', this.toggleSubscriptionCheckbox);
        this.event('change', '.js-contact-preference-sms', this.toggleMobileFieldMandatoryState);
        this.event('change', '.js-contact-preference-whiskey-news', this.toggleNewsCheckbox);
        this.event('keydown', '.js-confirm-password', this.confirmPasswordClearError);
    }

    submit(element, event) {
        if (!this.isChildsValid()) {
            event.preventDefault();
            return;
        }
        if (this.$el.find('.js-contact-preference-sms input').prop('checked') && $('.js-phone input').val() === '') {
            event.preventDefault();
            let phoneField = this._findElementWithFormFieldId('phone');

            if (phoneField) {
                phoneField.setError(window.Resources.PHONE_VALIDATION);
                $('.js-email').get(0).scrollIntoView();
            }

            return;
        }
    }

    disableEmail() {
        if (this.$el.find('.js-edit-profile').size() > 0) {
            this.$el.find('.js-email input').prop('readonly', true);
        }
    }

    toggleMobileFieldMandatoryState() {
        this.changeMobileFieldRequiredState();
        this.$el.find('.js-phone').toggleClass('form__row-mandatory');
    }

    toggleNewsCheckbox(event) {
        let newsCheckbox = this.$el.find('.js-contact-preference-news').find('.js-input_field')[0];

        if (event.querySelector('input').checked && newsCheckbox && !newsCheckbox.checked) {
            newsCheckbox.click();
        }
    }

    toggleSubscriptionCheckbox(event) {
        let subscriptionCheckbox = this.$el.find('.js-contact-preference-whiskey-news').find('.js-input_field')[0];

        if (!event.querySelector('input').checked && subscriptionCheckbox && subscriptionCheckbox.checked) {
            subscriptionCheckbox.click();
        }
    }

    changeMobileFieldRequiredState(state) {
        var mobileFieldID = $('.js-phone').find('input').prop('id');

        this.getById(mobileFieldID, function (field) {
            // if there is no state specified - toggle state
            field.config.required = typeof state === 'boolean' ? state : !field.config.required;
            field.validate();
        });
    }

    initPhoneFieldMandatoryState() {
        if ($('.js-contact-preference-sms').children().find('input').is(':checked')) {
            this.$el.find('.js-phone').addClass('form__row-mandatory');
            this.changeMobileFieldRequiredState(true);
        }
    }

    _findElementWithFormFieldId(formFieldId) {
        let found = null;

        this.eachChild(item => {
            if (item.id && ~item.id.indexOf(formFieldId)) {
                found = item;
            }
        });
        return found;
    }

    confirmPasswordClearError(element) {
        if ($(element).next('.form__error')) {
            $(element).next('.form__error').remove();
        }
    }
}
module.exports = RegisterForm;
