'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const refinementstogglerEmitter = eventMgr.getEmitter('refinementstoggler');

export default class RefinementsToggler extends Component {
    init() {
        super.init();
        this.event('click', this.onClick);
    }

    onClick() {
        refinementstogglerEmitter.emit('toggle');
    }
}
