'use strict';

var util = require('_core_ext/util');

function redirect(newURL) {
    setTimeout(function () {
        window.location.href = newURL;
    }, 0);
}

function refresh() {
    setTimeout(function () {
        if (window.location.hash) {
            window.location.reload();
        } else {
            window.location.assign(window.location.href.split('#')[0]);
        }
    }, 500);
}

module.exports = $.extend({
    title: '',
    type: ''
}, window.pageContext, {
        params: util.getQueryStringParams(window.location.search.substr(1)),
        redirect: redirect,
        refresh: refresh
    }
);
