'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

import {appendParamsToUrl} from '_core_ext/util';

const wishlistEmitter = eventMgr.getEmitter('wishlist');

export default class AddToWishlist extends Component {
    init() {
        super.init();

        this.event('click', this.onClick);
        this.eventMgr('wishlist.itemadded', this.onAdded);
        this.wishlistFromCarousel = false;
    }

    onAdded(data) {
        let self = this;

        if (self.$el.data('pid') === data.pid) {
            let html = `
                <a href="${Urls.wishlistShow}" class="b-link--product-tile-icon-heart in-wishlist js-in-wishlist">
                    <span>${window.Resources.IN_MY_WISHLIST}</span>
                </a>
            `;

            self.$el.find('.js-wishlist-add-btn').replaceWith(html);
        }
    }

    onClick(element, event) {
        let pid = this.$el.data('pid'),
            params;

        if (this.$el.find('.js-in-wishlist').length) {
            return;
        }

        wishlistEmitter.emit('addToWishlist', this, pid);

        // Prevent only after check on item in wishlist or not.
        // As href link to Wishlist-Show can be not invoked.
        event.preventDefault();

        if (!pid) {
            return;
        }

        if (this.$el.find('.js-wishlist-add-btn').hasClass('js-select-variant-warn')) {
            $document.find('.js-select-variant-msg').removeClass('h-hidden');
            return;
        }

        //In case, if current product is a product set
        if ($document.find('.js-select-variant-warn').size() > 0 && !this.wishlistFromCarousel) {
            $document.find('.js-product-set-error-msg .js-select-variant-msg').removeClass('h-hidden');
            return;
        }

        params = {
            pid: pid,
            source: 'ajax'
        };

        $.ajax({
            url: appendParamsToUrl(Urls.wishlistAdd, params)
        }).done(function (obj) {
            let isSuccess = typeof obj === 'object' &&
                Object.keys(obj).indexOf('success') !== -1 &&
                obj.success.toString() === 'true';

            if (isSuccess) {
                wishlistEmitter.emit('itemadded', {
                    pid: pid
                });
            }

        });
    }
}
