'use strict';

import Component from '_core_ext/components/Component';

export default class ButtonDisabler extends Component {
    init() {
        super.init();
        this.buttonClass = this.$el.attr('data-btnclass');
        this.$el.on('keyup', () => {
            if (this.$el.val().length > 0) {
                $(`.${this.buttonClass}`).addClass('disabled');
            } else {
                $(`.${this.buttonClass}`).removeClass('disabled');
            }
        });
    }
}