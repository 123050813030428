import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';
import {assign} from 'lodash';

const emitter = eventMgr.getEmitter('quantityfield');
const maxInputValue = 999;

var timer;

var defaultConfig = {
    liveUpdate: true,
    maxVal: maxInputValue,
    minVal: 1,
    updateDelay: 370
};

export default class QuantityField extends Component {
    init() {
        var $input = this.$el.find('.js-item-quantity-field');

        super.init();
        this.config = assign({}, defaultConfig, this.config);

        if (this.config.maxVal >= maxInputValue) {
            this.config.maxVal = maxInputValue;
        }

        let isCartOrMinicartInit = this.config.isCart || this.config.isMiniCart;

        this.inputRangeCorrection($input, isCartOrMinicartInit);

        this.event('click', '.js-qty-minus', function (el) {
            var count = parseInt($input.val()) - 1;

            if ($(el).hasClass('m-disabled')) {
                return false;
            }
            var productData = $(el).closest('.b-mini-cart__product').find('.js-remove-item').data('productdetails');
            if (!productData) {
                productData = $(el).closest('.b-cart__table-row').find('.js-remove-item').data('productdetails');
            }
            emitter.emit('gtm.minus-qty', productData);

            if (this.config.isMiniCart) {
                if (count > this.config.maxVal) {
                    count = this.config.maxVal;
                }
                emitter.emit('updateuuidtqy', this.config.uuid, count);
            }

            count = count < 1 ? 1 : count;
            $input.val(count).trigger('change');
            return false;
        });

        this.event('click', '.js-qty-plus', function (el) {
            var count = parseInt($input.val()) + 1;

            if ($(el).hasClass('m-disabled')) {
                return false;
            }

            var productData = $(el).closest('.b-mini-cart__product').find('.js-remove-item').data('productdetails');
            if (!productData) {
                productData = $(el).closest('.b-cart__table-row').find('.js-remove-item').data('productdetails');
            }
            emitter.emit('gtm.plus-qty', productData);
            if (this.config.isMiniCart) {
                emitter.emit('updateuuidtqy', this.config.uuid, count);
            }

            $input.val(count).trigger('change');
            return false;
        });

        this.event('change', $input, function (el, event) {
            let self = this;

            this.isTriggeredChange = 'isTrigger' in event;
            this.inputRangeCorrection($input);
            clearTimeout(timer);

            // This timer need to be here for prevent a lot of events
            // when customer will click too fast, it may cause
            // a lot of ajax calls in other components.
            timer = setTimeout(function () {

                if (self.config.liveUpdate) {
                    // self.config.uuid -- an id of item to which change a quantity.
                    emitter.emit('qtychanged',
                        $input,
                        self.config.uuid,
                        self.config.pid,
                        self.config.isMiniCart || false
                    );
                }
            }, this.config.updateDelay);
        });

        this.event('keydown', $input, function (el, e) {
            if (e.key.toLowerCase() === 'enter') {
                e.preventDefault();

                if (this.config.isMiniCart) {
                    var count = parseInt($input.val());

                    if (count > this.config.maxVal) {
                        count = this.config.maxVal;
                    }
                    emitter.emit('updateuuidtqy', this.config.uuid, count);
                }

                $input.trigger('change');
            }
        });

        this.initParentComponentInstance();
    }

    initParentComponentInstance() {
        var $parentComponentHolder = this.$el.closest('.js-p-actions-cmp');


        this.parentComponent = null;

        if ($parentComponentHolder.length) {
            this.parentComponent = $parentComponentHolder;
        }
    }

    triggerShowLimitWarn(qtyVal, forceShow) {
        if (!this.config.isCart && forceShow || qtyVal > this.config.minVal) {
            if (this.parentComponent) {
                let isSizeSelected = this.parentComponent.find('.js-tile-size-select').val();

                if (isSizeSelected) {
                    let warnMsg = Resources.PRODUCT_AVAILABLE_LIMIT_WARN;
                    let warnOddMsg = Resources.PRODUCT_AVAILABLE_LIMIT_WARN_ODD;
                    let warningMsg = qtyVal > 1 ? warnMsg : warnOddMsg;

                    this.parentComponent.trigger('showAvailabilityWarn', warningMsg.replace('*', qtyVal));
                }
            }
        }
    }

    triggerHideLimitWarn() {
        if (!this.config.isCart && this.parentComponent) {
            this.parentComponent.trigger('hideAvailabilityWarn');
        }
    }

    setMaxQty(maxVal) {
        this.config.maxVal = (maxVal <= maxInputValue ? maxVal : maxInputValue);
        this.$el.find('.js-item-quantity-field').val('1');
        this.togleActiveState(1, true);
    }

    inputRangeCorrection(inputEl, isCartOrMinicartInit) {
        var maxValue = this.config.maxVal,
            $inputElObj = $(inputEl),
            selectedQuantity = $inputElObj.val(),
            isNotNumberVal = isNaN(selectedQuantity);

        selectedQuantity = isNotNumberVal || selectedQuantity < 1 ? 1 : +selectedQuantity;

        // Update selected quantity to max value only for PDP.
        if (!isCartOrMinicartInit && selectedQuantity >= maxValue) {
            selectedQuantity = maxValue;
        }

        //Prevent quantity update when loading/init cart or minicart template.
        if (!isCartOrMinicartInit) {
            $inputElObj.val(selectedQuantity || 1);
        }
        this.togleActiveState(selectedQuantity, isNotNumberVal);
    }

    togleActiveState(originalVal, skipWarnMessage) {
        var minusBnt = this.$el.find('.js-qty-minus'),
            plusBtn = this.$el.find('.js-qty-plus'),
            min = this.config.minVal,
            max = this.config.maxVal;


        if (originalVal <= min) {
            minusBnt.addClass('m-disabled');
        } else {
            minusBnt.removeClass('m-disabled');
        }

        if (originalVal >= max) {
            if (!skipWarnMessage && (!this.isTriggeredChange ||
                (this.isTriggeredChange && plusBtn.hasClass('m-disabled')))
            ) {
                this.triggerShowLimitWarn(max, true);
            } else {
                this.triggerHideLimitWarn();
            }
            plusBtn.addClass('m-disabled');
        } else {
            if (max > 1) {
                plusBtn.removeClass('m-disabled');
            }
            this.triggerHideLimitWarn();
        }
    }
}

module.exports = QuantityField;
