'use strict';

//Global variable used by GTM
window['dataLayer'] = window['dataLayer'] || [];

let
    eventsLib = require('./events'),
    pageDataCollectors = require('./page-data-collectors'),
    pageTagsConfig = require('./tagsconfig'),
    pageEventConfig = require('./eventsconfig'),
    utils = require('./utils');

let analyticsData, currentPage;

import Component from '_core_ext/components/Component';

export default class GTM extends Component {
    init() {
        super.init();
        let self = this;

        if (!SitePreferences.GTM_ENABLED) {
            return;
        }

        $.ajax({
            type: 'POST',
            url: Urls.setGTMData,
            data: {
                pageContext: JSON.stringify(window.pageContext)
            }
        }).done(function (data) {
            if (data) {
                window.pageContext = data;
            }
        }).then(function () {
            self.initGlobalVariables();
            self.pushPageData();
            self.initGTM();
            self.initEvents();
            self.pushUserID();
        });
    }

    initGlobalVariables() {
        analyticsData = window.pageContext.analytics || {};
        currentPage = window.pageContext.currentPage || window.pageContext.ns;
    }

    initGTM() {
        (function (w, d, s, l, i) {
            w[l] = w[l] || []; w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true; j.src = '//www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', SitePreferences.GTM_ACCOUNT_ID);
    }

    pushPageData() {
        let tagsToPush = $.merge($.merge([], pageTagsConfig.global || []), pageTagsConfig[currentPage] || []);

        utils.initFunctionsFromObject(tagsToPush, pageDataCollectors, [analyticsData, currentPage]);
    }

    pushUserID() {
        if (analyticsData.siteID !== 'theloop-IE' || !analyticsData.user.email) {
            return;
        }
        // There is no way callback xtremepush sdk loaded or not, so we need delay to check it.
        var GTM_LOAD_DELAY = 1000;

        setTimeout(function() {
            if (typeof xtremepush === 'function') {
                var deviceInfo = xtremepush('get', 'device_info');

                if (deviceInfo && (!deviceInfo.user_id || deviceInfo.user_id !== analyticsData.user.email)) {
                    xtremepush('set', 'user_id', analyticsData.user.email);
                    xtremepush('set', 'email', analyticsData.user.email);
                }
            }
        }, GTM_LOAD_DELAY);

        if (analyticsData.pushLoggedInEvent) {
            window.dataLayer.push({
                'event': 'uidAvailable',
                'uid': analyticsData.user.email
            });
        }
    }

    initEvents() {
        let eventsToInit = $.merge($(pageEventConfig["global"]).slice() || [], pageEventConfig[currentPage] || []);

        utils.initFunctionsFromObject(eventsToInit, eventsLib, [analyticsData, currentPage]);
    }


}
