'use strict';

import Component from '_core_ext/components/Component';

export default class MobileCategoryNavigationToggler extends Component {
    init() {
        super.init();
        this.event('click', '.js-category-navigation-toggle', this.onNavigationToggleClick);
        this.event('click', '.js-overlay-menu', this.onOverlayCloseMenu);
    }

    onNavigationToggleClick() {
        this.$el.find('.js-mobile-category-navigation').toggleClass('h-hidden-mobile');
        this.$el.find('.js-category-navigation-toggle').toggleClass('is-active');
        this.$el.find('.js-overlay-menu').toggleClass('active');
    }

    onOverlayCloseMenu() {
        this.onNavigationToggleClick();
    }
}

module.exports = MobileCategoryNavigationToggler;
