'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('producttile');

export default class ProductTile extends Component {
    init() {
        super.init();

        this.event('click', 'a', this.productTileClick);
        this.event('click', '.js-compare-check', this.productTileCompare);
        
        this.decodeHtmlInProductTileName();
    }

    productTileClick() {
        emitter.emit('clicked', this);
    }

    productTileCompare(element) {
        var cb = $(element);
        var action = element.checked ? 'add' : 'remove';
        var itemImg = this.$el.find('.js-product-image img').first();

        emitter.emit('compare' + action, {
            itemid: this.$el.data('itemid'),
            uuid: element.id.split('-')[1],
            img: itemImg,
            cb: cb
        });

    }
    
    //Decode encoded HTML tag values in product tile name, if any
    decodeHtmlInProductTileName() {
        var productNameElement = this.$el.find('.b-product__product-name-link');
        var decodedHtmlValue = this.decodeHtml(productNameElement.html());
        productNameElement.html(decodedHtmlValue);
    }
    
    decodeHtml(html) {
        var dummyHTML = document.createElement("textarea");
        dummyHTML.innerHTML = html;
        return dummyHTML.value;
    }
}
