'use strict';

import Component from '_core_ext/components/Component';
import util from '_core_ext/util';

export default class AlcoholPrice extends Component {
    init() {
        // jscs: disable
        let storedCountry = this.$el.attr('data-session') !== 'null' ? this.$el.attr('data-session') : null;
        // jscs: enable
        super.init();
        this.countries = JSON.parse(this.$el.attr('data-json-config'));
        this.locale = this.$el.attr('data-locale');
        $('.js-product-country select option[value=""]').prop('disabled', true);

        if (storedCountry) {
            $('.js-product-country select').val(storedCountry);
            $('.js-addtocart-btn').removeClass('disabled');
        } else {
            // if country selector not fulled when form not applied
            this.fillSelector();
            $('.js-addtocart-btn').addClass('disabled');
        }
        this.event('change', '.js-product-country select', this.save);
    }

    save () {
        let country = $('.js-product-country select').find('option:selected').val(),
            url = util.appendParamsToUrl(Urls.flightDetailsSaveCountry, {
            country: country,
            format: 'ajax'
        });

        $('.js-addtocart-btn').addClass('disabled');
        $.ajax({
            url: url
        }).done(() => {
            // force reload for apply single price styles
            window.location.reload();
        });
    }

    fillSelector () {
        let options = '<option value="" selected disabled>Please select</option>';
        let countries = this.countries[this.locale];

        for (let country in countries) {
            options += `<option value="${country}">${countries[country].label}</option>`;
        }
        $('.js-product-country select').html(options);
    }
}