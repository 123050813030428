'use strict';

import Component from '_core_ext/components/Component';

import {getCookie, setCookie} from '_core_ext/util';

export default class FlightDetailsEducational extends Component {
    init() {
        super.init();
        this.updateEducationalAsset();
        this.eventMgr('flightdetails.hideeducationalasset', this.hideAsset);
        this.eventMgr('flightdetails.showeducationalasset', this.showAsset);
        this.event('click', '.js-close', this.closeAsset);
        this.event('click', '.js-flight-details-link-mobile', this.openFlyout);
    }
    //
    // Event handlers.
    //

    openFlyout() {
        $.ajax({
            url: Urls.flightDetailsShow
        }).done(function (html) {
            if (html) {
                $(document).find('.js-flight-details-flayout-mobile').html(html);
                $(document).scrollTop(0);
                $(document).find('.js-flight-details-link-mobile').addClass('b-header-flight-details__mobile--open');
            }
        });
    }

    hideAsset() {
        this.$el.addClass('h-hidden');
    }

    showAsset() {
        this.$el.removeClass('h-hidden');
    }

    updateEducationalAsset() {
        var fdCookie = getCookie('fd');
        var fdaCookie = getCookie('fda');

        if (fdCookie || fdaCookie) {
            this.hideAsset();
        } else {
            this.showAsset();
        }
    }

    closeAsset() {
        this.hideAsset();
        setCookie('fda', true, 43200);
    }
}

module.exports = FlightDetailsEducational;
