'use strict';

function Loader (container) {
    this.$target = (!container || $(container).length === 0) ? $('body') : $(container);
    this.$loader = this.$target.find('.b-loader');
}

Loader.prototype.show = function () {
    if (this.$loader.length === 0) {
        this.$loader = $('<div/>').addClass('b-loader')
            .append($('<span/>').addClass('b-loader-copy'));
    }

    return this.$loader.appendTo(this.$target).show();
};

Loader.prototype.hide = function () {
    if (this.$target.length) {
        this.$target.removeAttr('style');
    }

    if (this.$loader) {
        this.$loader.hide();
    }
};

module.exports.Progress = Loader;
