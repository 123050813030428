'use strict';

import Form from '_core_ext/components/forms/Form';
import eventMgr from '_core_ext/eventMgr';
import util from '_core_ext/util';

const emitter = eventMgr.getEmitter('flightdetails');

export default class FlightDetails extends Form {
    
    init() {
        super.init();
        this.initCache();
        this.emitFlightDetails();
        this.updateIsTransferLabels();
        this.event('change', '.js-city', this.updateCitySelection);
        this.event('change', '.form__input-date-wrapper', this.updateMessages);
        this.event('input', '.form__input-date-wrapper', this.disableButton);
        this.event('change', '.js-city-type', this.updateCityTypeSelection);
        this.event('change', '.js-country', this.updateMessages);
        this.event('change', '.js-collection-point', this.updateMessages);
        this.event('change', '.js-is-transfer', this.updateMessages);
        this.event('change', '.js-flight-number', this.updateMessages);
        this.event('click', '.js-show-collection-point', this.showCollectionPointForm);
        this.event('click', '.js-save', this.saveDetails);
        this.event('click', '.js-checkout-proceed', this.proceedCheckout);
        this.event('click', '.js-reset', this.resetDetails);
        this.event('click', '.js-datetime', this.openCalendarPicker);
        this.eventMgr('calendar.dateselected', this.setDate);
        this.eventMgr('checkoutcalendar.dateselected', this.setDate);
        this.eventMgr('calendar.hidecalendarpicker', this.hideCalendarPicker);
        this.eventMgr('flightdetailsstaff.hidecalendarpicker', this.hideCalendarPicker);
        this.eventMgr('flightdetailsstaff.staffchange', this.revertSummary);        
        this.event('submit', '#flightdetails', this.validateDateTime);

        this.eventMgr('dialog.content.loading', this.disableSaveButton);
        this.eventMgr('dialog.content.loaded', this.enableSaveButton);
        this.eventMgr('dialog.content.loaded.fail', this.enableSaveButton);
        
        emitter.emit('opened');

        
    }

    revertSummary(data) {
        if(!data.isFlying) {
            // TODO:
            // Edit IE in case of NZ site
            this.recalculateSummary('IE', false)
        } else {            
            this.recalculateSummary($('.js-country').find(':checked').val(), $('.js-city-type').find(':checked').val() === "departure");
        }
    }

    getEmitter() {
        return emitter;
    }

    initCache() {
        this.cache = {};
        this.cache.cityLabel = this.$el.find('.js-city-update');
        this.cache.collectionPoint = this.$el.find('.js-collection-point');
        this.cache.collectionPointShort = this.$el.find('.js-collection-point-short');
        this.cache.collectionPointForm = this.$el.find('.js-collection-point-form');
        this.cache.phone = this.$el.find('.js-phone');
        this.cache.cityRadio = this.$el.find('.js-city');
        this.cache.cityTypeRadio = this.$el.find('.js-city-type');
        this.cache.countrySelect = this.$el.find('.js-country');
        this.cache.datetime = this.$el.find('.js-datetime');
        this.cache.form = this.$el.find('.js-form-show');
        this.cache.calendar = this.$el.find('.js-calendar-show');
        this.cache.isFlying = $document.find('.js-is-flying');
        this.cache.isTransfer = this.$el.find('.js-is-transfer');
        this.cache.transferCountrySelect = this.$el.find('.js-transfer-country');
        this.cache.isTransferNoLabel = this.$el.find('.js-is-transfer .form__label--value').eq(0);
        this.cache.isTransferYesLabel = this.$el.find('.js-is-transfer .form__label--value').eq(1);
    }

    recalculateSummary(country, departure) {
        let self = this;
        var url = util.appendParamsToUrl(Urls.updateSummaryShipping, {
            format: 'ajax',
            country: country,
            departure: departure
        });

        $.ajax({
            url: url
        }).done(function (html) {            
            if (html) {
                let orderSummary = $(html).filter(function(index,el) { 
                    return $(el).attr('id') === 'order-summary' 
                });

                if(!!orderSummary.length) {
                    $('#order-summary').replaceWith($(orderSummary));
                }
            }
        });
    }

    disableButton() {
        $('.js-checkout-proceed').attr('disabled', true);
    }

    disableSaveButton() {
        $('.js-save').attr('disabled',true);
    }

    enableSaveButton() {
        $('.js-save').attr('disabled',false);
    }

    getFormDetails() {
        let flightDetailsForm = {};
        flightDetailsForm.city = this.cache.cityRadio.find(':checked').val();
        flightDetailsForm.cityType = this.cache.cityTypeRadio.find(':checked').val();
        flightDetailsForm.country = this.cache.countrySelect.find(':checked').val();
        flightDetailsForm.datetime = this.cache.datetime.find('input').val();
        flightDetailsForm.collectionPoint = this.cache.collectionPointForm.find(':checked').val();
        flightDetailsForm.phone = this.cache.phone.find('input').val();
        flightDetailsForm.isTransfer = this.cache.isTransfer.find(':checked').val();
        flightDetailsForm.transferCountry = this.cache.transferCountrySelect.find(':checked').val();
        flightDetailsForm.isFlying = 'yes';

        return flightDetailsForm;
    }

    updateMessages() {
        let flightDetailsForm = this.getFormDetails();
        let url;

        if (this.$el.find('.js-no-fly').size() > 0) {
            flightDetailsForm.isFlying = 'no';
        }

        if (this.$el.find('.js-checkout').size() > 0) {
            url = util.appendParamsToUrl(Urls.checkoutFlightDetailsUpdate, {
                flightDetails: JSON.stringify(flightDetailsForm),
                isFlying: flightDetailsForm.isFlying,
                format: 'ajax'
            });
            let self = this;

            $.ajax({
                url: url
            }).done(function (html) {
                if (html) {
                    self.$el.replaceWith(html);
                    flightDetailsForm = self.getFormDetails();
      
                    if(flightDetailsForm.cityType === 'arrival' ) {
                        self.recalculateSummary('IE', false); 
                    } else {
                        self.recalculateSummary(flightDetailsForm.country, true)
                    }
                }
            });
        } else {
            url = util.appendParamsToUrl(Urls.flightDetailsUpdate, {
                flightDetails: JSON.stringify(flightDetailsForm)
            });
            emitter.emit('reloadcontent', {
                url: url
            });
            emitter.emit('reloadflyoutcontent', url);
        }

    }

    updateIsTransferLabels() {
        var cityTypeSelected = this.cache.cityTypeRadio.find(':checked').val();

        if (cityTypeSelected === 'arrival') {
            this.cache.isTransferNoLabel.text(Resources.FLIGHT_DETAILS_TRANSFER_ARRIVAL_NO);
            this.cache.isTransferYesLabel.text(Resources.FLIGHT_DETAILS_TRANSFER_ARRIVAL_YES);
        } else {
            this.cache.isTransferNoLabel.text(Resources.FLIGHT_DETAILS_TRANSFER_DEPARTURE_NO);
            this.cache.isTransferYesLabel.text(Resources.FLIGHT_DETAILS_TRANSFER_DEPARTURE_YES);
        }
        this.cache.isTransfer.removeClass('h-hidden');
    }

    updateCitySelection(element) {
        var city = $(element).find(':checked').val();

        this.updateMessages();
        emitter.emit('citychanged', city);
    }

    updateCityTypeSelection(element) {
        var cityTypeSelected = $(element).find(':checked').val();

        this.updateMessages();
        this.updateIsTransferLabels(cityTypeSelected);
        emitter.emit('citytypechanged', cityTypeSelected);
    }

    showCollectionPointForm(element) {
        $(element).addClass('h-hidden');
        this.cache.collectionPointShort.addClass('h-hidden');
        this.cache.collectionPointForm.removeClass('h-hidden');
    }

    saveDetails(element, event) {        
        event.preventDefault();
        if (!this.isChildsValid()) {
            return;
        }
        var flightDetails = {};

        flightDetails.isFlying = 'yes';
        flightDetails.city = this.cache.cityRadio.find(':checked').val();
        flightDetails.cityType = this.cache.cityTypeRadio.find(':checked').val();
        flightDetails.country = this.cache.countrySelect.find(':checked').val();
        flightDetails.datetime = this.cache.datetime.find('input').val();
        flightDetails.collectionPoint = this.cache.collectionPointForm.find(':checked').val();
        flightDetails.isTransfer = this.cache.isTransfer.find(':checked').val();
        flightDetails.transferCountry = this.cache.transferCountrySelect.find(':checked').val();

        util.setCookie('fd', JSON.stringify(flightDetails).replace(/,/g, '@').replace(/ /g, '&'), 43200);
        $.ajax({
            url: Urls.flightDetailsSave
        });

        emitter.emit('hasflightdetails');
        emitter.emit('closedialog');
        emitter.emit('closeflyout');
        emitter.emit('hideeducationalasset');
        emitter.emit('updateminicart');
        emitter.emit('reloadpage');
    }

    validateDateTime() {
        let flightDetailsForm = {};
        let url;

        flightDetailsForm.city = this.cache.cityRadio.find(':checked').val();
        flightDetailsForm.cityType = this.cache.cityTypeRadio.find(':checked').val();
        flightDetailsForm.country = this.cache.countrySelect.find(':checked').val();
        flightDetailsForm.datetime = this.cache.datetime.find('input').val();
        flightDetailsForm.collectionPoint = this.cache.collectionPointForm.find(':checked').val();
        flightDetailsForm.phone = this.cache.phone.find('input').val();
        flightDetailsForm.isTransfer = this.cache.isTransfer.find(':checked').val();
        flightDetailsForm.transferCountry = this.cache.transferCountrySelect.find(':checked').val();
        flightDetailsForm.isFlying = 'yes';
        if (this.$el.find('.js-no-fly').size() > 0) {
            flightDetailsForm.isFlying = 'no';
        }
        url = util.appendParamsToUrl(Urls.checkoutValidateDate, {
            flightDetails: JSON.stringify(flightDetailsForm),
            isFlying: flightDetailsForm.isFlying,
            format: 'ajax'
        });
        let isValid;

        $.ajax({
            url: url,
            async: false
        }).done(function (response) {
            isValid = response.isValid;
        });
        return isValid;
    }

    proceedCheckout(element, event) {
        if (!this.isChildsValid()) {
            event.preventDefault();
            return;
        }
        if (this.cache.datetime.find('input').val() === '') {
            event.preventDefault();
            let dateTimeField = this._findElementWithFormFieldId(
                'datetime', this._findElementWithFormFieldId('js-datetime-dialog-cmp'
            ));

            if (dateTimeField) {
                dateTimeField.setError(window.Resources.PHONE_VALIDATION);
            }
            return;
        }
        this.updateMessages();
        if (this.$el.find('.js-non-valid').size() > 0) {
            event.preventDefault();
            return;
        }

        var flightDetails = {};

        if (this.$el.find('.js-no-fly').size() > 0) {
            util.setCookie('fd', flightDetails, 0);
            emitter.emit('noflightdetails');
            $.ajax({
                url: Urls.flightDetailsReset
            });
        } else {
            flightDetails.isFlying = this.cache.isFlying.find(':checked').val();
            flightDetails.city = this.cache.cityRadio.find(':checked').val();
            flightDetails.cityType = this.cache.cityTypeRadio.find(':checked').val();
            flightDetails.country = this.cache.countrySelect.find(':checked').val();
            flightDetails.datetime = this.cache.datetime.find('input').val();
            flightDetails.collectionPoint = this.cache.collectionPoint.find(':checked').val();
            flightDetails.phone = this.cache.phone.find('input').val();
            flightDetails.isTransfer = this.cache.isTransfer.find(':checked').val();
            flightDetails.transferCountry = this.cache.transferCountrySelect.find(':checked').val();

            util.setCookie('fd', JSON.stringify(flightDetails).replace(/,/g, '@').replace(/ /g, '&'), 43200);
            emitter.emit('hasflightdetails');
            $.ajax({
                url: Urls.flightDetailsSave
            });
        }
    }

    resetDetails(element, event) {
        event.preventDefault();

        var flightDetails = {};

        util.setCookie('fd', flightDetails, 0);

        $.ajax({
            url: Urls.flightDetailsReset
        });

        emitter.emit('noflightdetails');
        emitter.emit('reset');
        emitter.emit('closedialog');
        emitter.emit('closeflyout');
        emitter.emit('showeducationalasset');
        emitter.emit('updateminicart');
        emitter.emit('reloadpage');
    }

    emitFlightDetails() {
        var city = this.cache.cityRadio.find(':checked').val();
        var cityType = this.cache.cityTypeRadio.find(':checked').val();

        emitter.emit('update', city, cityType);
    }

    openCalendarPicker() {
        if (this.$el.find('.js-checkout').size() === 0) {
            this.cache.form.addClass('h-hidden');
            this.cache.calendar.removeClass('h-hidden');
            let selectedDate = this.cache.datetime.find('input').val();

            emitter.emit('showcalendar', selectedDate);
        }
    }

    hideCalendarPicker() {
        this.cache.form.removeClass('h-hidden');
    }

    setDate(date) {
        this.cache.datetime.find('input').val(date);
        this.updateMessages();
        var dateTimeElem = this._findElementWithFormFieldId('datetime');

        if (dateTimeElem) {
            dateTimeElem.clearError();
        }
    }

    _findElementWithFormFieldId(formFieldId, parentElement) {
        let found = null;
        let self = parentElement ? parentElement : this;

        self.eachChild(item => {
            if (item && item.id && ~item.id.indexOf(formFieldId)) {
                found = item;
                }
        });
        return found;
    }
}

module.exports = FlightDetails;
