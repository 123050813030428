'use strict';

import Component from '_core_ext/components/Component';
import {appendParamsToUrl} from '_core_ext/util';

export default class AddToWishlistProduct extends Component {
    init() {
        super.init();
        this.state = this.$el.data('inwishlist');
        this.render();
        this.event('click', this.onClick);
        
        if(window.location.href.indexOf('wishlist=true') !== -1) {
            this.$el.click();
        }
    }

    render () {
        this.$el.html(`
            <a href="#" class="b-link--product-tile-icon-heart ${this.state ? 'in-wishlist' : ''}">
                <span>${this.state ? this.$el.data('labeladded') : this.$el.data('labeladd')}</span>
            </a>
        `);
    }

    onClick(element, event) {
        let pid = this.$el.data('pid'),
            params = {
                pid: pid,
                source: 'ajax'
            },
            url = this.state ? Urls.wishlistRemove : Urls.wishlistAdd;

        event.preventDefault();
        if (!pid) {
            return;
        }
        
        if (this.$el.data('wishlistwarning')) {
            $document.find('.js-select-variant-msg').removeClass('h-hidden');
            return;
        }

        $.ajax({
            url: appendParamsToUrl(url, params)
        }).done(data => {
            if (data && data.success) {
                this.state = !this.state;
                this.render();
            }
        });
    }
}
