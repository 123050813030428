'use strict';

import Form from '_core_ext/components/forms/Form';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('deleteaccount');

export default class DeleteAccount extends Form {
    init() {
        super.init();
        this.event('click', '.js-delete', this.deleteAccount);
        this.event('click', '.js-cancel', this.cancel);
    }

    deleteAccount(element, event) {
        const inputValue = this.$el.find('.js-confirm-msg input').val();

        this.$el.find('.js-confirm-error-upper').addClass('h-hidden');
        this.$el.find('.js-confirm-error').addClass('h-hidden');

        if (inputValue !== 'DELETE') {
            event.preventDefault();
            if (inputValue === 'delete') {
                this.$el.find('.js-confirm-error-upper').removeClass('h-hidden');
            } else {
                this.$el.find('.js-confirm-error').removeClass('h-hidden');
            }
            return;
        }
    }

    cancel(element, event) {
        event.preventDefault();
        emitter.emit('closedialog');
    }
}
module.exports = DeleteAccount;
