'use strict';

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';

export default class CookiesCheck extends Component {
    init() {
        super.init();
        this.initState();
        this.event('click touchstart', '.js-close', this.onClose);
    }

    onClose() {
        this.$el.addClass(Constants.GLOBAL.hiddenClass);
    }

    initState() {
        if (document.cookie.length === 0) {
            this.showError();
        }
    }

    showError() {
        this.$el.removeClass(Constants.GLOBAL.hiddenClass);
    }
}
