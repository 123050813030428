import Component from '_core_ext/components/Component';
import {scrollTo} from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('inputgeneric');

export default class InputGeneric extends Component {
    init() {
        this.event('change', '.js-input_field', this.update);
        this.event('paste', '.js-input_field', () => {
            setTimeout(this.update.bind(this), 10);
        });
        this.$field = this.$el.find('.js-input_field');
        this.initValue = this.getValue();
        if (!this.id && this.$field.first().attr('name')) {
            this.id = this.$field.first().attr('name');
        }
        if (this.config.forceUpdate) {
            this.event('keyup', '.js-input_field', this.update);
        }
        this.shown = true;
        this.disabled = this.$field.prop('disabled');
        this.event('focusout', this.validate);
    }
    getValue() {
        return this.$field.val();
    }
    getName() {
        let name;

        if (this.shown && this.isValid()) {
            name = this.$field.attr('name');
        }
        return name;
    }
    setFocus() {
        scrollTo(this.$el, () => {
            if (this.$field) {
                this.$field.focus();
            }
        });
    }
    setValue(newVal = '', silently = false) {
        this.$field.val(newVal);
        if (!silently) {
            this.update();
        }
    }
    update() {
        if (this.isDurty()) {
            this.$el.addClass('f-state-durty');
        } else {
            this.$el.removeClass('f-state-durty');
        }

        this.validate();
        this.emitter.emit('change', this);
    }
    isDurty() {
        return this.initValue !== this.getValue();
    }
    clearError() {
        this.setError();
        if (this.config.hideValidationError) {
            emitter.emit('hidevalidationerror', {error: '', eventEl: this.$el});
        }
    }
    setError(error) {
        var errorEl;

        this.$el
            .removeClass('f-state-error')
            .addClass('f-state-valid')
            .find('.f-error').remove();

        if (error) {
            errorEl = `<div class="f-error ${this.config.modifierErrorClass || ''}">
                            <span class="f-error-block">
                            <span class="f-error-text">
                                ` + $('<div>').text(error).html() /*escapes content*/ + `
                            </span>
                        </span>
                    </div>`;

            this.$el
                .removeClass('f-state-valid')
                .addClass('f-state-error');

            if (this.config.hideValidationError) {
                emitter.emit('hidevalidationerror', {error: errorEl, eventEl: this.$el});
                return;
            }

            if (this.$el.hasClass('f-input')) {
                this.$el.append(errorEl);
            } else {
                this.$el
                    .find('.f-field-wrapper')
                    .append(errorEl);
            }
        }
    }
    getError (value) {
        let err = '';

        if (this.config.minlength && value.length < this.config.minlength) {
            err = (this.config.minlengthtext + '').replace('{0}', this.config.minlength);
        } else if (this.config.maxlength && value.length > this.config.maxlength) {
            err = (this.config.maxlengthtext + '').replace('{0}', this.config.maxlength);
        } else if (this.config.regEx && !(new RegExp(this.config.regEx, 'g')).test(value)) {
            err = (this.config.parseError + '').replace('{0}', value);
        } else if (this.cmpToMatch) {
            let cmpt1 = this.getValue(),
                cmpt2 = this.cmpToMatch.getValue();

            if (this.config.ignoreCase) {
                cmpt1 = cmpt1.toLowerCase();
                cmpt2 = cmpt2.toLowerCase();
            }

            if (cmpt1 !== cmpt2) {
                err = this.cmpToMatchOpts.message;
            }
        }

        return err;
    }
    isValid(callback) {
        let _value = this.getValue();
        let value = _value ? (_value + '').replace(/\r?\n/g, '\r\n').trim() : _value;
        let error;

        if (this.$field.attr('type') !== 'checkbox' && !this.config.ignoreSpaces) {
            this.setValue(value, true);
        }

        if (this.config.required && !value) {
            error = this.config.requiredtext;
        }

        if (
            this.$field.attr('type') === 'number' &&
            value && (value < this.config.minVal || value > this.config.maxVal)
        ) {
            error = this.config.rangeError;
        }

        if (!error && value) {
            error = this.getError(value);
        }

        this.error = error;

        if (!error && callback) {
            callback();
        }

        return !error;
    }
    validate() {
        if (!this.shown || this.disabled || this.locked) {
            return true;
        }

        const valid = this.isValid();

        if (valid) {
            this.clearError();
        } else {
            this.setError(this.error);
        }
        return valid;
    }
    hide() {
        if (this.shown) {
            this.$el.addClass('h-hidden');
            this.shown = false;
        }
    }
    show() {
        if (!this.shown) {
            this.$el.removeClass('h-hidden');
            this.shown = true;
        }
    }
    disable() {
        this.disabled = true;
        this.$field.attr('disabled', true);
        this.$el.addClass('m-disabled');
    }
    enable() {
        this.disabled = false;
        this.$field.removeAttr('disabled');
        this.$el.removeClass('m-disabled');
    }
    lock() {
        this.locked = true;
        this.$field.attr('readonly', true);
        this.$el.addClass('m-locked');
    }
    unlock() {
        this.locked = false;
        this.$field.attr('readonly', false);
        this.$el.removeClass('m-locked');
    }
    isDisabled() {
        return this.disabled;
    }
    setMatchCmp(cmpToMatch, options = {}) {
        this.cmpToMatch = cmpToMatch;
        this.cmpToMatchOpts = options;
    }
    skipSubmission() {
        return false;
    }
}
