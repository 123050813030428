'use strict';
import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';
import 'eonasdan-bootstrap-datetimepicker';

const emitter = eventMgr.getEmitter('checkoutcalendar');

export default class CheckoutFlightDetailsCalendar extends Component {
    init() {
        super.init();
        this.open();

        this.event('click', '.js-back', this.close);
        this.event('click', '.js-save-date', this.saveDateTime);
    }
    open() {
        $('.js-datetimepicker').datetimepicker({
            inline: true,
            sideBySide: true,
            minDate: Date()
        });

        $(document).scrollTop(0);

        let selectedDate = $document.find('.js-datetime input').val();

        $('.js-datetimepicker')
            .data('DateTimePicker')
            .format('ddd, DD.MM.YYYY hh:mm A')
            .date(selectedDate);
    }

    close() {
        emitter.emit('close');
    }

    saveDateTime() {
        let date = $('.js-datetimepicker')
            .data('DateTimePicker')
            .date()
            .format('ddd, DD.MM.YYYY hh:mm A');

        emitter.emit('close');
        emitter.emit('dateselected', date);
    }
}

module.exports = CheckoutFlightDetailsCalendar;
