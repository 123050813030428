'use strict';

import Form from '_core_ext/components/forms/Form';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('flightdetailsstaff');

export default class FlightDetailsStaff extends Form {
    init() {
        var self = this;

        super.init();
        this.event('change', '.js-is-flying', this.loadFlightDetailsForm);
    }

    //
    // Event handlers.
    //

    loadFlightDetailsForm() {
        let self = this;
        let isFlying = this.$el.find('.js-is-flying').find(':checked').val();
        let url = util.appendParamsToUrl(Urls.checkoutFlightDetailsStart, {
            isFlying: isFlying,
            format: 'ajax'
        });

        if (isFlying === 'no') {
            $document.find('.js-co-fd-staff-title').text(window.Resources.CHECKOUT_FLIGHT_DETAILS_NO_FLIGHT_TITLE);
        } else {
            $document.find('.js-co-fd-staff-title').text(window.Resources.CHECKOUT_FLIGHT_DETAILS_WITH_FLIGHT_TITLE);
        }
        $.ajax({
            url: url
        }).done(function (html) {            
            if (html) {
                $document.find('.js-flightdetails-form').html(html);
            }
            emitter.emit('staffchange', {
                isFlying: isFlying === 'no' ? false : true
            });
        });
    }
}
module.exports = FlightDetailsStaff;
