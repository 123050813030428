'use strict';
import Form from '_core_ext/components/forms/Form';
import eventMgr from '_core_ext/eventMgr';
import util from '_core_ext/util';

const emitter = eventMgr.getEmitter('consent');

export default class VtoConsent extends Form {
    init() {
        super.init();
        this.event('click', '.js-consent-save', this.save);
        this.vtoType = this.$el.data('vtotype');
    }

    save(element, event) {
        event.preventDefault();

        let approval = this.$el.find('.js-consent-approval input').val();

        if (approval && this.isChildsValid()) {
            util.setCookie('modifaceConsent', 'true', 60 * 24 * 30);
            emitter.emit('closedialog');

            if (this.vtoType === "live") {
                emitter.emit('startonlive');
            } else {
                emitter.emit('startonphoto');
            }
        } else {
            return;
        }
    }
}

module.exports = VtoConsent;
