// jscs: disable
'use strict';

import Form from '_core_ext/components/forms/Form';
import eventMgr from '_core_ext/eventMgr';
import util from '_core_ext/util';
import page from '_core_ext/page';

const emitter = eventMgr.getEmitter('createeditaddress');

export default class CreateEditAddress extends Form {
    init() {
        super.init();
        this.event('click', '.js-submit', this.submit);
        this.event('click', '.js-cancel', this.cancel);
    }

    submit(element, event) {
        if (!this.isChildsValid()) {
            event.preventDefault();
            return;
        } else {
            event.preventDefault();
            var $form = $('#edit-address-form');
            if (!$form.valid()) {
                return false;
            }
            var url =  $form.attr('action');
            var separator = url.indexOf('?') === -1 ? '?' : '&';
            url = url + separator + 'format=ajax';
            var applyName = $form.find('.js-submit').attr('name');
            var options = {
                url: url,
                data: $form.serialize() + '&' + applyName + '=x',
                type: 'POST'
            };

            $.ajax(options).done(function (data) {
                if (typeof(data) !== 'string') {
                    if (data.message && !data.success) {
                        $form.find('.form-error').html(data.message);
                        util.scrollBrowser($('.form-error').offset().top - 40);
                        return;
                    }

                    if (data.success) {
                        emitter.emit('closedialog');
                        page.refresh();
                    } else {
                        window.alert(data.message);
                        return false;
                    }
                } else {
                    window.alert(data.message);
                    return false;
                }
            });
        }
    }

    cancel(element, event) {
        event.preventDefault();
        emitter.emit('closedialog');
    }

    getEmitter () {
        return emitter;
    }
}
module.exports = CreateEditAddress;
// jscs: enable