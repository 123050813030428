import InputGeneric from './InputGeneric';

export default class InputHidden extends InputGeneric {
    isValid () {
        return true;
    }
    validate () {
        return true;
    }
}
