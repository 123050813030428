import Form from './Form';

const Constants = require('_core_ext/components/Constants');
const eventMgr = require('_core_ext/eventMgr');
const emitter = eventMgr.getEmitter('sortform');
const searchPaginationEmitter = eventMgr.getEmitter('searchpagination');

export default class SortForm extends Form {
    init() {
        let self = this;

        super.init();

        this.event('selectmenuchange', this.onChange);

        if (!this.selectmenu) {
            this.selectmenu = this.$el.find('.js-sort-select');
            this.selectmenu.selectmenu({
                select: function () {
                    self.updateSelectMenuTitle();
                },
                create: function () {
                    self.button = self.selectmenu.selectmenu('widget');
                    self.menu = self.selectmenu.selectmenu('menuWidget');
                    self.instance = self.selectmenu.selectmenu('instance');

                    self.updateSelectMenuTitle();

                    // Add custom class for FE stylings.
                    self.button.addClass('b-sort__selectmenu-button');
                    self.menu.addClass('b-sort__selectmenu-menu');
                    self.menu.find('.ui-state-disabled').addClass(Constants.GLOBAL.hiddenClass);

                    // Move menu to the button for have a possibility correctly
                    // handle mouseenter and mouseleave events.
                    self.menu.appendTo(self.button);
                },
                open: function () {
                    let selected = self.button.attr('aria-labelledby');

                    self.menu.find('li').removeAttr('aria-selected');
                    self.menu.find('li#' + selected).attr('aria-selected', 'true');

                    self.updateSelectMenuTitle();
                }
            });

            self.button.on('mouseenter', function () {
                if (!self.instance.isOpen) {
                    self.selectmenu.selectmenu('open');
                    self.button.addClass('ui-state-focus');
                }
            });

            self.button.on('mouseleave', function () {
                self.selectmenu.selectmenu('close');
                self.button.removeClass('ui-state-focus');
            });

            self.button.on('click', function () {
                if (!self.instance.isOpen) {
                    self.button.removeClass('ui-state-focus');
                }
            });
        }
    }

    onChange() {
        let selectedOption = this.$el.find('option:selected'),
            selectedOptiopURL = selectedOption && selectedOption.val();

        this.selectmenu.selectmenu('close');
        this._makeAjax(selectedOptiopURL);
    }

    //
    // Internally used functions.
    //

    updateSelectMenuTitle() {
        let titleName = this.$el.find('option:selected').data('name');

        if (String(titleName) === 'undefined') {
            titleName = window.Constants.sortTitle;
        }

        this.$el.find('.ui-selectmenu-text').html(titleName);
    }

    /**
     * @param {[]} params Array with objects.
     */
    _makeAjax(url) {
        if (!url || typeof url !== 'string') {
            return;
        }

        history.pushState(null, '', url);

        $.ajax({
            url: url + '&format=ajax'
        }).done(function (data) {
            emitter.emit('updatesearchresult', data);
            searchPaginationEmitter.emit('move');
        });
    }
}
