'use strict';

import Component from '_core_ext/components/Component';
import {Progress} from '_core_ext/progress';
import util from '_core_ext/util';

export default class ProductSetActions extends Component {
    init() {
        super.init();
        this.loader = new Progress(this.$el);
        this.event('click', '.js-product-set-item .swatchanchor', this.updateSetItem);
        this.event('change', '.js-product-set-error-msg .js-age-checkbox', this.hideAgeWarning);
        this.eventMgr('flightdetails.reloadpage', this.reload);
    }

    //
    // Event handlers.
    //
    reload() {
        location.reload();
    }

    hideAgeWarning(element) {
        if ($(element).prop('checked')) {
            this.$el.find('.js-age-warn').text('');
        }
    }

    updateAddAllToCartBtn() {
        if ($document.find('.js-not-available-msg').size() === 0) {
            $document.find('.js-add-all').removeClass('js-outofstock-warn');
            $document.find('.js-product-set-error-msg .js-outofstock-warn').addClass('h-hidden');
        }
    }

    updateSetItem (element, event) {
        event.preventDefault();

        if ($(element).parents('li').hasClass('unselectable')) { return; }

        let url = Urls.getSetItem + element.search;
        let $container = $(element).closest('.js-product-set-item');
        let qty = $container.find('input[name="Quantity"]').first().val();
        let params = {
            Quantity: isNaN(qty) ? '1' : qty,
            format: 'ajax'
        };

        this.loader.show();
        let self = this;

        $.ajax({
            url: util.appendParamsToUrl(url, params),
            type: 'GET'
        }).done(function (data) {
            $container.html(data);
            self.updateAddAllToCartBtn();
        });
    }
}

module.exports = ProductSetActions;
