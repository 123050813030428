var Constants = require('_core_ext/components/Constants');
var EventEmitter = require('events');
var eventMgr = require('_core_ext/eventMgr');
var loadedLibs = [];

function Component($el, config) {
    this.$el = $el;
    this.config = $.extend({}, config);
    this.listeners = [];
    this.eventMgrListeners = [];
    this.items = [];
    this.emitter = new EventEmitter();

    if (this.config.cmpId) {
        this.id = this.config.cmpId;
    } else {
        if (this.$el.attr('id')) {
            this.id = this.$el.attr('id');
        }
        if (!this.id && this.config.id) {
            this.id = this.config.id;
        }
    }

    this.shown = !this.config.hidden;
}

Component.prototype.init = function () {
    if (this.config.hidden) {
        this.hide();
    } else {
        this.show();
    }
    this.$el
        .addClass('m-cmp_loaded')
        .removeClass('m-cmp_loading');
};

Component.prototype.destroy = function () {
    var i, item, event, $element;

    while ((event = this.listeners.pop())) {
        $element = event.shift();
        $element.off.apply($element, event);
    }
    while ((event = this.eventMgrListeners.pop())) {
        eventMgr.off.apply(eventMgr, event);
    }
    for (i = 0; i < this.items.length; ++i) {
        item = this.items[i];
        if (item) {
            if (typeof item.destroy === 'function') {
                item.destroy();
            }
            delete this.items[i];
        }
    }
    this.$el = null;
    this.emitter.removeAllListeners();
};

Component.prototype.isBindedToDom = function () {
    return !!this.$el.closest('html').length || this.$el.is('html');
};

Component.prototype.loadDependencies = function (dependencies) {
    var d = $.Deferred();

    if ('css' in dependencies) {
        dependencies.css.map(function (url) {
            if (loadedLibs.indexOf(url) === -1) {
                $('head').append('<link rel="stylesheet" type="text/css" href="' + url + '">');
            }
        });
    }

    var jsDeps = dependencies.js.map(function (url) {
        let script;

        if (loadedLibs.indexOf(url) === -1) {
            script = $.getScript(url);
        }
        return script;
    });

    $.when.apply($, jsDeps).done(function () {
        d.resolve();
    });

    return d;
};
/**
 * Assign action to event for DOM element
 * @param {String} eventName ex: 'click', 'change'
 * @param {String} selector CSS/jQuery selector
 * @param {function} cb
 * @param {JQueryElement} $element
 */
Component.prototype.event = function (eventName, selector, cb, $element) {
    var self = this,
        fn = function () {
            return cb.apply(self, [this].concat(Array.prototype.slice.call(arguments)));
        };

    if ($.isFunction(selector)) {
        $element = cb || self.$el;
        cb = selector;
        $element.on(eventName, fn);
        self.listeners.push([$element, eventName, fn]);
    } else {
        $element = $element || self.$el;
        $element.on(eventName, selector, fn);
        self.listeners.push([$element, eventName, selector, fn]);
    }
    return self;
};

Component.prototype.on = function (eventName, cb) {
    return this.emitter.on(eventName, cb);
};

Component.prototype.off = function (eventName, cb) {
    return this.emitter.off(eventName, cb);
};

Component.prototype.once = function (eventName, cb) {
    return this.emitter.once(eventName, cb);
};

Component.prototype.eventMgr = function (event, handler) {
    handler = handler.bind(this);
    eventMgr.on(event, handler);
    this.eventMgrListeners.push([event, handler]);
};
/**
 * Search for child component instance which is returned in callback
 * @param {String} id of component
 * @param {function(Component)} cb
 */
Component.prototype.getById = function (id, cb) {
    let result;

    for (let c = 0; c < this.items.length; ++c) {
        let item = this.items[c];

        if (item) {
            if (item.id === id) {
                result = cb.call(this, item);
                break;
            } else {
                item.getById(id, cb);
            }
        }
    }
    return result;
};
/**
 * Travels over nearest/next level child components
 * @param {function(Component)} fn
 * @return {any[]} arrays of callback results
 */
Component.prototype.eachChild = function (fn) {
    return this.items.map(function (item) {
        return fn(item);
    });
};

Component.prototype.eachChildWithClass = function (childComponentClass, fn) {
    this.eachChildDeep(chld => {
        if (chld instanceof childComponentClass) {
            fn(chld);
        }
    });
};
/**
 * Travels over all child components
 * @param {function(Component)} fn
 */
Component.prototype.eachChildDeep = function (fn) {
    for (var c = 0; c < this.items.length; ++c) {
        var item = this.items[c];

        if (item) {
            fn(item);
            item.eachChildDeep(fn);
        }
    }
};
Component.prototype.hide = function () {
    if (this.shown) {
        this.$el.addClass(Constants.GLOBAL.hiddenClass);
        this.shown = false;
    }
};
Component.prototype.show = function () {
    if (!this.shown) {
        this.$el.removeClass(Constants.GLOBAL.hiddenClass);
        this.shown = true;
    }
};
Component.prototype.isHidden = function () {
    return !this.shown;
};
Component.prototype.isShown = function () {
    return this.shown;
};

module.exports = Component;
