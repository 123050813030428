'use strict';

import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';
import {redirect as pageRedirect} from '_core_ext/page';

const addToCartEmitter = eventMgr.getEmitter('addtocart');

export default class AddToCart extends Component {
    init() {
        super.init();
        this.dialog = this.items[0];
        this.button = this.$el.find('.js-addtocart-btn');
        this.pid = this.$el.data('pid');
        if (this.$el.data('alcohol')) {
            this.button.addClass('disabled');
            $(`#ageVerification-${this.pid}.js-age-checkbox`).on('change', () => {
                if ($('.js-product-country select').find('option:selected').val() !== '') {
                    this.button.toggleClass('disabled');
                }
            });
            // clear checkbox when user clicks Back in browser
            setTimeout(() => {
                $('.js-addtocarg-age-block').css('pointer-events', 'all');
                $(`#ageVerification-${this.pid}.js-age-checkbox`).prop('checked', false);
            }, 500);
        }
        this.event('click', '.js-addtocart-btn', this.addProductToCart);
        this.eventMgr('wishlistbeforeadd.closewishlistremoveconfirmation', this.closeWishlistBeforeAdd);
    }

    closeWishlistBeforeAdd() {
        this.dialog.close();
    }

    addProductToCart(element, event) {
        event.preventDefault();

        let $rootElement = $document.find('.js-product-set-item').size() > 0 &&
            $(element).closest('.js-add-all').size() === 0 ?
            $(element).closest('.js-product-set-item') :
            $(element).closest('.js-product-detail');

        //When add to cart button clicked from addtocart-confirmation modal, Root element set to recommended product tile in addtocart-confirmation modal.
        if ($rootElement.length === 0) {
            $rootElement = $(element).closest('.js-product-recommendation-for-cart');
        }

        //if no size variant chosen, show error message.
        var hasSizeSwatchess = $rootElement.find('.b-product-variations__value--size').length > 0;
        var isSizeSwatchSelected = $rootElement.find('.b-product-variations__value--size').find('li.selected').length > 0;

        if (hasSizeSwatchess && !isSizeSwatchSelected) {
            $document.find('.js-select-variant-msg').removeClass('h-hidden');
            return;
        }

        if ($(element).closest('.js-add-all').size() === 0) {
            if ($(element).hasClass('js-select-variant-warn')) {
                $rootElement.find('.js-select-variant-msg').removeClass('h-hidden');
                return;
            } else if (
                $rootElement.find('.js-age-checkbox').size() > 0 &&
                !$rootElement.find('.js-age-checkbox').prop('checked')
            ) {
                $rootElement.find('.js-age-warn').text(window.Resources.AGE_WARNING);
                return;
            }
        } else {
            if ($document.find('.js-select-variant-warn').size() > 0) {
                $document.find('.js-product-set-error-msg .js-select-variant-msg').removeClass('h-hidden');
                return;
            } else if ($(element).closest('.js-outofstock-warn').size() > 0) {
                $document.find('.js-product-set-error-msg .js-outofstock-warn').removeClass('h-hidden');
                return;
            } else if (
                $document.find('.js-add-all .js-age-checkbox').size() > 0 &&
                !$document.find('.js-add-all .js-age-checkbox').prop('checked')
            ) {
                $document.find('.js-product-set-error-msg .js-age-warn').text(window.Resources.AGE_WARNING);
                return;
            }
        }
        if ($document.find('.js-wl-owner').size() > 0 && $document.find('.js-add-to-cart-confirmation').size() === 0) {
            this._makeAjaxCallWishList($rootElement);
        } else {
            this._makeAjaxCall($rootElement);
        }
        return;
    }

    _makeAjaxCallWishList($rootElement) {
        const $qty = $rootElement.find('.js-item-quantity-field'),
            addToCartURL = util.ajaxUrl(Urls.addProduct),
            productID = this.$el.data('pid'),
            qty = $qty ? $qty.val() : 1,
            $uuid = $rootElement.find('#uuid'),
            uuid = $uuid.length > 0 ? $uuid.val() : '',
            cartAction = uuid ? 'update' : 'add',
            uuidWL = this.$el.data('uuidwl'),
            self = this;

        if (!productID) {
            return;
        }

        if (!uuidWL) {
            $.ajax({
                url: util.ajaxUrl(Urls.replaceWishlistItem),
                data: {
                    pid: productID,
                    Quantity: qty,
                    cartAction: cartAction,
                    uuid: uuid
                }
            }).done(function () {
                addToCartEmitter.emit('updatewishlist');
                addToCartEmitter.emit('closedialog');
            });
            return;
        }

        $.ajax({
            url: addToCartURL,
            type: 'POST',
            data: {
                pid: productID,
                Quantity: qty,
                cartAction: cartAction,
                uuid: uuid
            }
        }).done(function (html) {
            let url;

            url = util.appendParamsToUrl(Urls.showRemoveWLConfirmation, {
                uuidWL: uuidWL,
                pid: productID
            });

            addToCartEmitter.emit('updateminicart', html);
            self.dialog.open({
                options: {
                    customClass: 'b-popup b-popup--before-you-add'
                },
                url: url
            });
        });
    }

    _makeAjaxCall($rootElement) {
        let childPids = [],
            childQtys = [];

        if (!$rootElement.hasClass('js-product-set-item') && $rootElement.find('.js-bundle').size() === 0) {
            $document.find('.js-product-set-item').each(function () {
                let $this = $(this);

                childPids.push($this.find('[data-pid]').attr('data-pid'));
                childQtys.push($this.find('.js-item-quantity-field').val());
            });
        }
        childPids = childPids.join(',');
        childQtys = childQtys.join(',');

        const $qty = $rootElement.find('.js-item-quantity-field'),
            addToCartURL = util.ajaxUrl(Urls.addProduct),
            productID = this.$el.data('pid'),
            qty = $qty ? $qty.val() : 1,
            $uuid = $rootElement.find('#uuid'),
            uuid = $uuid.length > 0 ? $uuid.val() : '',
            cartAction = uuid ? 'update' : 'add',
            hidedialog = this.$el.data('hidedialog'),
            self = this;

        if (!productID) {
            return;
        }

        $.ajax({
            url: addToCartURL,
            type: 'POST',
            data: {
                pid: productID,
                Quantity: qty,
                childPids: childPids,
                childQtys: childQtys && childQtys.toString() !== 'null' ? childQtys : '',
                cartAction: cartAction,
                uuid: uuid
            }
        }).done(function (html) {
            let url;

            if (uuid) {
                return pageRedirect(Urls.cartShow);
            } else {
                addToCartEmitter.emit('updateminicart', html);

                if (hidedialog) {
                    addToCartEmitter.emit('closedialog');
                } else {
                    url = util.appendParamsToUrl(Urls.showAddProductConfirmation, {
                        pid: productID,
                        childPids: childPids,
                        bonusDiscountLineItemUUID: $document.find('.js-bonus-discount-line-item').attr('data-uuid')
                    });
                    self.dialog.open({
                        options: {
                            customClass: 'b-popup b-popup--add-to-cart'
                        },
                        url: url
                    });
                }
            }

            addToCartEmitter.emit('added', {
                quantity: qty,
                cmp: self
            });
        });
    }
}