'use strict';

import Component from '_core_ext/components/Component';

export default class NavigationMenu extends Component {
    init() {
        super.init();
        this.initCache();

        this.event('mouseover', this.cache.rootClassSelector, this.onRootHover);
        this.event('mouseout', this.onMouseOut);
    }

    initCache() {
        this.cache = {};
        this.cache.rootClassSelector = '.js-root-item';
    }

    //
    // Component actions.
    //

    onMouseOut() {
        this._hideAllSubMenu();
    }

    onRootHover(element) {
        let subMenu = $(element).children('ul');

        this._hideAllSubMenu();

        if (subMenu.length) {
            subMenu.addClass('is-open');
        }
    }

    //
    // Internally used functions.
    //

    _hideAllSubMenu() {
        this.$el
            .find(this.cache.rootClassSelector)
            .children('ul')
            .removeClass('is-open');
    }
}

module.exports = NavigationMenu;
