import Component from '_core_ext/components/Component';
import {getDeviceType} from '_core_ext/screendetector';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('dynamicimage');

export default class DynamicImage extends Component {
    init () {
        const self = this;

        this.eventMgr('screenDetector.mobile', this.setImage);
        this.eventMgr('screenDetector.tablet', this.setImage);
        this.eventMgr('screenDetector.desktop', this.setImage);
        this.event('mouseleave', this.setAltImage);
        this.setImage();
        this.event('mouseover', function () {
            self.setAltImage('Alt');
        });
    }
    loadImage (imgSrc) {
        if (!this.$img) {
            this.$img = $('<img/>').attr({
                alt: this.config.alt,
                title: this.config.title,
                class: this.config.class
            });
            this.$el.append(this.$img);
        }
        this.$img.one('load', () => {
            this.emitter.emit('loaded', this);
            emitter.emit('imageload', this.$img);
        }).one('error', () => {
            this.$img.attr('src', Urls.noImage);
            emitter.emit('imageload', this.$img);
        });
        this.$img.attr('src', imgSrc);
    }
    setImage (screenType, imageSource) {
        var imgSrc;

        if (imageSource) {
            imgSrc = imageSource;
        } else {
            if (!screenType) {
                screenType = getDeviceType();
            }
            if (window.devicePixelRatio > 1) {
                screenType = `${screenType}Retina`;
            }

            this.screenType = screenType;
            imgSrc = this.config[`${screenType}Src`] || this.config.defaultSrc;
        }

        this.loadImage(imgSrc);
    }
    setAltImage (imageType) {
        var imgAltSrc;

        if (typeof imageType !== 'string') {
            imageType = '';
        }

        imgAltSrc = this.config[`${this.screenType}Src${imageType}`];

        if (imgAltSrc) {
            this.loadImage(imgAltSrc);
        }
    }
}

module.exports = DynamicImage;
