'use strict';

import Component from '_core_ext/components/Component';

export default class ScrollToTop extends Component {
    init() {
        super.init();     
        this.event('click', this.onClick);
    }

    onClick() {
        var delay = 500;
        $('body,html').animate({scrollTop: 0}, delay);
    }
}
