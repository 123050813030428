import screendetector from '_core_ext/screendetector';
import eventMgr from '_core_ext/eventMgr';

import Block from '_core_ext/components/common/Block';
import DynamicImage from '_core_ext/components/common/DynamicImage';
import Constants from '_core_ext/components/Constants';

import {assign} from 'lodash';

import 'slick-carousel';

const emitter = eventMgr.getEmitter('carousel');

export default class Carousel extends Block {
    init() {
        super.init();
        let hasDynamicImage = false;

        this.slickConfig = assign({},
            Constants.CAROUSEL.defaultConfig,
            JSON.parse(Resources.CAROUSEL_DEFAULT_CONFIG),
            this.config
        );

        this.slickConfig.slide = ':not(script)';

        delete this.slickConfig.jsonConfig;
        delete this.slickConfig.cmp;

        this.event('touchstart', this.stopTouch);
        this.event('touchend', this.stopTouch);
        this.event('touchmove', this.stopTouch);
        this.event('beforeChange', this.onSlideChange);
        this.event('mouseover', 'button', this.onButtonMouseover);
        this.event('mouseout', 'button', this.onButtonMouseout);
        this.$el.addClass(this.slickConfig.customClass);
        this.eachChildDeep(cmp => {
            if (cmp instanceof DynamicImage) {
                hasDynamicImage = true;
                cmp.once('loaded', () => {
                    if (!this.slickInitialized) {
                        if (this.config.disableOn) {
                            this.initDisabling();
                        } else {
                            this.initSlick();
                        }
                    }

                    emitter.emit('imgloaded');
                });
            }
        });

        this.eventMgr('wishlist.addToWishlist', this.isAddToWLActionFromCarousel);

        if (this.config.disableOn) {
            this.initDisabling();
        } else if (!hasDynamicImage) {
            this.initSlick();
        }
    }

    isAddToWLActionFromCarousel(wishlistCmp, pid) {
        if (this.$el.find('[data-pid=' + pid + ']')) {
            wishlistCmp.wishlistFromCarousel = true;
        }
    }

    highLightActiveSlide(el) {
        if (el) {
            $('.js-p-thumbnails-list-el').removeClass('m-current');
            $(el).addClass('m-current');
        }
    }

    initDisabling() {
        this.eventMgr('screenDetector.mobile', this.onDeviceChange);
        this.eventMgr('screenDetector.tablet', this.onDeviceChange);
        this.eventMgr('screenDetector.desktop', this.onDeviceChange);
        this.onDeviceChange(screendetector.getDeviceType());
    }
    initSlick() {
        if (!this.slickInitialized) {
            this.slickInitialized = true;
            this.$el.on('init', function () {
                var $dialogWrap = $(this).closest('.ui-dialog-content');

                if ($dialogWrap.length !== 0) {
                    $dialogWrap.dialog({
                        position: {
                            my: 'center',
                            at: 'center',
                            of: window
                        }
                    });
                }
            }).slick(this.slickConfig);
            this.eachChild(cmp => {
                if (typeof cmp.reinit === 'function') {
                    cmp.reinit();
                }
            });
        }
    }
    toggleDraggable(state) {
        this.$el.slick('slickSetOption', 'draggable', !state, false);
        this.$el.slick('slickSetOption', 'swipe', !state, false);
    }
    unSlick() {
        if (this.slickInitialized) {
            this.slickInitialized = false;
            this.$el.slick('unslick');
        }
    }
    onDeviceChange(device) {
        var disable = this.config.disableOn.indexOf(device) >= 0;

        if (disable) {
            this.unSlick();
        } else {
            this.initSlick();
        }
    }
    onSlideChange(el, event, slick, currentIndex, newIndex) {
        if (this.slickConfig.infinite) {
            eventMgr.execute('componentmgr.update', this);
        }
        this.emitter.emit('changeSlide', newIndex);

        if (this.config.asNavFor) {
            this.$el.slick('asNavFor', newIndex);
            if (this.$el.hasClass('slider-for')) {
                var $activeSlide = this.$el.slick('getNavTarget').slick('getSlick').$slides[newIndex];


                this.highLightActiveSlide($activeSlide);
            }
        }
        var primaryImageClass = '.b-product-images__primary-image-link';
        var videoEl = this.$el.find(primaryImageClass)[newIndex] ? this.$el.find(primaryImageClass)[newIndex].querySelector('.js-pdp-video-element') : null;
        if (videoEl) {
            videoEl.currentTime = 0;
            videoEl.play();
        }

        emitter.emit('sliderchanged', newIndex);
    }
    reinit() {
        //be careful: can duplicate navigation arrows
        if (this.slickInitialized) {
            this.$el.slick('reinit');
        }
    }
    nextSlide() {
        const slick = this.$el.slick('getSlick');

        this.$el.slick('slickGoTo', slick.currentSlide + 1, true);
    }
    stopTouch(el, e) {
        e.stopPropagation();
    }
    destroy() {
        if (this.slickInitialized) {
            this.$el.slick('destroy');
        }

        super.destroy();
    }
    onButtonMouseover() {
        this.$el.slick('slickPause');
    }
    onButtonMouseout() {
        if (this.slickConfig.autoplay) {
            this.$el.slick('slickPlay');
        }
    }
}

module.exports = Carousel;
