'use strict';

import Form from '_core_ext/components/forms/Form';
import eventMgr from '_core_ext/eventMgr';
import {appendParamsToUrl} from '_core_ext/util';

const emitter = eventMgr.getEmitter('notifyme');

export default class NotifyMe extends Form {
    init() {
        super.init();
        this.event('click', '.js-notifyme-save', this.save);
    }

    //
    // Event handlers.
    //
    save (element, event) {
        event.preventDefault();

        let pid = this.$el.find('#pid').val();
        let email = this.$el.find('.js-notifyme-email input').val();
        let recaptchaTokenVal = this.$el.find('.js-recaptcha-token').find('input').val();
        
        if (email && pid && this.isChildsValid()) {
            $.ajax({
                url: appendParamsToUrl(Urls.notifyMeSave, {
                    pid: pid,
                    email: email,
                    recaptchaToken: recaptchaTokenVal
                })
            }).done(function () {
                emitter.emit('closedialog');
            });
        } else {
            return;
        }
    }
}

module.exports = NotifyMe;
