'use strict';

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';
import {assign} from 'lodash';
import eventMgr from '_core_ext/eventMgr';

const emitter  = eventMgr.getEmitter('stickyheader');

export default class StickyHeader extends Component {
    init() {
        super.init();
        this.config = assign({}, Constants.STICKY_HEADER.defaultConfig, this.config);

        this.initCache();
        this.initHeaderPosition();

        this.event('click', '.js-sticky-search', this.onMenuToggleClick);

        this.event('click', function () {
            emitter.emit('stickyheaderclicked');
        });

        this.eventMgr('document.scroll', this.onDocumentScroll);

        this.eventMgr('document.ready', this.shiftBody);
        this.eventMgr('window.resize', this.shiftBody);
        this.eventMgr('flightdetails.closeflyout', this.shiftBody);

        this.eventMgr('minicart.itemupdated', this.updateMiniCartTotal);
        this.eventMgr('addtocart.updateminicart', this.updateMiniCartTotal);
        this.eventMgr('giftvoucher.updateminicart', this.updateMiniCartTotal);
    }

    shiftBody() {
        $('.js-main-slider').css('min-height', 'calc(100vh - ' + this.$el.height() + 'px - 60px)');
        if ($(window).width() > 1223) {
            $('.js-mobile-menu-category').removeAttr('style');
            $('body').removeClass('nav-active');
        }
    }

    initCache() {
        this.cache = {};
        this.cache.navigation = this.$el.find('.js-navigation');
    }

    /**
     * Update minicart total here due to prevent a lot of ajax calls.
     * All carts contains in this components.
     *
     * On one page we have mini cart total for:
     * -- Desktop
     * -- Desktop flyout
     * -- Mobile
     * -- Mobile flyout
     */
    updateMiniCartTotal() {
        let self = this;

        $.ajax({
            url: Urls.miniCartTotal
        }).done(function (html) {
            if (html) {
                self.$el.find('.js-mini-cart-link').replaceWith(html);
            }
        });
    }

    onMenuToggleClick() {
        let self = this;

        this.$el.toggleClass('active-search');
        // Need some delay as focus cannot be applied to hidden element.
        // As for css was set some delay here we need it as well.
        setTimeout(function () {
            self.$el.find('.js-search-field').focus();
        }, 300);
    }

    initHeaderPosition() {
        var navigation = this.cache.navigation,
            navigationHeight = navigation.outerHeight(),
            navigationOffsetTop = navigation.offset().top;

        this.headerBottom = navigationOffsetTop + navigationHeight;
    }

    onDocumentScroll() {
        var scrollTop = $document.scrollTop();

        //the PLP filter and sort div to stick when scrolling
        var $plpFilterDivToStick = $('.b-search-result-option');
        var plpFilterStickClass = 'sticky-plp-filter';

        if (scrollTop > this.headerBottom && !this.$el.hasClass(this.config.stickyClass)) {
            if (this.$el.find('.js-flight-details-flayout-mobile').children().length === 0) {
                this.$el.addClass(this.config.stickyClass);
                this.$el.parents('#wrapper').addClass(this.config.paddingClass);
                if ($plpFilterDivToStick.length > 0) {
                    $plpFilterDivToStick.addClass(plpFilterStickClass);
                }
            }
        } else if (scrollTop < this.headerBottom && this.$el.hasClass(this.config.stickyClass)) {
            this.$el.removeClass(this.config.stickyClass);
            this.$el.parents('#wrapper').removeClass(this.config.paddingClass);
            if ($plpFilterDivToStick.length > 0) {
                $plpFilterDivToStick.removeClass(plpFilterStickClass);
            }
        }
    }
}

module.exports = StickyHeader;
