import Dialog from '_core_ext/components/common/Dialog';
import {getCookie, setCookie} from '_core_ext/util';

export default class InformativeDialog extends Dialog {
    init() {
        super.init();
        if(!getCookie('md-info')) {
            this.open(this.config);
            setCookie('md-info', true, new Date ( Date.now() + 86400e3 ));
        }
    }
}
