'use strict';

import Component from '_core_ext/components/Component';

export default class Tooltip extends Component {
    init() {
        super.init();
        this.initTooltip();
    }

    // Initialize tooltips.
    initTooltip() {
        this.$el.tooltip({
            track: true,
            position: {
                using: function (position, feedback) {
                    $(this).css(position).find('.js-arrow').remove();
                    $('<div class="js-arrow">')
                        .addClass('ui-tooltip__arrow-' + feedback.vertical)
                        .appendTo(this);
                }
            },
            content: this.$el.attr('title')
        });
    }
}
