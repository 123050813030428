'use strict';

/**
 * Components config params.
 *
 * data-scroll-destination:
 *  -- screen -- will scroll one screen if document height > screen height.
 *  -- itself -- will scroll to the currect position of scrolldown component.
 *
 * data-scroll-speed:
 * -- number with speed of scrolling.
 *
 * data-delta:
 * -- you can specify custom position which will be minus from counted.
 */

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';

export default class ScrollDown extends Component {
    init() {
        super.init();
        this.event('click', this.onClick);
        this.eventMgr('document.scroll', this._updateArrowState);
        this.eventMgr('document.ready', this._updateArrowState);
    }

    onClick() {
        let destination = this.$el.data('scroll-destination'),
            speed = this.$el.data('scroll-speed'),
            delta = Number(this.$el.data('delta')) || 0;

        if (window.$document.height() < window.$window.height()) {
            return;
        }

        if (destination === 'screen') {
            this._scroll(window.$window.height() - delta, speed);
        } else if (destination === 'itself') {
            let position = this.$el.offset().top - delta;

            this._scroll(position, speed);
        }

        return;
    }

    // Scroll page the page.

    _scroll(scrollTop, speed) {
        let self = this;

        window.$html.animate({
            scrollTop: scrollTop
        }, speed || 500, function () {
            self.$el.addClass(Constants.GLOBAL.hiddenClass);
        });
    }

    // Updates arrow state. It can be in two state: hidden/visible.
    // If window scrolltop > 0 hide this arrow, and show it equals 0.

    _updateArrowState() {
        if (window.$window.scrollTop() > 0) {
            this.$el.addClass(Constants.GLOBAL.hiddenClass);
        } else {
            this.$el.removeClass(Constants.GLOBAL.hiddenClass);
        }
    }
}

module.exports = ScrollDown;
