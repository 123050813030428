module.exports = {
    'productImpression': function (analyticsData) {
        let
            $productsDetails = $('.js-product-gtm'),
            data = analyticsData || {},
            products = [];

        if ($productsDetails.length) {
            $productsDetails.each(function () {
                let
                    self = $(this),
                    productData = self.data('productdetails'),
                    position = self.data('idx') || 0;

                if (productData) {
                    products.push({
                        'name': productData.name,
                        'id': productData.id,
                        'price': productData.price,
                        'brand': productData.brand,
                        'category': productData.category,
                        'variant': productData.variant,
                        'list': window.pageContext.analytics.page.type || '',
                        'position': position || 0
                    });
                }

                self.addClass('js-gtm-send');
            });

            dataLayer.push({
                'event': 'eeProductImpression',
                'ecommerce': {
                    'currencyCode': data.page ? data.page.currencyCode : '',
                    'impressions': products
                }
            });
        }
    },
    'productClick': function (analyticsData, component) {
        let dataObject = {
                'event': 'eeProductClick',
                'ecommerce': {
                    'click': {
                        'actionField': {
                            'list': {}
                        },
                        'products': []
                    }
                }
            },
            productData = component.$el.data('productdetails'),
            position = component.$el.data('idx') || 0;

        if (productData) {
            let productObject = {
                'name': productData.name,
                'id': productData.id,
                'price': productData.price,
                'brand': productData.brand,
                'category': productData.category,
                'variant': productData.variant,
                'position': position || 0
            };

            dataObject.ecommerce.click.products.push(productObject);
            dataObject.ecommerce.click.actionField.list = window.pageContext.analytics.page.type || '';
        }

        dataLayer.push(dataObject);
    },
    'productDetail': function (analyticsData) {
        let dataObject = {
                'event': 'eeProductDetail',
                'ecommerce': {
                    'detail': {
                        'actionField': {
                            'list': {}
                        },
                        'products': []
                    }
                }
            };

        if ('product' in analyticsData) {
            try {
                dataObject.ecommerce.detail.products.push(JSON.parse(analyticsData.product));
            } catch (error) {
                window.console.error(error);
            }
            dataObject.ecommerce.detail.actionField.list = pageContext.analytics.page.type || '';
        }

        dataLayer.push(dataObject);
    },
    'addToCart': function (analyticsData, params) {
        let data = analyticsData || {},
            dataObject = {
                'event': 'eeAddToCart',
                'ecommerce': {
                    'currencyCode': data.page ? data.page.currencyCode : '',
                    'add': {
                        'products': []
                    }
                }
            },
            productData = params.cmp.$el.data('productdetails');

        if (productData) {
            let productObject = {
                'name': productData.name,
                'id': productData.id,
                'price': productData.price,
                'brand': productData.brand,
                'category': productData.category,
                'variant': productData.variant,
                'quantity': params.quantity || 1,
                'image': productData.image || ''
            };

            dataObject.ecommerce.add.products.push(productObject);
        }

        dataLayer.push(dataObject);
    },
    'removeFromCart': function (analyticsData, params) {
        let dataObject = {
                'event': 'eeRemoveFromCart',
                'ecommerce': {
                    'remove': {
                        'products': []
                    }
                }
            },
            productData = params.$el.data('productdetails');

        if (productData) {
            let productObject = {
                'name': productData.name,
                'id': productData.id,
                'price': productData.price,
                'brand': productData.brand,
                'category': productData.category,
                'variant': productData.variant,
                'quantity': Number(params.$el.data('quantity')) || 1
            };

            dataObject.ecommerce.remove.products.push(productObject);
        }

        dataLayer.push(dataObject);
    },
    'promotionImpression': function () {
        let $promotionDetails = $('.js-promotions:not(.slick-cloned)'),
            dataObject = {
                'event': 'eePromotionImpression',
                'ecommerce': {
                    'promoView': {
                        'promotions': []
                    }
                }
            };

        if ($promotionDetails.length) {
            $promotionDetails.each(function () {
                let promotionData = $(this).data('promotions');

                if (promotionData) {
                    let promoObject = {
                        'id': 'id' in promotionData ? promotionData.id : '',
                        'name': 'name' in promotionData ? promotionData.name : '',
                        'creative': 'creative' in promotionData ? promotionData.creative : '',
                        'position': 'position' in promotionData ? promotionData.position : ''
                    };

                    dataObject.ecommerce.promoView.promotions.push(promoObject);
                }
            });
            dataLayer.push(dataObject);
        }
    },
    'promotionClick': function (analyticsData, params) {
        let elementData = params.$el.data('promotions'),
            dataObject = {
                'event': 'eePromotionClick',
                'ecommerce': {
                    'promoClick': {
                        'promotions': [{
                            'id': 'id' in elementData ? elementData.id : '',
                            'name': 'name' in elementData ? elementData.name : '',
                            'creative': 'creative' in elementData ? elementData.creative : '',
                            'position': 'position' in elementData ? elementData.position : ''
                        }]
                    }
                }
            };

        dataLayer.push(dataObject);
    },
    'checkout': function (analyticsData, currentPage) {
        let dataObject = {
            'event': 'eeCheckout',
            'ecommerce': {
                'checkout': {
                    'actionField': {
                        'step': analyticsData.checkout && analyticsData.checkout.step ? analyticsData.checkout.step : currentPage === 'checkout' ? 2 : 1
                    },
                    'products': []
                }
            }
        };

        analyticsData.basket.line_items && analyticsData.basket.line_items.forEach(function (item) {
            dataObject.ecommerce.checkout.products.push(item);
        });

        dataLayer.push(dataObject);
    },
    'purchase': function (analyticsData) {
        var dataObject = {
            'event': 'eePurchase',
            'ecommerce': {
                'purchase': {
                    'actionField': {
                        'id': analyticsData.transactionId,
                        'affiliation': analyticsData.affiliation,
                        'revenue': analyticsData.transactionTotal,
                        'coupon': analyticsData.couponCodes
                    },
                    'products': []
                }
            }
        };

        analyticsData.transactionProducts.forEach(function (item) {
            dataObject.ecommerce.purchase.products.push(item);
        });

        dataLayer.push(dataObject);
    },
    'noEcommerceData': function (analyticsData) {
        let dataObject = {
            'userType': analyticsData.user.type,
            'staffOrganisation': analyticsData.user.staffOrganisation || '',
            'staffFlying': analyticsData.user.isFlying === 'no' ? false : true,
            'departingTo': analyticsData.flydetails.departingTo,
            'departingFrom': analyticsData.flydetails.departingFrom,
            'departureDate': analyticsData.flydetails.departureDate,
            'daysBeforeFlight': analyticsData.flydetails.daysBeforeFlight || '',
            'terminal': analyticsData.flydetails.treminal,
            'userResidence': analyticsData.user.residence || '',
            'isPickUp': analyticsData.flydetails.isPickUp,
            'pickUpType': analyticsData.flydetails.pickUpType,
            'airportWiFi': analyticsData.flydetails.airportWiFi || ''
        };

        dataLayer.push(dataObject);
    }
};