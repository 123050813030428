'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('deleteaddress');

export default class DeleteAddress extends Component {
    init() {
        super.init();
        this.event('click', '.js-cancel', this.cancel);
    }

    cancel(element, event) {
        event.preventDefault();
        emitter.emit('closedialog');
    }
}
module.exports = DeleteAddress;
