'use strict';

import Form from '_core_ext/components/forms/Form';

export default class SubscribeForm extends Form {
    init() {
        super.init();
        this.event('click', '.js-subscribe', this.submit);
        this.event('change', '.js-signup', this.toggleButton);
        this.isCheckedSignUp();
    }

    isCheckedSignUp() {
        let signUp = $('.js-signup').get(0),
            submitButton = $('.js-subscribe'),
            isQuicksubscribe = $('.b-quicksubscribe').length;

        if (isQuicksubscribe) {
            return true;
        }

        if (!!signUp && signUp.checked) {
            submitButton.removeClass('disabled');
            return true;
        }

        return false;
    }

    toggleButton(element, event) {
        event.preventDefault();
        let submitButton = $('.js-subscribe');

        if (element.checked && !!submitButton && submitButton.hasClass('disabled')) {
            submitButton.removeClass('disabled');
        } else if (!!submitButton && !submitButton.hasClass('disabled')) {
            submitButton.addClass('disabled');
        }

    }

    submit(element, event) {
        if (!this.isChildsValid() || !this.isCheckedSignUp()) {
            event.preventDefault();
            return;
        }

        let checkPhone = new RegExp(/^[+\d-\s]+$/g),
            phoneField = $('.js-phone input'),
            phoneValue = phoneField.val();
            
        if(phoneValue.length > 0) {
            if ( phoneValue !== '' && !checkPhone.test(phoneValue)) {
                event.preventDefault();
                let phoneField = this._findElementWithFormFieldId('phone');
    
                if (phoneField) {
                    phoneField.setError(window.Resources.PHONE_VALIDATION);
                }
    
                return;
            }
        }
    }

    _findElementWithFormFieldId(formFieldId) {
        let found = null;

        this.items.forEach(item => {
            if (item.id && ~item.id.indexOf(formFieldId)) {
                found = item;
            }
        });
        return found;
    }
    
}
module.exports = SubscribeForm;