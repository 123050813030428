'use strict';

import Component from '_core_ext/components/Component';

export default class ProductDetailsTabs extends Component {
    init() {
        super.init();
        this.event('click', '.js-tab', this.onClick);
    }

    onClick(element) {
        this.$el.find('.js-tab').removeClass('is-active');
        $(element).addClass('is-active');
    }
}

module.exports = ProductDetailsTabs;