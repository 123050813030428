'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

export default class Refinements extends Component {
    init() {
        super.init();

        this.eventMgr('refinementstoggler.toggle', this.onToggle);
        this.eventMgr('filterform.updatesearchresult', this.closeDialog);
    }

    onToggle() {
        if (this.dialog) {
          this.dialog.dialog('open');
        } else {
          this._openInDialog(this.$el);
          eventMgr.execute('componentmgr.update');
        }
    }

    closeDialog() {
        if (this.dialog) {
            this.dialog.dialog('close');
            this.dialog.remove();
        }
    }

    /**
     * Refinements component can open itself in dialog.
     * It need for mobile.
     *
     * @param {*} dialog
     */
    _openInDialog(dialog) {
        if (!dialog) {
            return;
        }

        this.dialog = this.dialog || dialog.dialog({
            dialogClass: 'b-popup b-popup--filter',
            modal: true,
            open: function () {
                $('.ui-widget-overlay').bind('click', function () {
                    dialog.dialog('close');
                });
                $('body').addClass('popup--active');
            },
            close: function () {
                dialog.dialog('close');
                $('body').removeClass('popup--active');
            }
        });
    }
}
