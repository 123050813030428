'use strict';

import Form from '_core_ext/components/forms/Form';
import util from '_core_ext/util';

export default class ResetPassword extends Form {
    init() {
        super.init();
        this.event('click', '.js-reset-password', this.reset);
    }

    reset(element, event) {
        event.preventDefault();
        if (!this.isChildsValid()) {
            return;
        }
        let url = util.appendParamsToUrl(Urls.passwordResetHandler, {
            email: this.$el.find('.js-email').find('input').val(),
            recaptchaToken: this.$el.find('.js-recaptcha-token').find('input').val()
        });
        let self = this;

        $.ajax({
            url: url
        }).done(function (html) {
            if (html) {
                self.$el.html(html);
            }
        });
    }
}
module.exports = ResetPassword;
