import FormGeneric from '_core_ext/components/forms/FormGeneric';
import {refresh as pageRefresh, redirect as pageRedirect} from '_core_ext/page';
import {appendParamsToUrl, fillAddressFields} from '_core_ext/util';
import {scrollTo} from '_core_ext/util';

import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('billingform');

export default class BillingForm extends FormGeneric {
    init() {
        super.init();

        this.$cache = {
            authipayDialog: this.$el.find('.js-authipay-dialog'),
            cardOptions: this.$el.find('.js-authipay-credit-options'),
            paymentMethods: this.$el.find('.js-payment-method'),
            gcPaymentMethods: this.$el.find('.js-gift-certificate-pm'),
            saveCard: this.$el.find('.js-save-card'),
            selectedCardField: this.$el.find('.js-selected-credit-card-id'),
            placeOrderBtn: this.$el.find('.js-credit-card-selected button')
        };

        this.ppRedirectUrl = this.$el.data('pp-redirect-url');

        let firstPayment = this.$cache.paymentMethods.eq(0);

        if (!firstPayment.is(':disabled')) {
            firstPayment.attr('checked', 'checked');
        }

        this.selectPaymentMethodByID(firstPayment.val());

        this.event('change', '.js-payment-method', this.selectPaymentMethod);
        this.event('change', '.js-credit-card-list', this.processCardSelection);
        this.event('change', '.js-shipping-address-select select', this.updateAddress);
        this.event('change', '.js-billing-address-select select', this.updateAddress);
        this.event('change', '.js-same-as-delivery input', this.toogleBillingForm);
        this.event('click', '.js-yes-coupon', this.showCouponCodeSection);
        this.event('click', '.js-no-coupon', this.hideCouponCodeSection);
        this.event('click', '.js-add-coupon', this.addCouponCode);
        this.event('submit', this.onSubmitForm);

        this.eventMgr('inputgeneric.hidevalidationerror', this.showValidationError);
        this.eventMgr('checkoutgiftvoucher.ordercovered', this.disablePayments);
    }

    showCouponCodeSection() {
        this.$el.find('.js-coupon-area').removeClass('h-hidden');
        this.$el.find('.js-coupon-code-error').removeClass('h-hidden');
    }
    hideCouponCodeSection() {
        this.$el.find('.js-coupon-area').addClass('h-hidden');
        this.$el.find('.js-coupon-code-error').addClass('h-hidden');
    }

    toogleBillingForm(el) {
        let billingFormContainer = this.$el.find('.js-billing-form-container');

        if (!el.checked) {
            $.ajax({
                url: Urls.getBillingAddressForm
            }).done(function (data) {
                if (data) {
                    billingFormContainer.append(data);
                }
            });
        } else {
            billingFormContainer.empty();
            eventMgr.execute('componentmgr.update');
        }
    }

    addCouponCode(el, e) {
        e.preventDefault();

        const couponCode = this.$el.find('.js-coupon-code').val(),
            recaptchaToken = this.$el.find('.js-recaptcha-token input').val(),
            csrfField = this.$el.find('.js-csrf'),
            url = Urls.addCouponFromBilling,
            self = this;

        if (!couponCode) {
            // ToDo: fix text when it is known
            this.$el.find('.js-coupon-error').text('Missing field');
            return;
        }

        let data = {
            couponCode: couponCode,
            recaptchaToken: recaptchaToken
        };

        data[csrfField.attr('name')] = csrfField.val();

        $.ajax({
            url: url,
            data: data
        }).done(function (data) {
            if (data) {
                let $updates = $(data);

                self.$el.find('.js-coupon-field')
                    .replaceWith($updates.find('.js-coupon-field'));

                emitter.emit('couponadded', data);
                self.showCouponCodeSection();
            } else {
                pageRefresh();
            }
            try {
                emitter.emit('updaterecaptcha');
            } catch (err) {
                // recaptcha error
            }
        });
    }

    showValidationError(data) {
        if (data.eventEl.find('.js-coupon-code').length > 0) {
            this.$el.find('.js-coupon-code-error').html(data.error);
        }
    }

    updateAddress(el) {
        let selected = $(el).children(':selected').first(),
            selectedAddress = $(selected).data('address');

        if (!selectedAddress) {
            return;
        }

        fillAddressFields(selectedAddress, $(el).closest('.js-address-form'));
    }

    disablePayments() {
        this.$cache.paymentMethods.attr('disabled', true);
        this.$cache.gcPaymentMethods.attr('checked', 'checked');
        this.$cache.saveCard.removeAttr('checked').attr('disabled', true);
    }

    processCardSelection(el) {
        this.selectedCreditCardID = $(el).val();

        if (this.selectedCreditCardID) {
            this.$cache.saveCard.removeAttr('checked').attr('disabled', true);
        } else {
            this.$cache.saveCard.removeAttr('disabled', true);
        }

        this.$cache.selectedCardField.val(this.selectedCreditCardID);
    }

    selectPaymentMethod(el, e) {
        this.selectPaymentMethodByID($(e.target).val());
    }

    selectPaymentMethodByID(paymentMethodName) {
        if (paymentMethodName === 'PayPal') {
            this.$cache.cardOptions.addClass('h-hidden');
            this.$cache.placeOrderBtn.text(window.Resources.BILLING_PLACE_ORDER_WITH_PAYPAL);
            return;
        }

        this.$cache.placeOrderBtn.text(window.Resources.BILLING_PLACE_ORDER);
        this.$cache.cardOptions.removeClass('h-hidden');
    }

    onSubmitForm(el, e) {
        const
            isFormValid = this.isValid(),
            self = this;

        if (!isFormValid) {
            return false;
        }

        if (this.$el.find('.js-payment-method:checked').val() === 'PayPal') {
            e.preventDefault();

            const submitData = this.$el.serialize();
            let redirectUrl = this.ppRedirectUrl;

            $.ajax({
                url: Urls.billing,
                type: 'POST',
                data: submitData
            }).done(function (data) {
                if (data) {
                    if (data.redirectUrl) {
                        pageRedirect(data.redirectUrl);
                        return;
                    }

                    if (data.token) {
                        redirectUrl = appendParamsToUrl(redirectUrl, data);
                        pageRedirect(redirectUrl);
                        return;
                    }

                    if (typeof data !== 'object') {
                        self.$el.parents('.js-billing-page').replaceWith(data);

                        scrollTo('.js-billing-page');
                    }
                }
            });
            return false;
        }

        if (!this.selectedCreditCardID && window.Resources.AUTHIPAY_IN_IFRAME) {
            e.preventDefault();

            let dialogConfig = JSON.parse(this.$cache.authipayDialog.attr('data-json-config'));

            dialogConfig.data = this.$el.serialize();
            emitter.emit('refreshdata', dialogConfig);
            this.$cache.authipayDialog.trigger('click');

            return false;
        }

        return true;
    }

    getEmitter() {
        return emitter;
    }
}
