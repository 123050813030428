import FormGeneric from './FormGeneric';

export default class Form extends FormGeneric {
    init() {
        super.init();
    }
    isChildsDeepValid(cb) {
        var valid = true;

        this.eachChildDeep(item => {
            if (!item.disabled && typeof item.validate === 'function') {
                if (!item.validate()) {
                    if (valid && item.setFocus) {
                        item.setFocus();
                    }
                    valid = false;
                }
            }
        });
        if (valid && typeof cb === 'function') {
            cb();
        }
        return valid;
    }
}
