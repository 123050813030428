import InputGeneric from './InputGeneric';

export default class InputRadio extends InputGeneric {
    getValue () {
        var val = '';

        this.$field.each((i, el) => {
            if (el.checked) {
                val = el.value;
            }
        });
        return val;
    }
    setValue (value) {
        this.$field.prop('checked', false);
        this.$field.filter('[value="' + value + '"]').prop('checked', true);
    }
}
