'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('countryselector');

export default class CountrySelector extends Component {
    init() {
        super.init();
        this.countries = JSON.parse(this.$el.attr('data-json-config'));
        this.locale = this.$el.attr('data-locale');
        // if need to check paired selector for some value, store it there
        this.actionButton = this.$el.attr('data-action-button');
        this.checkElement = this.$el.attr('data-check-element');
        this.fillSelector();
        this.checkButton();
        this.event('click', '.js-country-selector-value', this.toggle);
        this.event('click', '.js-country-selector-options', this.select);
        this.eventMgr('fdsteps.closeselector', this.close);
        this.eventMgr('fdstepsnz.closeselector', this.close);
        this.eventMgr('fdstepsca.closeselector', this.close);
    }

    close () {
        this.$el.find('.js-country-selector-options').addClass('h-hidden');
        this.$el.removeClass('selector--opened');
    }

    toggle() {
        if (this.$el.hasClass('disabled')) {
            return;
        }
        this.$el.find('.js-country-selector-options').toggleClass('h-hidden');
        this.$el.toggleClass('selector--opened');
    }

    fillSelector () {
        let options = '';
        let countries = this.countries[this.locale];

        for (let country in countries) {
            options += `<li class="disabledrag b-checkout-flightdetails__select--option" data-value="${country}">${countries[country].label}</li>`;
        }
        this.$el.find('.js-country-selector-options').html(options);
    }

    select (el, e) {
        let $item = $(e.target);

        // filter for stopping drag-n-drop country list element, pass only click at LI
        if (e.target.tagName !== 'UL') {
            this.$el.attr('data-value', $item.attr('data-value'));
            this.$el.attr('data-label', $item.text());
            this.$el.find('.js-country-selector-value').text($item.text());
            this.checkButton();
            emitter.emit('change');
            this.toggle();
        }
    }

    checkButton () {
        if (this.checkElement && $(`.${this.checkElement}`).attr('data-value') && this.$el.attr('data-value')) {
            $(`.${this.actionButton}`).removeClass('disabled');
        }
    }
}