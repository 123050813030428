'use strict';

import Component from '_core_ext/components/Component';
import {redirect as pageRedirect} from '_core_ext/page';
import eventMgr from '_core_ext/eventMgr';
import {ajaxUrl} from '_core_ext/util';
import 'core-js/features/array/from';

const emitter = eventMgr.getEmitter('cart');

export default class Cart extends Component {
    init() {
        const self = this;

        super.init();

        this.event('click', '.js-remove-item,.js-remove-coupon,.js-add-coupon,.js-checkout', function (el, e) {
            let action = $(el).attr('name');
            let $quantityInput = this.$el.find('.js-item-quantity-field');
            let wrongQuantity = false;

            e.preventDefault();

            $quantityInput.each(function () {
                let maxQuantityVal = $(this).closest('.cart-quantity-component').data('json-config').maxVal;

                if ($(this).val() > maxQuantityVal) {
                    wrongQuantity = true;
                }
            });

            if (wrongQuantity && !$(el).hasClass('js-remove-item')) {
                $quantityInput.trigger('change');
                return false;
            } else {
                self
                .updateBasket(true, action)
                .done(function () {
                    if (e.currentTarget.classList) {
                        let classList = Array.from(e.currentTarget.classList);

                        if (~classList.indexOf('js-remove-item')) {
                            emitter.emit('itemremoved', {
                                $el: $(el)
                            });
                        }
                    }
                });
            }
        });

        this.event('keydown', '.js-cart-coupon-code', this.applyCoupon);

        this.event('click', '.js-continue-shopping', this.getRedirectUrl);

        this.event('submit', '.js-cart-form', function (el, e) {
            e.preventDefault();
            return false;
        });

        this.eventMgr('quantityfield.qtychanged', function () {
            // Need to prevent update minicart is quantity was changes
            // from minicart.
            // Third arguments (arguments[3]) is check on isMinicart quantity was changed.

            if (arguments[3] && String(arguments[3]) === 'true') {
                return;
            }

            self.updateBasket(true);
        });

        this.eventMgr('flightdetails.updateminicart', function () {
            self.updateBasket(true);
        });

        this.eventMgr('flightdetails.reloadpage', function () {
            self.updateBasket(true);
        });

        this.eventMgr('minicart.itemremoved', function () {
            self.updateBasket(false);
        });

        this.eventMgr('minicart.itemupdated', function (isMiniCart) {
            // Update only if event came from minicart.
            // Couse if we on shopping bag page, and changing quantity from
            // it, it already will update basket. And we do not need fire event twice.
            // It may cause unnecessary basket updates.
            if (isMiniCart) {
                self.updateBasket(false);
            }
        });

        this.eventMgr('quantityfield.updateuuidtqy', function (uuid, qty) {
            this.$el.find('.js-quantity-field[data-uuid="' + uuid + '"] input').val(qty);
        });
    }

    applyCoupon(el, e) {
        if (e.key.toLowerCase() === 'enter') {
            e.preventDefault();
            this.$el.find('.js-add-coupon').click();
        }
    }

    getRedirectUrl(el, e) {
        e.preventDefault();

        $.ajax({
            url: Urls.continueShopping
        }).done(function (data) {
            pageRedirect(data.url);
        });
    }

    updateBasket(redrawMinicart, action, noParams, data) {
        let defaultAction = this.$el.find('.js-basket-update-cart-button').attr('name');
        let $form = this.$el.find('.js-cart-form');
        let self = this;

        if (!action) {
            action = defaultAction;
        }

        let tokenVal = $form.find('input[name="csrf_token"]').val();
        let cartFormURL = $form.attr('action');
        let submitData = '';

        if (noParams) {
            submitData = {};
            submitData[defaultAction] = defaultAction;
            submitData.csrf_token = tokenVal;
        } else {
            submitData = data ? data : $form.serialize();
            submitData = submitData.replace(new RegExp(defaultAction, 'g'), action);
        }

        cartFormURL = ajaxUrl(cartFormURL);

        return $.ajax({
            url: cartFormURL,
            type: 'POST',
            data: submitData
        }).done(function (data) {
            if (data) {
                // Take a part from response.
                let $updates = $(data).find('.js-cart-global').children();

                if ($updates.length) {
                    // Clean current cart html.
                    self.$el.empty();

                    // Append taken part from above to the cleaned element.
                    self.$el.append($updates);
                    emitter.emit('updaterecaptcha');
                } else {
                    // Here we go to checkout.
                    if (typeof data === 'object') {
                        window.location.assign(data.redirectUrl);
                    }
                }
            }
            if ($('.js-item-quantity-error-msg').length > 0) {
                $('body,html').animate({
                    scrollTop: $('.js-item-quantity-error-msg').position().top
                }, 300);
            }

            if (redrawMinicart) {
                emitter.emit('redrawminicart');
            }
        });
    }

    getEmitter () {
        return emitter;
    }
}
