'use strict';

/**
 * For correct work of this component, the html structure needs to be the next one:
 *
 * <ul data-cmp="accordion"> -- root level --
 * *   <li>
 * *   *   <a href="#" class="toggle"> Name of level </a>
 * *   *   <ul class="h-hidden"> -- hidden at start sub-category --
 * *   *   *   <li>
 * *   *   *   *   <a href="#" class="toggle"> Name of level </a>
 * *   *   *   *   <ul class="h-hidden"> -- hidden at start sub sub-category --
 * *   *   *   *   </ul>
 * *   *   *   </li>
 * *   *   *    ...
 * *   *    </ul>
 * *   </li>
 * *   ...
 * </ul>
 */

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';

import {assign} from 'lodash';

export default class Accordion extends Component {
    init() {
        super.init();
        this.config = assign({}, Constants.ACCORDION.defaultConfig, this.config);
        this.$el.addClass('js-root');
        this.event('click', 'li', this.onClick);
    }

    _findTargetChildren(target) {
        if (this.config.hiddenClass === 'desktop' || !target.hasClass('js-header-category-item')) {
            return target.children('ul');
        } else {
            return target.find('.js-root-item > ul > li > div > div').children('ul').add(target.find('.js-root-item').children('ul'));
        }
    }

    /* sonar-ignore-start */
    onClick(element, event) {
        event.stopPropagation();

        let $target = $(element),
            $eventTarget = $(event.target),
            targetList = $target && this._findTargetChildren($target),
            hasChild = targetList && targetList.length > 0,
            isTargetVisible = targetList.is(':visible');

        // Prevent default actions if title was clicked and it has a link.
        // And this link is not a last node in a tree.
        if (
            this.config.disableTitleLinks === true &&
            $target.find('ul').length > 0 &&
            $eventTarget.hasClass('toggle') || $eventTarget.parent('a').hasClass('toggle')
        ) {
            event.preventDefault();
        }

        let tagsAllowed = ['LI', 'A', 'I'];

        if (this.config.hiddenClass === 'mobile' && $target.hasClass('js-header-category-item')) {
            tagsAllowed.push('DIV');
        }

        if (!~tagsAllowed.indexOf(event.target.tagName)) {
            return;
        }

        // Collapse all menus no have only one is opened.
        // The new one will be expanded below.
        if (
            this.config.keepOne === true &&
            $target.parent().hasClass('js-root')
        ) {
            this._collapseAll(this.$el);
        } else if (
            this.config.keepOne === true &&
            !$target.closest('ul').hasClass('js-root')
        ) {
            this._collapseAll($target.closest('ul'));
        }

        if (isTargetVisible && this.config.collapseChilds === true) {
            this._collapse($target.find('ul')); // collapse all child.
            return;
        }

        if (
            hasChild &&
            (this.config.disableTitleLinks === false && event.target.tagName === 'A') ||
            // Prevent expand on when "clear" link was clicked for sample in Refinements.
            // Only if toggle link may have in scope.
            (event.target.tagName === 'A' && !$eventTarget.hasClass('toggle'))
        ) {
            return;
        }

        if (hasChild) {
            if (isTargetVisible) {
                this._collapse(targetList);
            } else {
                this._expand(targetList);
            }
            return;
        }
    }
    /* sonar-ignore-end */

    //
    // Internally used functions.
    //

    _collapseAll($element) {
        if (!$element) {
            return;
        }

        $element.find('ul')
            .addClass(this.config.hiddenClass === 'desktop' ? Constants.GLOBAL.hiddenClass : Constants.GLOBAL.hiddenMobileClass);

        $element.find('.' + this.config.expandedClass)
            .removeClass(this.config.expandedClass);

        return;
    }

    _expand(element) {
        if (typeof element !== 'object') {
            return;
        }

        element.removeClass(this.config.hiddenClass === 'desktop' ? Constants.GLOBAL.hiddenClass : Constants.GLOBAL.hiddenMobileClass)
            .addClass(this.config.expandedClass)
            .siblings('i')
            .addClass(this.config.expandedClass);
    }

    _collapse(element) {
        if (typeof element !== 'object') {
            return;
        }

        element.addClass(this.config.hiddenClass === 'desktop' ? Constants.GLOBAL.hiddenClass : Constants.GLOBAL.hiddenMobileClass)
            .removeClass(this.config.expandedClass)
            .siblings('i')
            .removeClass(this.config.expandedClass);
    }
}

module.exports = Accordion;
