'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('flightdetailslinkaccount');

export default class FlightDetailsLinkAccountMenu extends Component {
    init() {
        super.init();
        this.event('click', this.onClick);
    }

    onClick(element, event) {
        event.preventDefault();
        if ($(element).hasClass('js-mobile')) {
            window.$document.find('.js-flight-details-link-mobile').trigger('click');
            emitter.emit('hidemenu');
        } else {
            window.$document.find('.js-flight-details-link').trigger('click');
        }
    }
}
