'use strict';

import Form from '_core_ext/components/forms/Form';
import util from '_core_ext/util';

export default class ContactUs extends Form {
    init() {
        super.init();
    }

    onSubmit(element, event) {
        event.preventDefault();

        let isValid = super.onSubmit();

        if (isValid) {
            this._validateCommentField(this.$el, this._makeAjax);
         } else {
             this.$el.find('.js-send-error').removeClass('h-hidden');
         }
    }

    _validateCommentField(el, makeAjax) {
        $.ajax({
            url: Urls.customerServiceValidate,
            data: el.serialize(),
            dataType: 'html',
            method: 'POST'
        }).done(function (data) {
            if (data) {
                var responseObj = JSON.parse(data);
                if (responseObj.isValid) {
                    makeAjax(el.serialize());
                } else {
                    el.find('.js-send-error-comment')[0].innerText = responseObj.errorMsg;
                    el.find('.js-send-error').removeClass('h-hidden');
                }
            }
        });
    }

    _makeAjax(data) {
        $.ajax({
            url: util.appendParamsToUrl(Urls.customerServiceSubmit, {recaptchatoken: $document.find('.js-recaptcha-token input').val()}),
            data: data,
            dataType: 'html',
            method: 'POST'
        }).done(function (data) {
            if (data) {
                $document.find('.js-customer-service-container').html(data);
            }
        });
    }
}
