'use strict';

import Component from '_core_ext/components/Component';
import util from '_core_ext/util';

export default class FlightDetailsCountry extends Component {
    init() {
        super.init();
        // jscs: disable
        let storedCountry = this.$el.attr('data-session') !== 'null' ? this.$el.attr('data-session') : null;
        // jscs: enable
        if ($('.js-fd-country-selector select option:selected').val() === '') {
            if (storedCountry) {
                $('.js-fd-country-selector select').val(storedCountry);
            } else {
                $('.js-fd-save').addClass('disabled');
            }
        }
        $('.js-fd-country-selector select option[value=""]').attr('disabled', true);
        this.event('click', '.js-fd-save', this.save);
        this.event('change', '.js-fd-country-selector', this.change);
    }

    save () {
        let country = $('.js-fd-country-selector select option:selected').val(),
            url = util.appendParamsToUrl(Urls.flightDetailsSaveCountry, {
            country: country,
            format: 'ajax'
        });

        $('.js-fd-save').addClass('disabled');
        $.ajax({
            url: url
        }).done(() => {
            window.location.reload();
        });
    }

    change (e) {
        if ($(e).find('option:selected').val() === '') {
            $('.js-fd-save').addClass('disabled');
        } else {
            $('.js-fd-save').removeClass('disabled');
        }
    }
}
module.exports = FlightDetailsCountry;