import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

var page = require('_core_ext/page'),
    util = require('_core_ext/util'),
    TPromise = require('promise');

var _currentCategory = '',
    replacementData,
    MAX_ACTIVE = 4;

const emitter = eventMgr.getEmitter('comparewidget');

export default class CompareWidget extends Component {
    init() {
        var self = this;

        super.init();
        this.initializeDom();
        this.event('click', '.js-compare-item .js-compare-item-remove', function (element) {
            self.removeItem($(element).closest('.js-compare-item'));
        });
        this.event('click', '.js-compare-items-button', function () {
            page.redirect(util.appendParamToURL(Urls.compareShow, 'category', _currentCategory));
        });
        this.event('click', '.js-clear-compared-items', function () {
            $('.js-compare-items .js-active').each(function () {
                self.removeItem($(this));
            });
        });
        this.event('click', '.js-replace-item', this.replaceItem);
        this.event('click', '.js-cancel-replace-item', this.cancelReplaceItem);
        this.eventMgr('producttile.compareadd', this.addProduct);
        this.eventMgr('producttile.compareremove', this.removeProduct);
        this.eventMgr('dialog.comparereplaceclosed', this.cancelReplaceItem);
    }

    //
    // Event handlers
    //
    refreshContainer() {
        var $compareContainer = $('.js-compare-items');
        var $compareItems = $compareContainer.find('.js-compare-item');
        var numActive = $compareItems.filter('.js-active').length;

        if (numActive < 2) {
            $('.js-compare-items-button').attr('disabled', 'disabled');
        } else {
            $('.js-compare-items-button').removeAttr('disabled');
        }

        $compareContainer.toggle(numActive > 0);
    }

    addToList(data) {
        // get the first compare-item not currently active
        var $item = $('.js-compare-items .js-compare-item').not('.js-active').first(),
            $productTile = $('#' + data.uuid);

        if ($item.length === 0) {
            if ($productTile.length > 0) {
                $productTile.find('.js-compare-check')[0].checked = false;
            }
            return;
        }

        // if already added somehow, return
        if ($('[data-uuid="' + data.uuid + '"]').length > 0) {
            return;
        }
        // set as active item
        $item.addClass('js-active b-compare-select__item--selected')
            .attr('data-uuid', data.uuid)
            .attr('data-itemid', data.itemid)
            .data('uuid', data.uuid)
            .data('itemid', data.itemid)
            .append($(data.img).clone().addClass('js-compare-item-image'));
    }

    removeFromList($item) {
        if ($item.length === 0) { return; }
        // remove class, data and id from item
        $item.removeClass('js-active b-compare-select__item--selected')
            .removeAttr('data-uuid')
            .removeAttr('data-itemid')
            .data('uuid', '')
            .data('itemid', '')
            // remove the image
            .find('.js-compare-item-image').remove();
    }

    addProductAjax(args) {
        var promise = new TPromise(function (resolve, reject) {
            $.ajax({
                url: Urls.compareAdd,
                data: {
                    pid: args.itemid,
                    category: _currentCategory
                },
                dataType: 'json'
            }).done(function (response) {
                if (!response || !response.success) {
                    reject(new Error(Resources.COMPARE_ADD_FAIL));
                } else {
                    resolve(response);
                }
            }).fail(function (jqxhr, status, err) {
                reject(new Error(err));
            });
        });
        return promise;
    }

    removeProductAjax(args) {
        var promise = new TPromise(function (resolve, reject) {
            $.ajax({
                url: Urls.compareRemove,
                data: {
                    pid: args.itemid,
                    category: _currentCategory
                },
                dataType: 'json'
            }).done(function (response) {
                if (!response || !response.success) {
                    reject(new Error(Resources.COMPARE_REMOVE_FAIL));
                } else {
                    resolve(response);
                }
            }).fail(function (jqxhr, status, err) {
                reject(new Error(err));
            });
        });
        return promise;
    }

    shiftImages() {
        var self = this;

        return new TPromise(function (resolve) {
            var $items = $('.js-compare-items .js-compare-item');

            $items.each(function (i, item) {
                var $item = $(item);

                // last item
                if (i === $items.length - 1) {
                    return self.removeFromList($item);
                }
                var $next = $items.eq(i + 1);

                if ($next.hasClass('js-active')) {
                    // remove its own image
                    $next.find('.js-compare-item-image').detach().appendTo($item);
                    $item.addClass('js-active b-compare-select__item--selected')
                        .attr('data-uuid', $next.data('uuid'))
                        .attr('data-itemid', $next.data('itemid'))
                        .data('uuid', $next.data('uuid'))
                        .data('itemid', $next.data('itemid'));
                }
            });
            resolve();
        });
    }

    addProduct(args) {
        var promise;
        var $items = $('.js-compare-items .js-compare-item');
        var $cb = $(args.cb);
        var numActive = $items.filter('.js-active').length;
        var self = this;

        if (numActive === MAX_ACTIVE) {
            replacementData = args;
            this.$el.find('.js-replace-confirm-dialog').trigger('click');
            return;
        } else {
            promise = TPromise.resolve(0);
        }
        return promise.then(function () {
            return self.addProductAjax(args).then(function () {
                self.addToList(args);
                if ($cb && $cb.length > 0) { $cb[0].checked = true; }
                self.refreshContainer();
            });
        }).then(null, function () {
            if ($cb && $cb.length > 0) { $cb[0].checked = false; }
        });
    }

    cancelReplaceItem() {
        var $cb = $(replacementData.cb);

        $cb[0].checked = false;
        emitter.emit('closedialog');
    }

    replaceItem() {
        var $items = $('.js-compare-items .js-compare-item');
        var $cb = $(replacementData.cb);
        var self = this;
        var $firstItem = $items.first();
        var promise = self.removeItem($firstItem).then(function () {
            return self.shiftImages();
        });

        return promise.then(function () {
            return self.addProductAjax(replacementData).then(function () {
                self.addToList(replacementData);
                if ($cb && $cb.length > 0) { $cb[0].checked = true; }
                self.refreshContainer();
                emitter.emit('closedialog');
            });
        }).then(null, function () {
            if ($cb && $cb.length > 0) { $cb[0].checked = false; }
        });
    }

    removeProduct(args) {
        var self = this;
        var $cb = args.cb ? $(args.cb) : null;

        return self.removeProductAjax(args).then(function () {
            var $item = $('[data-uuid="' + args.uuid + '"]');

            self.removeFromList($item);
            if ($cb && $cb.length > 0) { $cb[0].checked = false; }
            self.refreshContainer();
        }, function () {
            if ($cb && $cb.length > 0) { $cb[0].checked = true; }
        });
    }

    removeItem($item) {
        var uuid = $item.data('uuid'),
            $productTile = $('#' + uuid).find('.js-compare-check').length > 0 ? $('#' + uuid).find('.js-compare-check') : $('#' + uuid);

        return this.removeProduct({
            itemid: $item.data('itemid'),
            uuid: uuid,
            cb: ($productTile.length === 0) ? null : $productTile
        });
    }

    initializeDom() {
        if (this.$el.find('.js-replace-confirm-dialog-content').length === 0) {
            var $compareContainer = $('.js-compare-items');
            var $active = $compareContainer.find('.js-compare-item').filter('.js-active');

            _currentCategory = $compareContainer.data('category') || '';
            $active.each(function () {
                var $productTile = $('#' +  $(this).data('uuid'));

                if ($productTile.length === 0) {return;}
                $productTile.find('.js-compare-check')[0].checked = true;
            });
            // set container state
            this.refreshContainer();
        }
    }

}
