import Component from '_core_ext/components/Component';

export default class LanguageSwitcherLink extends Component {
    init () {
        super.init();
        this.event('click', this.onClick);
    }

    onClick(params, event) {
        event.preventDefault();
        const urls = $('[data-locale-urls]').data('localeUrls');
        const locale = this.$el.data('locale');
        let url = urls && urls[locale];

        if (url) {
            window.location = url;
        } else {
            window.location = this.$el.attr('href');
        }
    }
}
