const customUtils = require('./customUtils');

module.exports = {
    customPV: function(analyticsData) {
        if (!window.pageContext.PV) {return;}

        const eventObj = {
            event: 'PV',
            page: window.pageContext.PV
        };

        customUtils.attachPdpData(eventObj, analyticsData);
        customUtils.attachPlpData(eventObj, analyticsData);
        customUtils.attachPromoView(eventObj, analyticsData); // TODO: implement PV for promoView
        customUtils.attachCheckoutData(eventObj, analyticsData);
        customUtils.attachPurchasesData(eventObj, analyticsData);

        window.dataLayer.push(eventObj);
    },
    customVPV: function(analyticsData, params, impressionProduct) {
        const eventObj = {
            event: 'VPV',
            page: {
                path: customUtils.getVpvPath(params.url)
            }
        };

        if (impressionProduct) {
            impressionProduct.list = 'recommendation';
            eventObj.ecommerce = {
                currencyCode: analyticsData.page ? analyticsData.page.currencyCode : '',
                impressions: [impressionProduct]
            };
        }

        window.dataLayer.push(eventObj);
    },
    userAction: function(analyticsData) {
        $(document).on('click', 'a', function(event) {
            if (!window.pageContext.PV) {return;}

            const eventObj = customUtils.getUserActionEvent(event, analyticsData);

            if (!eventObj) {return;}

            window.dataLayer.push(eventObj);
        });
    },
    addToCart: function(analyticsData, params) {
        const productData = customUtils.getProductData(analyticsData, params);
        const eventObj = {
            event: 'userAction',
            click: {
                category: 'product',
                action: 'add',
                label: productData.name || 'Product Details'
            },
            ecommerce: {
                currencyCode: analyticsData.page ? analyticsData.page.currencyCode : '',
                add:{
                     products: [productData]
                 }
            }
        };
        window.dataLayer.push(eventObj);
    },
    incrementProduct: function(analyticsData, productData) {
        productData.quantity = 1;

        const eventObj = {
            event: 'userAction',
            click: {
                category: 'product',
                action: 'add',
                label: productData.name || 'Product Details'
            },
            ecommerce: {
                currencyCode: analyticsData.page ? analyticsData.page.currencyCode : '',
                add: {
                     products: [productData]
                 }
            }
        };

        window.dataLayer.push(eventObj);
    },
    removeFromCart: function(analyticsData, params) {
        const productData = params.$el.data('productdetails');
        productData.quantity = params.$el.data('quantity');

        const eventObj = {
            event: 'userAction',
            click: {
                category: 'product',
                action: 'remove',
                label: productData.name
            },
            ecommerce: {
                currencyCode: analyticsData.page ? analyticsData.page.currencyCode : '',
                remove: {
                     products: [productData]
                }
            }
        };

        window.dataLayer.push(eventObj);
    },
    decrementProduct: function(analyticsData, productData) {
        productData.quantity = 1;

        const eventObj = {
            event: 'userAction',
            click: {
                category: 'product',
                action: 'remove',
                label: productData.name
            },
            ecommerce: {
                currencyCode: analyticsData.page ? analyticsData.page.currencyCode : '',
                remove: {
                    products: [productData]
                 }
            }
        };
        window.dataLayer.push(eventObj);
    },
    promotionClick(/*analyticsData, params*/) {
        // TODO: implement promoClick here
    }
};
