'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('ordercancel');

export default class ConfirmationCancellation extends Component {
    init() {
        super.init();

        this.event('click touchstart', '.js-confirm', this.onConfirm);
        this.event('click touchstart', '.js-cancel', this.onCancel);
    }

    onConfirm(el) {
        const orderId = $(el).data('orderid');

        $.ajax({
            url: Urls.cancelOrder,
            type: 'POST',
            data: {
                orderNo: orderId
            }
        }).done(function (data) {
            if (typeof data === 'string') {
                $document.find('.js-order-status').replaceWith($(data));
                $document.find('.js-cancelorder').remove();
            } else {
                $document.find('.js-order-cancel-error').removeClass('h-hidden');
            }
            emitter.emit('closedialog');
        });
    }

    onCancel() {
        if (typeof this.$el.parent().dialog('instance') === 'object') {
            this.$el.parent().dialog('close');
        }
    }
}
