import InputGeneric from './InputGeneric';

export default class InputCheckbox extends InputGeneric {
    getValue () {
        if (this.$field.prop('checked')) {
            return super.getValue();
        } else {
            return '';
        }
    }
    check () {
        this.$field.prop('checked', true);
        this.emitter.emit('change', this);
    }
    uncheck () {
        this.$field.prop('checked', false);
        this.emitter.emit('change', this);
    }
}
