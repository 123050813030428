'use strict';

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';

export default class OrderHistoryItemsToggler extends Component {
    init() {
        super.init();

        this.event('click', '.js-order-history-toggler', this.onClick);
        this.event('click', '.js-recent-order-showmore', this.showMore);
        this.event('click', '.js-recent-order-showfewer', this.showFewer);
    }

    onClick() {
        this.$el.find('.js-order-history-items, .js-order-history-toggler')
            .toggleClass(Constants.GLOBAL.hiddenClass);
    }

    showMore() {
        this.$el.find('.js-recent-order-showmore, .js-recent-order-showfewer')
            .toggleClass(Constants.GLOBAL.hiddenClass);
        this.$el.find('.js-order-history-item')
            .removeClass(Constants.GLOBAL.hiddenClass);
    }

    showFewer() {
        this.$el.find('.js-recent-order-showmore, .js-recent-order-showfewer')
            .toggleClass(Constants.GLOBAL.hiddenClass);
        this.$el.find('.js-order-history-item').each(function (i) {
            if (i > 2) {
                $(this).addClass(Constants.GLOBAL.hiddenClass);
            }
        });
    }
}
