import Component from '_core_ext/components/Component';

export default class Comparison extends Component {
    init() {
        super.init();
        this.event('change', '.js-category-select', function (element) {
            $(element).closest('form').submit();
        });
        this.event('click', '.js-remove-link', this.removeProduct);
    }

    //
    // Event handlers
    //
    removeProduct(element, event) {
        event.preventDefault();
        var productsSize = this.$el.find('#compare-table').data('products-size');

        $.ajax({
            dataType: 'json',
            url: element.href
            }).done(function () {
                if (productsSize < 2) {
                    $('.js-category-select option:not(:selected)').first().attr('selected', 'selected');
                }
                $('.js-category-select').closest('form').submit();
            });
    }
}
