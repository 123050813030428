import Component from '_core_ext/components/Component';

export default class CheckEmailAccount extends Component {
    init () {
        super.init();
        this.$email = $('.js-email-create-account');
        this.$button = $('.js-button-create-account');
        this.url = this.$el.attr('data-url');
        this.label = this.$el.attr('data-label') || '';
        this.event('click', '.js-button-create-account', this.check);
    }

    check (element, event) {
        event.preventDefault();
        
        // email input field component
        let $component = this.$email.parent().parent();
        // check all inputs validate erors
        if ($('fieldset').find('.f-error').length > 0) {
            return;
        }
        // disable button for multi-click
        this.$button.addClass('disable');

        $.ajax({
            url: this.url,
            data: {
                email: this.$email.val().trim()
            }
        }).done(data => {
            if (data && data.success) {
                $("form#RegistrationForm").submit();
            } else {
                this.$button.removeClass('disable');
                $component.append(`<div class="f-error "><span class="f-error-block"><span class="f-error-text">${this.label}</span></span></div>`);
            }
        });
    }
}
