'use strict';

import Component from '_core_ext/components/Component';

export default class Recaptcha extends Component {
    init() {
        super.init();
        this.action = this.$el.data('action');
        this.execute();
        this.eventMgr('cart.updaterecaptcha', this.execute);
        this.eventMgr('billingform.updaterecaptcha', this.execute);
        this.eventMgr('checkoutgiftvoucher.updaterecaptcha', this.execute);
        this.eventMgr('registrationaccount.updaterecaptcha', this.execute);
        this.eventMgr('dialog.updaterecaptcha', this.execute);
    }

    execute() {
        let self = this;

        grecaptcha.ready(function () {
              grecaptcha.execute(SitePreferences.RECAPTCHA_SITE_KEY, {
                action: self.action
              }).then(function (token) {
                 $('.js-recaptcha-token input').val(token);
              });
          });
    }
}
