'use strict';

import Component from '_core_ext/components/Component';
import {Progress} from '_core_ext/progress';
import eventMgr from '_core_ext/eventMgr';

let emitter = eventMgr.getEmitter('productactions');

export default class ProductActions extends Component {
    init() {
        super.init();
        this.loader = new Progress(this.$el);
        this.event('click', '.js-product-detail .swatchanchor', this.updateContent);
        this.event('change', '.js-age-checkbox', this.hideAgeWarning);
        this.eventMgr('flightdetails.reloadpage', this.reload);
    }

    //
    // Event handlers.
    //
    reload() {
        location.reload();
    }

    hideAgeWarning(element) {
        if ($(element).prop('checked')) {
            this.$el.find('.js-age-warn').text('');
        }
    }

    updateContent(element, event) {
        event.preventDefault();
        var href = $(element).attr('href'),
            self = this;

        href = href + '&format=ajax';

        this.loader.show();

        $.ajax({
            url: href,
            type: 'GET'
        }).done(function (data) {
            var $newContent = $('.js-product-content');
            let productNameElement = document.getElementsByClassName('b-product-info__product-name')[0];

            $newContent.html(data);
            emitter.emit('variantchanged', $newContent);

            productNameElement.innerHTML = self.decodeHtml(productNameElement.innerHTML);
            self.loader.hide();
        }).fail(() => {
            self.loader.hide();
        });
    }

    getEmitter () {
        return emitter;
    }

    //Decodes the encoded HTML tags in product name on PDP, if any.
    decodeHtml(html) {
        let dummyHTML = document.createElement('textarea');

        dummyHTML.innerHTML = html;
        return dummyHTML.value;
    }

}
