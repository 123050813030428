import FormGeneric from '_core_ext/components/forms/FormGeneric';
import {appendParamsToUrl} from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';
import util from '_core_ext/util';

const giftVoucherEmitter = eventMgr.getEmitter('giftvoucher');

export default class GiftCertificateForm extends FormGeneric {
    init() {
        const self = this;

        super.init();
        this.dialog = this.items[this.items.length - 1];
        this.updateForm();
        this.setAmount();
        this.event('change', '.js-isgift-radio', this.updateForm);
        this.event('change', '.js-predefined-amount', this.setAmount);
        this.event('change', '.js-specified-amount', this.validateAmount);
        this.event('input', '.js-gift-message', this.setRemainingMessage);
        this.eventMgr('addtocart.submitGiftCertificateForm', () => self.$el.trigger('submit'));
        this.event('submit', this.onSubmitForm);
    }

    validateAmount() {
        if (HTMLFormElement.prototype.reportValidity) {
            this.$el.find('.js-specified-amount').find('input').first()[0].reportValidity();
        } else {
            let inputAmount = this.$el.find('.js-specified-amount').find('input').val();
            if (!/^(\d+|\d+\.0)$/.test(inputAmount)) {
                this.$el.find('.js-specified-amount').find('input').first()[0].invalidate();
            }
        }

        // Validate only number filed in form.
        this.items.forEach(function(item){
            if (item.cmpName === 'inputNumber') {
                item.validate();
            }
        });
    }

    setRemainingMessage() {
        const m = this.$el.find('.js-gift-message');
        let len = m.attr('maxlength') - m.val().toString().length;
        let mess = len + ' ' + (len === 1 ? Resources.GIFTCERT_PURCHASEMESSAGEDESCRIPTION_SINGULAR :
            Resources.GIFTCERT_PURCHASEMESSAGEDESCRIPTION_PLURAL);

        m.siblings('.form__description').html(mess);
    }

    updateForm() {
        const isGift = this.$el.find('.js-isgift-radio:checked').val();

        if (isGift === 'true') {
            this.$el.find('.js-gift-fields').removeClass('h-hidden').addClass('js-fields-required');
            this.$el.find('.js-not-gift-fields').addClass('h-hidden').removeClass('js-fields-required');
        } else {
            this.$el.find('.js-gift-fields').addClass('h-hidden').removeClass('js-fields-required');
            this.$el.find('.js-not-gift-fields').removeClass('h-hidden').addClass('js-fields-required');
        }

        this.eachChild(itemCmp => {
            if (itemCmp.cmpName === 'inputTextGV') {
                let isRequired = itemCmp.$el.closest('.js-fields-wrapper').hasClass('js-fields-required');

                itemCmp.setRequired(isRequired);
            }
        });
    }

    setAmount() {
        let selectedPredefinedValue = this.$el.find('.js-predefined-amount').find(':checked').val();

        this.$el.find('.js-specified-amount').find('input').val(selectedPredefinedValue);
        this.validateAmount();
    }

    onSubmitForm(el, e) {
        const isFormValid = this.isValid();

        if (!isFormValid) {
            return false;
        }

        e.preventDefault();

        let $form = this.$el;
        let url = appendParamsToUrl($form.attr('action'), {
            format: 'ajax'
        });
        let self = this;
        let amount = this.$el.find('.js-specified-amount').find('input').val();

        $.ajax({
            url: url,
            method: 'POST',
            cache: false,
            data: $form.serialize()
        }).done(function (res) {
            if (typeof res === 'object') {
                // show error
            } else {
                giftVoucherEmitter.emit('updateminicart', res);
                url = util.appendParamsToUrl(Urls.showAddProductConfirmation, {
                    amount: amount
                });

                self.dialog.open({
                    options: {
                        customClass: 'b-popup b-popup--add-to-cart'
                    },
                    url: url
                });
            }
        }).fail(function () {
            // failed
        });

        return false;
    }
}