'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('searchresultitems');

export default class SearchResultItems extends Component {
    init() {
        super.init();
        this.eventMgr('infinitescroll.updatesearchitems', this.updateSearchResultItems);
    }

    updateSearchResultItems(html) {
        if (!html) {
            return;
        }

        this.$el.append(html);
        emitter.emit('updated');
    }
}
