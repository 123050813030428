const libs = require('./libs');
const customEvents = require('./customEvents');
const caTrack = !!window.pageContext.PV;

module.exports = {
    productImpression: function (analyticsData) {
        if (!caTrack) {
            libs.productImpression(analyticsData);
        }
    },
    productDetail: function (analyticsData) {
        if (!caTrack) {
            libs.productDetail(analyticsData);
        }
    },
    promotionImpression: function () {
        if (!caTrack) {
            libs.promotionImpression();
        }
    },
    checkout: function (analyticsData, currentPage) {
        if (!caTrack) {
            libs.checkout(analyticsData, currentPage);
        }
    },
    purchase: function (analyticsData) {
        if (!caTrack) {
            libs.purchase(analyticsData);
        }
    },
    nonEcommerce: function (analyticsData) {
        if (caTrack) {
            customEvents.customPV(analyticsData);
            customEvents.userAction(analyticsData);
        } else {
            libs.noEcommerceData(analyticsData);
        }
    }
};
