'use strict';

import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('addtocartconfirmation');

export default class AddToCartConfirmation extends Component {
    init() {
        super.init();
        this.dialog = this.items[0];
        this.event('click', '.js-continue-shopping', this.continueShopping);
        this.eventMgr('wishlistbeforeadd.productremoved', this.showConfirmation);
        this.eventMgr('wishlistbeforeadd.productkeeped', this.showConfirmation);
    }

    //
    // Event handlers.
    //
    continueShopping() {
        emitter.emit('closedialog');
    }

    showConfirmation(pid) {
        this.dialog.open({
            options: {
                customClass: 'b-popup b-popup--add-to-cart'
            },
            url: util.appendParamsToUrl(Urls.showAddProductConfirmation, {
                pid: pid
            })
        });
    }
}