import InputText from './InputText';

export default class InputPassword extends InputText {
    init() {
        super.init();

        this.eventMgr('dialog.open', this.blockField);
        this.eventMgr('dialog.close', this.unblockField);
        this.eventMgr('checkoutlogin.togglerequired', this.toggleRequired);
        this.event('click', '.js-show-password', this.showPassword);
        this.event('click', '.js-hide-password', this.hidePassword);
    }
    blockField () {
        this.$field.attr('readonly', 'readonly');
    }
    unblockField () {
        this.$field.removeAttr('readonly');
    }
    toggleRequired (isRequired) {
        this.config.required = isRequired;

        if (this.config.required) {
            this.$el.addClass('f-state-required');
            return;
        }

        this.$el.removeClass('f-state-required');
    }
    showPassword() {
        this.$el.find('input').attr('type', 'text');
        this.$el.find('.js-hide-password').removeClass('h-hidden');
        this.$el.find('.js-show-password').addClass('h-hidden');
    }
    hidePassword() {
        this.$el.find('input').attr('type', 'password');
        this.$el.find('.js-show-password').removeClass('h-hidden');
        this.$el.find('.js-hide-password').addClass('h-hidden');
    }
}
