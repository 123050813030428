'use strict';

import Component from '_core_ext/components/Component';
import {refresh as pageRefresh} from '_core_ext/page';
import {appendParamsToUrl} from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('checkoutgiftvoucher');

export default class GiftVoucher extends Component {
    init() {
        super.init();
        this.fillValues();
        this.event('click touchstart', '.js-apply-giftcert', this.onApply);
        this.event('click touchstart', '.js-check-giftcert', this.onCheck);
        this.event('click touchstart', '.js-remove-applied-voucher', this.onRemove);
        this.eventMgr('inputgeneric.hidevalidationerror', this.showValidationError);
    }

    fillValues() {
        if (sessionStorage.getItem('isSavedBillingForm')){
            let formValues = JSON.parse(sessionStorage.getItem('savedBillingForm'));
            $('.js-input_field').each(function(indx, el) {
                if ($(el).hasClass('form__radio')) { return; }

                if ($(el).attr('type') === 'checkbox') {
                    $(el).prop('checked', formValues[indx]);
                } else {
                    $(el).val(formValues[indx]);
                }
            });
            sessionStorage.removeItem('isSavedBillingForm');
            sessionStorage.removeItem('savedBillingForm');
        }
    }

    _updateInfo(params) {
        this.$el.find('.js-balance-info').html('');
        this.$el.find('.js-voucher-error').html(window.Resources.INVALID_VOUCHER.replace('{0}', params.voucherCode));
    }

    _getParams() {
        return {
            voucherCode: this.$el.find('.js-giftcert-code').val(),
            recaptchaToken: $document.find('.js-recaptcha-token input').val()
        };
    }

    _isValid() {
        let code = this.$el.find('.js-giftcert-code').val();

        if (~code.search(/^\d{8}$/)) {
            return true;
        }

        return false;
    }

    showValidationError(data) {
        if (data.eventEl.find('.js-giftcert-code').length > 0) {
            this.$el.find('.js-voucher-error').html(data.error);
        }
    }

    onApply(el, e) {
        e.preventDefault();

        let params = this._getParams(),
            self = this;

        if (!params.voucherCode || !this._isValid()) {
            self._updateInfo(params);
            return;
        }

        $.ajax({
            url: appendParamsToUrl(Urls.giftVoucherRedemption, params)
        }).done(function (response) {
            if (typeof response === 'object' && response.ok) {
                self.$el.find('.js-vouchers-wrapper').find(`.js-applied-voucher[data-code="${params.voucherCode}"]`).remove();
                self.$el.find('.js-vouchers-wrapper').append($(response.message));
                self.$el.find('.js-giftcert-code').val('');
                self.$el.find('.js-voucher-error').html('');

                if (response.isFullyCovered) {
                    emitter.emit('ordercovered');
                }

                emitter.emit('reloadordersummary');
            } else if (typeof response === 'object' && !response.ok) {
                self.$el.find('.js-voucher-error').html(response.message);
            }
            emitter.emit('updaterecaptcha');
        });
    }

    onCheck(el, e) {
        e.preventDefault();

        let params = this._getParams(),
            self = this;

        if (!params.voucherCode || !this._isValid()) {
            self._updateInfo(params);

            return;
        }

        $.ajax({
            url: appendParamsToUrl(Urls.giftVoucherBalance, params)
        }).done(function (response) {
            if (typeof response === 'object') {
                if (response.ok) {
                    self.$el.find('.js-balance-info').html(response.message);
                } else {
                    self.$el.find('.js-voucher-error').html(response.message);
                }
            }
            emitter.emit('updaterecaptcha');
        });
    }

    onRemove(el) {
        const code = $(el).closest('.js-applied-voucher').data('code');

        $.ajax({
            url: appendParamsToUrl(Urls.giftVoucherRemoval, {
                voucherCode: code
            })
        }).done(function (response) {
            if (typeof response === 'object') {
                //save values before page refreshing
                let formValues = [];
                $('.js-input_field').each(function(indx, el) {
                    //exclude hidden radios to set payment methods by default
                    if ($(el).hasClass('form__radio')) { return; }

                    if ($(el).attr('type') === 'checkbox') {
                        formValues[indx] = $(el).prop('checked');
                    } else {
                        formValues[indx] = $(el).val();
                    }
                });
                sessionStorage.setItem('isSavedBillingForm', 'true');
                sessionStorage.setItem('savedBillingForm', JSON.stringify(formValues));
                pageRefresh();
            }
        });
    }
}
