import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';
import {appendParamsToUrl} from '_core_ext/util';
import {refresh as pageRefresh} from '_core_ext/page';

const emitter = eventMgr.getEmitter('bonusproductmodal');

export default class BonusProductModal extends Component {
    init() {
        let $bonusProductList = this.$el.find('.js-bonus-product-list');
        const bliData = JSON.parse($bonusProductList.attr('data-line-item-detail'));

        super.init();

        this.config = bliData;
        this.maxAllowedItems = Number(bliData.maxItems) - Number(bliData.itemCount);

        this.selectedList = JSON.parse($bonusProductList.attr('data-selected-items'));
        this.bliUUID = bliData.uuid;

        this.checkAddToCartButtonState();

        this.event('click', '.js-select-bonus-button', this.selectItem);
        this.event('click', '.js-add-to-cart-bonus', this.addToCart);
        this.event('click', '.js-remove-bonus-product', this.removeBonusProduct);
        this.event('click', '.js-swatchanchor', this.updateVariation);
    }

    checkAddToCartButtonState() {
        this.$el.find('.js-selected-items-count').text(this.selectedList.length);

        if (this.selectedList.length) {
            this.$el.find('.js-add-to-cart-bonus').removeAttr('disabled');
            this.$el.find('.js-bonus-error').addClass('h-hidden');
        } else {
            this.$el.find('.js-add-to-cart-bonus').attr('disabled', 'disabled');
            this.$el.find('.js-bonus-error').removeClass('h-hidden');
        }
    }

    removeBonusProduct(el) {
        const $item = $(el).closest('.js-selected-bonus-item');
        const uuid = $item.data('uuid');

        for (let i = 0, len = this.selectedList.length; i < len; i++) {
            let item = this.selectedList[i];

            if (uuid === item.uuid) {
                this.maxAllowedItems += Number(item.qty);
                this.selectedList.splice(i, 1);
                $item.remove();

                this._updateHeaderState(this.$el.find('.js-selected-bonus-list'), this.selectedList.length);

                break;
            }
        }

        this.checkAddToCartButtonState();
    }

    /**
     * Choose variation if we have a non-variant as
     * bonus product
     * @param {object} el target element
     * @param {object} e event object
     */
    updateVariation(el, e) {
        e.preventDefault();

        let href = $(el).attr('href');
        let params = {
            maxItems: Number(this.maxAllowedItems),
            format: 'ajax',
            source: 'bonus'
        };

        $.ajax({
            url: appendParamsToUrl(href, params),
            type: 'GET'
        }).done(function (data) {
            let $bonusItem = $(el).parents('.js-bonus-product-item');

            if ($bonusItem && data) {
                $bonusItem.html(data);
            }
        });
    }

    selectItem(el, e) {
        e.preventDefault();

        const $btn = $(el);
        const $form = $btn.closest('.js-bonus-product-form');
        const $detail = $btn.closest('.js-product-detail');
        const quantity = $detail.find('.js-item-quantity-field').val();

        if ((this.selectedList.length + Number(quantity)) > this.maxAllowedItems) {
            // ToDo: show error ???
            return;
        }

        let product = {
            uuid: $form.find('.js-productUUID').val(),
            pid: $form.find('.js-pid').val(),
            name: $detail.find('.js-product-name').text(),
            qty: quantity,
            options: []
        };
        let self = this;

        $.ajax({
            url: appendParamsToUrl(Urls.selectedBonusProduct, product),
            type: 'GET'
        }).done(function (item) {
            if (item) {
                let $list = self.$el.find('.js-selected-bonus-list');

                $list.append($(item));

                self._updateHeaderState($list, product.qty);

                self.selectedList.push(product);
                self.checkAddToCartButtonState();
            }
        });
    }

    _updateHeaderState($list, qty) {
        let $selecterProduct = $list.parents('.js-selected-bonus-product');

        if (qty > 0) {
            $selecterProduct.removeClass('b-selected-bonus-product--empty');
        } else {
            $selecterProduct.addClass('b-selected-bonus-product--empty');
        }
    }

    addToCart(el, e) {
        const url = appendParamsToUrl(Urls.addBonusProduct, {
            bonusDiscountLineItemUUID: this.bliUUID
        });

        const bonusProducts = this.getBonusProducts();

        e.preventDefault();

        // make the server call
        $.ajax({
            type: 'POST',
            dataType: 'json',
            cache: false,
            contentType: 'application/json',
            url: url,
            data: JSON.stringify(bonusProducts)
        }).done(function () {
            pageRefresh();
        }).fail(function () {
        }).always(function () {
            emitter.emit('closedialog');
        });
    }

    getBonusProducts() {
        var bonusproducts = [];
        var i, len;

        for (i = 0, len = this.selectedList.length; i < len; i++) {
            var p = {
                pid: this.selectedList[i].pid,
                qty: this.selectedList[i].qty,
                options: {}
            };
            var a, alen, bp = this.selectedList[i];

            if (bp.options) {
                for (a = 0, alen = bp.options.length; a < alen; a++) {
                    var opt = bp.options[a];

                    p.options = {
                        optionName: opt.name,
                        optionValue: opt.value
                    };
                }
            }

            bonusproducts.push({
                product: p
            });
        }

        return {
            bonusproducts: bonusproducts
        };
    }
}

module.exports = BonusProductModal;
