import Form from './Form';
import screenDetector from '_core_ext/screendetector';
import eventMgr from '_core_ext/eventMgr';

import {appendSearchParamsToURL} from '_core_ext/util';

const filterFormEmitter = eventMgr.getEmitter('filterform');
const searchPaginationEmitter = eventMgr.getEmitter('searchpaginationdiv');

export default class FilterForm extends Form {
    init() {
        super.init();

        this.event('change', 'input[type="checkbox"]', this.onChange);
        this.event('reset', this.onReset);

        this.eventMgr('clearrefinement.clear', this.onClear);
        this.eventMgr('brandrefinement.submit', this.onSubmitBrandRefinement);

        this.defaultExpandRefinement();
    }

    //Default expand the first refinement type if none selected
    defaultExpandRefinement() {
        var $allRefinementTypes = $('.b-refinement__section.refinement');

        if ($allRefinementTypes.find('input:checked').length === 0) {
            $allRefinementTypes.first().trigger('click');
        }
    }

    onClear(element, event) {
        $(event.target)
            .parent()
            .siblings()
            .find('input[type="checkbox"]')
            .attr('checked', false);

        this._makeAjax(this._getFormData());
    }

    // Submit fires on mobile view.
    onSubmit(element, event) {
        event.preventDefault();
        this._makeAjax(this._getFormData());
    }

    onSubmitBrandRefinement() {
        if (this._isDesktop()) {
            this.onChange();
        } else {
            this._makeAjax(this._getFormData());
        }
    }

    onChange(element) {
        let $element = $(element);

        // Keep only one selected for price refinement
        // with possibility to unselect
        if ($element.prop('name') === 'price') {
            this.$el
                .find('input[name="price"]')
                .not($element)
                .prop('checked', false);
        }

        // Make ajax calls only on desktop. For mobile and tables we have "Apply filters"
        // button, which will submit form.
        if (this._isDesktop()) {
            this._makeAjax(this._getFormData());
        }
    }

    onReset() {
        let serializedFormData = this.$el.serializeArray();
        let self = this;

        // Pass only cgid, to clear filter results.
        serializedFormData.some(function (e) {
            if (e.name === 'cgid' || e.name === 'q') {
                self._makeAjax([e]);
                return true;
            }
            return false;
        });
    }

    //
    // Internally used functions.
    //

    _getFormData() {
        return this.$el.find('input').not('.js-skip-for-submit').serializeArray();
    }

    /**
     * @param {[]} params Array with objects.
     */
    _makeAjax(params) {
        if (!params || !(params instanceof Array)) {
            return;
        }

        $.ajax({
            url: appendSearchParamsToURL(Urls.searchShow, params, true)
        }).done(function (data) {
            if ('pageUrl' in window.pageContext) {
                // Remove cgid from params to not show it in URL (for SEO)
                params.map(function (e, idx) {
                    if (e.name === 'cgid') {
                        params.splice(idx, 1);
                    }
                });
                history.pushState({}, '', appendSearchParamsToURL(window.pageContext.pageUrl, params, false));
            }
            filterFormEmitter.emit('updatesearchresult', data);
            searchPaginationEmitter.emit('move');
        });
    }

    /**
     * Dynamically check device type.
     */
    _isDesktop() {
        return screenDetector.getCurrentDeviceType() === screenDetector.DESKTOP;
    }
}
