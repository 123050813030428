'use strict';

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';

export default class CookiesHint extends Component {
    init() {
        super.init();
        this.loadCookiesHint();
        this.event('click', '.js-accept-cookies', this.acceptAsset);
        this.event('click', '.js-close-cookies', this.closeAsset);
    }
    //
    // Event handlers.
    //

    hideAsset() {
        this.$el.addClass(Constants.GLOBAL.hiddenClass);
    }

    showAsset() {
        this.$el.removeClass(Constants.GLOBAL.hiddenClass);
    }

    closeAsset() {
        this.hideAsset();
        window.sessionStorage.setItem('dw_cookies_closed', true);
    }

    acceptAsset() {
        this.hideAsset();
        this._enableCookies();
    }

    loadCookiesHint() {

        let cookieAccepted = ~document.cookie.indexOf('dw_cookies_accepted');
        let sessionClosed = window.sessionStorage.getItem('dw_cookies_closed');

        if (sessionClosed || cookieAccepted) {
            return;
        }

        // If cookie policy accepted.
        this.showAsset();
        return;
    }

    _enableCookies() {
        if (document.cookie.indexOf('dw=1') < 0) {
            document.cookie = 'dw=1; path=/';
        }
        if (document.cookie.indexOf('dw_cookies_accepted') < 0) {
            let expireDate = new Date();

            expireDate.setFullYear(expireDate.getFullYear() + 1);
            document.cookie = 'dw_cookies_accepted=1; path=/; expires=' + expireDate.toUTCString();
        }
    }
}

module.exports = CookiesHint;
