import Component from '_core_ext/components/Component';

export default class CheckoutOrderSummary extends Component {
    init() {
        super.init();
        this.eventMgr('billingform.couponadded', this.reloadOrderSummary);
        this.eventMgr('checkoutgiftvoucher.reloadordersummary', this.reloadOrderSummary);
    }

    reloadOrderSummary(html) {
        const url = Urls.updateSummary,
            self = this;

        if (html) {
            let $totals = $(html).find('.js-summary-order-totals');

            if ($totals.length) {
                self.$el.replaceWith($totals);
            }
        } else {
            $.ajax({
                url: url
            }).done(function (html) {
                if (html) {
                    let $totals = $(html).find('.js-summary-order-totals');

                    if ($totals.length) {
                        self.$el.replaceWith($totals);
                    }
                }
            });
        }

    }
}
