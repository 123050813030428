'use strict';

/* Component config example: data-json-config
 * {
 *    "url": "DIALOG_TEMPLATE_URL",
 *    "options": {
 *        "title": "DIALOG_TITLE",
 *        "closeText": "CLOSE_BTN_TEXT"
 *    },
 *    "callback": "EVENT_NAME_WHICH_IS_EMITTED_AFTER_DIALOG_OPEN",
 *    "closecallback": "EVENT_NAME_WHICH_IS_EMITTED_AFTER_DIALOG_CLOSED"
 * }
*/


import Component from '_core_ext/components/Component';
import {redirect as pageRedirect} from '_core_ext/page';
import {appendParamsToUrl, scrollBrowser} from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';
import {assign, extend} from 'lodash';

const emitter = eventMgr.getEmitter('dialog');
const Constants = require('_core_ext/components/Constants');

const defaultConfig = {
    autoOpen: false,
    closeText: 'Close',
    closeOnEscape: true,
    draggable: false,
    modal: true,
    position: {
        my: 'center',
        at: 'center',
        of: window,
        collision: 'flipfit'
    },
    resizable: false,
    title: '',
    width: 'auto',
    beforeClose: function () {
        emitter.emit('beforeclose');
    },
    close: function () {
        emitter.emit('close');
    },
    open: function (e) {
        $(e.target).find('.js-main-content').scrollTop(0);

        /* Preventing default behavior of close button for the right class assignment */
        $('.ui-dialog-titlebar-close').off('click').on('click', function () {
            emitter.emit('close');
        });

        $(this).find('a, select, input, textarea, button').first().blur();
    }
};

export default class Dialog extends Component {
    init() {
        super.init();

        this.config.urlParams = {
            format: 'ajax'
        };

        this.event('click', this.open);
        this.event('click', this.closeDialogIfClickOutside, $document);

        this.eventMgr('resetpasswordmodal.replacedialogcontent', this.replace);
        this.eventMgr('billingform.refreshdata', this.refreshDataConfig);
        this.eventMgr('dialog.close', this.close);
        this.eventMgr('headertabs.closedialog', this.close);
        this.eventMgr('flightdetails.closedialog', this.close);
        this.eventMgr('flightdetailscountry.closedialog', this.close);
        this.eventMgr('productactions.closedialog', this.close);
        this.eventMgr('deleteaccount.closedialog', this.close);
        this.eventMgr('deleteaddress.closedialog', this.close);
        this.eventMgr('createeditaddress.closedialog', this.close);
        this.eventMgr('addtocartconfirmation.closedialog', this.close);
        this.eventMgr('ordercancel.closedialog', this.close);
        this.eventMgr('notifyme.closedialog', this.close);
        this.eventMgr('addtocart.closedialog', this.close);
        this.eventMgr('comparewidget.closedialog', this.close);
        this.eventMgr('checkoutcalendar.close', this.close);
        this.eventMgr('flightdetails.reloadcontent', function (config) {
            this.replace(config);
        });
        this.eventMgr('deletecreditcard.closedialog', this.close);
    }
    closeDialogIfClickOutside(element, event) {
        if (event.target.classList.contains('ui-widget-overlay')) {
            emitter.emit('close');
        }
    }

    refreshDataConfig(params) {
        this.config.data = params.data;
    }
    create(config) {
        let $dialogContainer = $('.js-dialog-content');
        const isNew = !$dialogContainer.length;
        let customClass,
            $wrapper,
            mClass;

        config.options = config.options || {};
        mClass = config.options.modificationClass || '';
        customClass = config.options.customClass || '';

        if (isNew) {
            $dialogContainer = $('<div></div>').addClass('js-dialog-content')
                .appendTo('body');
        }

        this.$container = $dialogContainer;
        this.$container.dialog(assign({}, defaultConfig, config.options || {}));

        $dialogContainer = this.$container.parents('.ui-dialog');
        $wrapper = $dialogContainer.children('.ui-dialog-content_wrapper');

        if (!$wrapper.length) {
            $wrapper = $(`<div class="ui-dialog-content_wrapper"/>`)
                .appendTo($dialogContainer);
        }

        if (customClass) {
            // get default ui classes.
            let uiClasses = $dialogContainer
                .attr('class')
                .split(Constants.GLOBAL.spaceChar)
                .filter(function (e) {return ~e.indexOf('ui-');})
                .join(Constants.GLOBAL.spaceChar);

            // clean all before set classes.
            $dialogContainer.attr('class', '');

            // add default ui and new custom classes.
            $dialogContainer.addClass(uiClasses).addClass(customClass);
        }

        if (mClass) {
            $wrapper.addClass(mClass);
        } else {
            $wrapper.removeClass(mClass);
        }

        $dialogContainer.children().not('.ui-dialog-content_wrapper').appendTo($wrapper);
        $(document.body).addClass('modal--show');
    }
    close() {
        if (!this.$container) {
            return;
        }

        this.$container.dialog('destroy').remove();
        this.$container = false;

        if (this.config.closecallback) {
            emitter.emit(this.config.closecallback);
        }
        $(document.body).removeClass('modal--show');
    }
    open(params, e) {
        if (this.config.scrollup) {
            scrollBrowser(0);
        }
        const configJSON = this.$el.attr('data-json-config');
        const options = params.url ? params : '';
        const config = options ? options : extend({}, this.config, JSON.parse(configJSON));

        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if (!this.$el.hasClass('m-cmp_loaded') || this.$el.hasClass('m-disabled')) {
            return false;
        }

        this.close();
        this.create(config);

        // for paypal button only
        if (this.$el.closest('.js-ppshipping-holder').length) {
            return this.close();
        }

        this.replace(config);
        return false;
    }
    replace(config) {
        let self = this;
        let url = config.url;

        if (!this.$container) {
            return;
        }

        if (url) {
            config.url = appendParamsToUrl(url, this.config.urlParams);

            $.ajax({
                url: config.url,
                data: config.data,
                type: config.type || 'GET',
                beforeSend: function () {
                    emitter.emit('content.loading');
                }
            }).done(function (response) {
                if (!response) {
                    emitter.emit('nocontent');
                }

                if (response.redirectUrl) {
                    return pageRedirect(response.redirectUrl);
                }
                if (response.error) {
                    emitter.emit('error', response.error);
                    return false;
                }

                config.content = config.replaceSelector ? $(response).find(config.replaceSelector) : response;
                self.openWithContent(config);
                emitter.emit('content.loaded');
                return false;
            }).fail(function () {
                emitter.emit('content.loaded.fail');
                // ToDo: handle error
            });
        } else if (config.html) {
            this.openWithContent(config);
        }
    }

    dialogReposition() {
        //In case of dynamic loading content:
        //do not allow dialog to be repositioned out of the window
        if ($('.ui-dialog').length > 0) {
            if ($('.ui-dialog').position().top < window.pageYOffset) {
                $('.ui-dialog').css('top', window.pageYOffset + 'px');
            }
        }
    }

    openWithContent(config) {
        const content = config.content || config.html;

        if (!this.$container || !content) {
            return;
        }

        if (config.replaceSelector) {
            this.$container.find(config.replaceSelector).replaceWith(config.content);
        } else {
            this.$container.empty().html(content);
        }

        this.eventMgr('carousel.imgloaded', this.dialogReposition);

        if (~content.indexOf('data-cmp="dynamicimage"') || ~content.indexOf('data-cmp="carousel"')) {
            this.$container.dialog('option', 'show', 500);
        }

        if (!this.$container.dialog('isOpen')) {
            this.$container.dialog('open');
        }

        if (config.callback) {
            emitter.emit(config.callback);
        }

        emitter.emit('open', config);
    }

    getEmitter () {
        return emitter;
    }
}

module.exports = Dialog;
