'use strict';

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';
import {redirect as pageRedirect} from '_core_ext/page';
import eventMgr from '_core_ext/eventMgr';
import {assign} from 'lodash';

const emitter = eventMgr.getEmitter('minicart');

let timerdbTouch;
let timerFlyout;
let timerDelayUpdate;

export default class MiniCart extends Component {
    init() {
        super.init();

        this.config = assign({}, Constants.MINI_CART.defaultConfig, this.config);

        this.eventMgr('addtocart.updateminicart', this.updateMiniCart);
        this.eventMgr('giftvoucher.updateminicart', this.updateMiniCart);
        this.eventMgr('cart.redrawminicart', this.updateMiniCart);
        this.eventMgr('reorder.success', this.updateMiniCart);
        this.eventMgr('quantityfield.qtychanged', this.updateQuantity);
        this.eventMgr('flightdetails.updateminicart', this.refreshMiniCart);

        // Mobile event listeners initialization.
        this.eventMgr('minicarttotal.touchstart', this.onTouchStart);
        this.event('touchstart', '.js-mini-cart-content-close', this.onCloseContent);

        // Desktop event listeners initialization.
        this.eventMgr('minicarttotal.mouseover', this.onMouseOver);
        this.event('click', '.js-mini-cart-content-close', this.onCloseContent);
        this.event('click', '.js-mini-cart-content', this.onContentClick);
        this.event('click', '.js-continue-shopping', this.onCloseContent);

        // Common events.
        this.event('click touchstart', '.js-remove-item', this.removeCartLineItem);
        this.eventMgr('flightdetails.opened', this._hideFlyout);
    }

    //
    // Common event handlres
    //

    refreshMiniCart() {
        if (window.pageContext.ns === 'cart') { return; }

        var self = this;

        $.ajax({
            url: Urls.refreshMinicard,
            data: {
                format: 'ajax'
            }
        }).done(function (html) {
            if (html) {
                self.$el.html(html);
            }
        });
    }

    getRedirectUrl(el, e) {
        e.preventDefault();
        $.ajax({
            url: Urls.continueShopping
        }).done(function (data) {
            pageRedirect(data.url);
        });
    }

    onContentClick(element, event) {
        // Close shopping cart on click out of shopping cart element.
        // If clicked not inner element, it means we are cliked out off.
        if ($(event.target).hasClass('visible')) {
            this._hideFlyout();
        }
    }

    updateQuantity(input, uuid, pid) {
        if (input && uuid && pid) {
            let self = this;

            $.ajax({
                url: Urls.updateProductQuantity,
                data: {
                    Quantity: String(input.val()),
                    uuid: String(uuid),
                    pid: String(pid)
                }
            }).done(function () {
                self.updateMiniCart(null, true, true);
            });
        }
    }

    removeCartLineItem(element) {
        const self = this;
        const params = {
            puuid: $(element).data('puuid'),
            guuid: $(element).data('guuid')
        };
        var url = params.guuid ? Urls.deleteGiftCertificateLineItem : Urls.deleteProductLineItem;

        $.ajax({
            url: url,
            data: params,
            method: 'POST'
        }).done(function () {
            self.updateMiniCart(null, true, true);
            emitter.emit('itemremoved', {
                $el: $(element)
            });
            self._hideFlyout();
        });
    }

    //
    // Mobile event handlers.
    //

    onCloseContent(el, event) {
        event.preventDefault();
        this._hideFlyout();
    }

    /**
     * Handles is there was a double or single touch.
     * And makes specified function call.
     */
    onTouchStart(element) {
        let self = this;

        if (Number(element.data('quantity')) === 0) {
            self._handleDoubleTouch(element);
            return;
        }

        if (self.isIconTouched) {
            self._handleDoubleTouch(element);

            delete self.isIconTouched;
            clearTimeout(timerdbTouch);
            return;
        }

        self.isIconTouched = true;

        clearTimeout(timerdbTouch);
        timerdbTouch = setTimeout(function () {
            self._handleSingleTouch();
            delete self.isIconTouched;
        }, this.config.dbTouchDelay);

        return;
    }

    //
    // Desktop event handlers.
    //

    onMouseOver(element) {
        let self = this;

        if (Number(element.data('quantity')) === 0) {
            return;
        }

        clearTimeout(timerFlyout);
        timerFlyout = setTimeout(function () {
            self._showFlyout();
        }, this.config.delayShowFlyout);
    }

    onMouseOut() {
        this._hideFlyout();
    }

    //
    // Handlers for event manager.
    //

    updateMiniCart(html, isAjax, isMinicart) {
        let self = this;
        let data = {};

        if (isAjax) {
            data.format = 'ajax';
            data.visible = true;
        }

        if (html) {
            this.$el.html(html);
        } else {
            clearTimeout(timerDelayUpdate);
            timerDelayUpdate = setTimeout(function () {
                $.ajax({
                    url: Urls.miniCart,
                    data: data
                }).done(function (html) {
                    if (html) {
                        self.$el.html(html);

                        if (!html.includes('b-mini-cart')) {
                            self._hideFlyout();
                        }
                    }
                    emitter.emit('itemupdated', isMinicart);
                });
            }, this.config.updateDelay);
        }
    }

    getEmitter () {
        return emitter;
    }

    //
    // Internally used function.
    //

    _showFlyout() {
        this.$el.find('.js-mini-cart-content')
            .addClass('visible');
        $('body').addClass('minicart-open');
        // hide account nav if active:
        $('.js-account-links').hide();
        $('.js-account-user-panel').removeClass('menu-active');

        emitter.emit('showflyout');
    }

    _hideFlyout() {
        this.$el.find('.js-mini-cart-content')
            .removeClass('visible');
        $('body').removeClass('minicart-open');

        emitter.emit('hideflyout');
    }

    _handleDoubleTouch(element) {
        // Make a request to Cart-Show controller.
        let href = element && element.attr('href');

        if (href) {
            window.location = href;
        }
    }

    _handleSingleTouch() {
        this._showFlyout();
    }
}
