'use strict';

import Component from '_core_ext/components/Component';
import {ajaxUrl} from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const wishlistEmitter = eventMgr.getEmitter('wishlistpage');

export default class Wishlist extends Component {
    init() {
        const self = this;

        super.init();
        this.event('click', '.js-share-link', this.showShareLink);
        this.event('click', '.js-remove-item', function (el, e) {
            let
                $el = $(el),
                action = $el.attr('name'),
                $form = $el.closest('.js-wl-form');

            e.preventDefault();

            self.updateWishlist(true, action, null, $form.serialize(), $form);
        });
        this.eventMgr('wishlistbeforeadd.updatewishlist', function () {
            self.updateWishlist(true);
        });
        this.eventMgr('addtocart.updatewishlist', function () {
            self.updateWishlist(true);
        });
        this.eventMgr('flightdetails.updateminicart', function () {
            self.updateWishlist(true);
        });
        this.eventMgr('quantityfield.qtychanged', function () {
            let qty = parseInt(this.$el.find('.js-item-quantity-' + arguments[1]).find('input').val());
            let itemPrice = parseFloat(this.$el.find('.js-item-price-' + arguments[1]).text().replace(/[^0-9.]/g, ''));

            if (qty && itemPrice) {
                let totalPrice = itemPrice * qty;
                let totalPriceField = this.$el.find('.js-total-price-' + arguments[1]);

                totalPriceField.text(totalPriceField.text().replace(/\d+.\d{2}/, totalPrice.toFixed(2)));
            }

        });
    }

    showShareLink(element) {
        this.$el.find($(element).attr('data-target')).removeClass('h-hidden');
    }

    updateWishlist(redrawMiniwishlist, action, noParams, data, $triggeredForm) {
        let
            $form = this.$el.find('.js-wl-form'),
            self = this,
            defaultAction;

        if ($triggeredForm) {
            defaultAction = $triggeredForm.find('.js-wishlist-update-item-button').attr('name');
        } else {
            defaultAction = this.$el.find('.js-wishlist-update-item-button').attr('name');
        }

        if (!action) {
            action = defaultAction;
        }

        let tokenVal = $form.find('input[name="csrf_token"]').val();
        let wlFormURL = $form.attr('action');
        let submitData = '';

        if (noParams) {
            submitData = {};
            submitData[defaultAction] = defaultAction;
            submitData.csrf_token = tokenVal;
        } else {
            submitData = data ? data : $form.serialize();
            submitData = submitData.replace(new RegExp(defaultAction, 'g'), action);
        }

        wlFormURL = ajaxUrl(wlFormURL);

        $.ajax({
            url: wlFormURL,
            type: 'POST',
            data: submitData
        }).done(function (data) {
            if (data) {
                // Take a part from response.
                let $updates = $(data).find('.js-wl-owner').children();

                // Clean current WL html.
                self.$el.empty();

                // Append taken part from above to the cleaned element.
                self.$el.append($updates);

                if (redrawMiniwishlist) {
                    wishlistEmitter.emit('itemremoved');
                }
            }
        });
    }

}
module.exports = Wishlist;
