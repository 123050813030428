'use strict';

var _ = require('lodash');
var inScroll = false;
var util;

function getQueryStringParameters(qs) {
    if (!qs || qs.length === 0) {return {};}
    var params = {};
    // Use the String::replace method to iterate over each
    // name-value pair in the string.
    qs.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
        function ($0, $1, $2, $3) {
            params[$1] = decodeURIComponent($3).replace(/\+/g, ' ');
        }
    );
    return params;
}

function appendParamToURLPrivate(url, name, value) {
    var separator;

    // quit if the param already exists
    if (url.indexOf(name + '=') !== -1) {
        return url;
    }

    separator = url.indexOf('?') === -1 ? '?' : '&';
    return url + separator + name + '=' + encodeURIComponent(value);
}

function chooseSeparator(baseUrl, paramsStr) {
    if (baseUrl.indexOf('?') === -1 && paramsStr.indexOf('?') === -1) {
        return '?';
    }
    return "&";
}

util = {
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollTo: function (topOffsetOrElement, cb) {
        var $scrollElement,
            scrollToElement = typeof topOffsetOrElement === 'object',
            topOffset;

        if (inScroll) {
            return;
        }

        inScroll = true;

        if (scrollToElement) {
            topOffset = topOffsetOrElement.offset().top;
        } else {
            topOffset = topOffsetOrElement;
        }

        $scrollElement = $('html, body');

        $scrollElement.animate({
            scrollTop: topOffset
        }, {
                duration: 500,
                complete: () => {
                    if (typeof cb === 'function') {
                        cb();
                    }

                    inScroll = false;
                }
            });
    },
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: appendParamToURLPrivate,

    appendSearchParamsToURL: function (url, params, isAjax) {
        let obj = {};
        let paramsStr = '';
        let separator;
        let skip = ['cgid', 'price', 'q', 'srule', 'pmid'];

        /**
         * Make an object from params array of objects like:
         *
         * {
         *   "brand": "b1|b2|b3",
         *   "size": "S|L|M"
         * }
         */
        params.map(function (el) {
            if (!el.value) {
                return;
            }
            if (obj[el.name]) {
                obj[el.name] += '|' + encodeURIComponent(el.value);
            } else {
                obj[el.name] = encodeURIComponent(el.value);
            }
        });

        /**
         * Make a params url for Search-Show
         * Sample: ?prefn1=brand&prefv1=Lacy-S|test2|test3&prefn2=size&prefv2=L|XS|S
         */
        Object.keys(obj).map(function (k, i) {
            separator = chooseSeparator(url, paramsStr);

            /**
             * Skip cgid as it needs to be in a URL as cgid=name
             * but not prefn1=cgid&prefv1=name.
            */
            if (skip.indexOf(k) !== -1) {
                return;
            }

            paramsStr += separator + 'prefn' + i + '=' + k;
            paramsStr += '&prefv' + i + '=' + obj[k];
        });

        // Append all skipped values.
        skip.map(function (e) {
            separator = chooseSeparator(url, paramsStr);

            if (e === 'price' && obj[e]) {
                let price = obj[e].split('-');

                paramsStr += separator + 'pmin=' + price[0];
                paramsStr += '&pmax=' + price[price.length-1];

                return;
            }

            if (obj[e]) {
                paramsStr += separator + e + '=' + obj[e];
            }
        });

        if (isAjax) {
            separator = chooseSeparator(url, paramsStr);

            paramsStr += separator + 'format=ajax';
        }

        return url + paramsStr;
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    appendParamsToUrlForSearch: function (url, params) {
        var _url = url;

        if (!params) {
            return _url;
        }

        if (params.constructor.name === 'Array') {
            _.each(params, function (obj, index) {
                let idx = index + 1; // start from 1
                // return url + separator + encodeURIComponent(value);
                // _url = appendParamToURLPrivate(_url, obj.name, obj.value);

                if (_url.indexOf(name + '=') === -1) {
                    _url += 'prefn' + idx + '=' + obj.name;
                }

                _url += 'prefv' + idx + '=' + obj.value;
            }.bind(this));
        }

        return _url;
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object|Array} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;

        // Array with object.
        // For example after Form.serializeArray().
        _.each(params, function (value, name) {
            _url = appendParamToURLPrivate(_url, name, value);
        }.bind(this));

        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        var a;

        if (!_.isString(url)) {
            return;
        }

        a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof (offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return appendParamToURLPrivate(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;

        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;

        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: getQueryStringParameters,

    getParameterValueFromUrl: function (parameterName, url) {
        var currentQueryString = url || window.location.search;
        var currentQueryStringParams = getQueryStringParameters(currentQueryString);

        return currentQueryStringParams[parameterName];
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'ID' || field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]).trigger('change');
            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]').trigger('change');
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
            }
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');

            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    checkLimitMessage: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation, duration) {
        duration = typeof (duration) === 'undefined' ? 500 : duration;

        $('html, body').animate({
            scrollTop: xLocation
        }, duration);
    },

    setWindowScrollTop: function (offset) {
        var fixedHeaderHeight;
        var positionToScroll;
        var isOffset = !offset;

        if (isOffset) {
            return;
        }

        fixedHeaderHeight = $('.js-sticky_header').height();
        positionToScroll = offset - fixedHeaderHeight;

        if (positionToScroll <= 0) {
            return;
        }

        $(window).scrollTop(positionToScroll);
    },

    isMobile: function () {
        var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce',
            'opera mini', 'palm'];
        var idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isMobile;
    },

    eventDelay: function (callback, threshhold, scope, skipInitialCall) {
        var last,
            deferTimer;

        threshhold = threshhold || 250;
        /**
         * @todo  Add description
         */
        return function () {
            var context = scope || this,
                now = (new Date()).getTime(),
                args = arguments;

            if (last && now < last + threshhold) {
                clearTimeout(deferTimer);
                deferTimer = setTimeout(function () {
                    last = now;
                    callback.apply(context, args);
                }, threshhold);
            } else {
                last = now;

                if (!skipInitialCall) {
                    callback.apply(context, args);
                }
            }
        };
    },

    /**
     * Throttling Function Calls
     *
     * @see http://www.nczonline.net/blog/2007/11/30/the-throttle-function/
     * @param {Function} callback Callback function to call
     * @param {Number} delay Delay before callback fire
     * @param {Object} scope The context to for callback fire
     */
    throttle: function (callback, delay, scope) {
        clearTimeout(callback._tId);
        callback._tId = setTimeout(function () {
            callback.call(scope);
        }, delay || 100);
    },

    getTimer: function () {
        return {
            id: null,
            clear: function () {
                if (this.id) {
                    window.clearTimeout(this.id);
                    delete this.id;
                }
            },
            start: function (duration, callback) {
                this.id = setTimeout(callback, duration);
            }
        };
    },

    /**
     * Add/remove value from array depending on its existing in array
     * @param inArr
     * @param value
     * @returns {Array}
     */
    toggleFromArray: function (inArr, value) {
        var arr = inArr.slice();
        var valueIndex = arr.indexOf(value);

        if (valueIndex >= 0) {
            arr.splice(valueIndex, 1);
        } else {
            arr.push(value);
        }

        return arr;
    },

    /**
     * Helper function.
     * Checks if two arrays have the same values. It doesn't depend from order
     * @param arr1
     * @param arr2
     * @returns {Boolean}
     */
    arraysEqualByValues: function (arr1, arr2) {
        var len1, len2;

        if (!arr1 || !arr2) {
            return arr1 === arr2;
        }

        len1 = arr1.length;
        len2 = arr2.length;

        if (len1 !== len2) {
            return false;
        }
        for (var i = 0; i < len1; i++) {
            if (arr2.indexOf(arr1[i]) < 0) {
                return false;
            }
        }

        return true;
    },

    /**
     * Helper function.
     * Construct :nth-child selector with given element and start position
     * @param {String} elem
     * @param {Number} from
     * @returns {String}
     */
    fromNthSelector: function (elem, from) {
        var selector = elem + ':nth-child(n+';

        selector += (from + 1);
        selector += ')';

        return selector;
    },

    getKeyByValue: function (HashMapCollection, value) {
        for (var prop in HashMapCollection) {
            if (HashMapCollection.hasOwnProperty(prop)) {
                if (HashMapCollection[prop] === value) {
                    return prop;
                }
            }
        }
    },

    /**
     * [getUri description]
     * @param  {[type]} o [description]
     * @return {[type]}   [description]
     */
    getUri: function (o) {
        var a;

        if (o.tagName && $(o).attr('href')) {
            a = o;
        } else if (typeof o === 'string') {
            a = document.createElement('a');
            a.href = o;
        } else {
            return null;
        }

        // overcome some stupid ie behaviour
        if (a.host === '') {
            a.href = a.href;
        }

        // all actual version of IE not so smart to correctly process
        // protocol independent locations, so wee need to help them
        if (a.protocol === ':') {
            a.protocol = window.location.protocol;
        }

        // fix for some IE browsers
        if (a.pathname.indexOf('/') !== 0) {
            a.pathname = '/' + a.pathname;
        }

        return Object.create({
            protocol: a.protocol, //http:
            host: a.host, //www.myexample.com
            hostname: a.hostname, //www.myexample.com'
            port: a.port, //:80
            path: a.pathname, // /sub1/sub2
            query: a.search, // ?param1=val1&param2=val2
            queryParams: a.search.length > 1 ? getQueryStringParameters(a.search.substr(1)) : {},
            hash: a.hash, // #OU812,5150
            url: a.protocol + '//' + a.host + a.pathname,
            toString: function () {
                return this.protocol + '//' + this.host + this.port + this.pathname + this.search + this.hash;
            }
        });
    },

    progressShow: function ($container) {
        $container.addClass('js-loading');
        //progress.show($container);
    },

    progressHide: function ($container) {
        $container.removeClass('js-loading');
        //progress.hide();
    },

    isTouch: function () {
        var mobileAgentHash = ['tablet', 'ipad', 'mobile', 'phone', 'ipod', 'android', 'blackberry', 'windows ce',
            'opera mini', 'palm'];
        var idx = 0;
        var isTouch = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isTouch) {
            isTouch = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }
        return isTouch;
    },

    scrollToViewMobile: function ($el, cb) {
        var headerHeight = $('.js-sticky-header').height();
        var elOffset = $el.offset().top;
        var topOffset = parseInt((elOffset - headerHeight), 10) - 10;

        var $scrollElement = $('html, body');

        $scrollElement.animate({
            scrollTop: topOffset
        }, {
                duration: 250,
                complete: () => {
                    if (typeof cb === 'function') {
                        cb();
                    }
                }
            });
    },


    setCookie: function (key, value, minutes) {
        var date = new Date();

        date.setMinutes(date.getMinutes() + minutes);

        document.cookie = key + '=' + value + '; expires=' + date.toGMTString() + '; path=/';
    },

    getCookie: function (key) {
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var tokens = cookies[i].split('=');
            var cookieKey = tokens[0].trim();

            if (cookieKey === key) {
                return tokens[1];
            }
        }
        return '';
    },

    removeCookie: function (key) {
        document.cookie = key + '= ;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    },

    log: console
};

module.exports = util;
