'use strict';

import Component from '_core_ext/components/Component';
import util from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('wishlistbeforeadd');

export default class WishlistBeforeAddPopup extends Component {
    init() {
        super.init();
        this.event('click', '.js-remove-product', this.removeProduct);
        this.event('click', '.js-keep-product', this.keepProduct);
    }

    keepProduct() {
        emitter.emit('closewishlistremoveconfirmation');
        emitter.emit('productkeeped', this.$el.find('.js-product-id').val());
    }

    removeProduct() {
        $.ajax({
            url: util.appendParamsToUrl(
                Urls.removeWishlistProduct,
                {
                    itemID: this.$el.find('.js-item-id').val()
                }
            )
        });

        emitter.emit('closewishlistremoveconfirmation');
        emitter.emit('updatewishlist');
        emitter.emit('productremoved', this.$el.find('.js-product-id').val());
    }

}