'use strict';
import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('vtolayout');

const CURRENT_MODE = {
    PHOTO: 'PHOTO_MODE',
    LIVE: 'LIVE_MODE'
};

const errorScreen = '.b-product-modiface__error-screen';
const errorMessage = '.b-product-modiface__error-text';

export default class VtoLayout extends Component {
    init() {
        super.init();
        this.eventMgr('window.resize', this.setModifaceLayoutPosition);
        this.eventMgr('tryiton.starttryonlive', this.startTryOnLive);
        this.eventMgr('tryiton.starttryonphoto', this.startTryOnPhoto);
        this.eventMgr('tryiton.uploadimage', this.uploadImage);
        this.eventMgr('variationselect.variantchanged', this.changeMakeup);
        this.eventMgr('productactions.variantchanged', this.changeMakeup);
        this.eventMgr('tryiton.initializemodiface', this.initializeModiface);
        this.event('click', '.js-toggleBeforeAfter-btn', this.toggleBeforeAfter);
        this.event('touchstart', '.js-compareSlider', this.touchSlide);
        this.event('mousedown', '.js-compareSlider', this.mouseSlide);
        this.event('click', '.js-close-btn', this.stopMakeupApp);
        this.vtlookupobject = this.$el.data('vtlookupobject');
        this.vtoHeight = 0;
        this.vtoWidth = 0;
        this.vtoLeft = 0;
    }

    startTryOnLive() {
        this.startLive();
    }

    startTryOnPhoto() {
        this.firefoxFirstClick();
        emitter.emit('clickuploadbutton');
    }

    getImageUri(uploadedImaged) {
        return new Promise((resolve) => {
            // convert to base64
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.readAsDataURL(uploadedImaged);
        });
    }

    uploadImage(event) {
        if (event.value === '') { return; }
        this.getImageUri(event.target.files[0]).then((base64Image) => {
            this.currentImage = base64Image;
            this.startPhoto(base64Image);
        });
        emitter.emit('clearuploadbutton');
    }

    firefoxFirstClick() {
        if (navigator.userAgent.indexOf('Firefox') > 0) {
            emitter.emit('clickuploadbutton');
        }
    }

    loading() {
        this.$el.find('.loading-overlay')[0].style.display = 'block';
        this.$el.find('.gif-loader')[0].style.display = 'block';
    }

    loaded() {
        this.$el.find('.loading-overlay')[0].style.display = 'none';
        this.$el.find('.gif-loader')[0].style.display = 'none';
    }

    startPhoto(base64Image) {
        if (this.currentMode) {
            this.stopMakeupApp();
        }
        this.modifaceMountPreparing();
        this.currentMode = CURRENT_MODE.PHOTO;
        this.closeErrorMessage();
        this.closeTryItOnButtons();
        this.loading();
        this.resetAll();
        this.addKeyboardEventListener();

        this.checkVtLookupObject(this.vtlookupobject)
            .then(() => {
                window.MFE_VTO.startPhotoMode({ imgUri: base64Image })
                    .then(() => {
                        window.MFE_VTO.setPhotoLook({ lookId: 'canvas', lookObject: this.vtlookupobject })
                            .then((canvas) => {
                                window.MFE_VTO.getPhotoOriginalCanvas()
                                    .then((originalCanvas) => {
                                        this.isPhotoModeOn = true;
                                        // Css settings
                                        this.$el.find('.rendered-canvas-container')[0].appendChild(canvas.renderedCanvas);
                                        this.$el.find('.orig-canvas-container')[0].appendChild(originalCanvas);

                                        // Set center and size of image
                                        const canvasContainerHeight = this.$el.find('.rendered-canvas-container')[0].offsetHeight;
                                        const canvasContainerWidth = this.$el.find('.rendered-canvas-container')[0].offsetWidth;
                                        // Image is portrait
                                        if (canvas.renderedCanvas.height / canvas.renderedCanvas.width > canvasContainerHeight / canvasContainerWidth) {
                                            originalCanvas.style.width = '100%';
                                            canvas.renderedCanvas.style.width = `${canvasContainerWidth}px`;
                                        } else { // Image is landscape
                                            canvas.renderedCanvas.style.height = '100%';
                                            originalCanvas.style.height = '100%';
                                            this.adjustMargin('orig-canvas-container', 'rendered-canvas-container');
                                        }
                                    });
                            })
                            .catch((error) => {
                                this.handleError(error);
                            });
                    })
                    .catch((error) => {
                        this.handleError(error);
                        return;
                    })
                    .finally(() => {
                        this.loaded();
                    });
            })
            .catch((error) => {
                this.loaded();
                this.handleError(error);
                return;
            });
    }

    setModifaceLayoutPosition() {
        if (document.querySelector('.b-product-modiface__layout--show')) {
            let breakpointLG = 1024;
            let breakpointSM = 767;
            let productImage = $document.find('.b-slider');
            let errorScreenHeight = Number(window.getComputedStyle(document.querySelector('.b-product-modiface__error-screen')).height.slice(0, -2));
            let isErrorScreen = document.querySelector('.m-error-show');
            let pdpContainerTopPadding = $document.find('.l-pdp__product-wrapper').css('padding-top');
            this.productImageDomReact = productImage[0].getBoundingClientRect();
            this.vtoWidth = this.vtoWidth > this.productImageDomReact.width ? this.vtoWidth : this.productImageDomReact.width;
            this.vtoLeft = this.vtoLeft > this.productImageDomReact.left ? this.vtoLeft : this.productImageDomReact.left;
            let topElementsHeight = 40 + document.querySelector('.b-product-info__product-name-wrap').offsetHeight + document.querySelector('.b-product-info__product-number').offsetHeight;
            this.vtoHeight = productImage[0] && productImage[0].offsetHeight ? productImage[0].offsetHeight : this.vtoHeight;

            this.$el.css({
                height: this.vtoHeight + (isErrorScreen ? errorScreenHeight : 0),
                width: window.innerWidth > breakpointSM ? this.vtoWidth : '100%',
                left: window.innerWidth > breakpointSM ? this.vtoLeft : 0,
                top: window.innerWidth > breakpointLG ? pdpContainerTopPadding : topElementsHeight
            });

            if (isErrorScreen) {
                productImage.css('margin-bottom', `${15 + errorScreenHeight}px`);
                const compareSlider = this.$el.find('.compare-slider');
                compareSlider.height(this.vtoHeight);

                this.$el.find('.rendered-canvas-container').height(this.vtoHeight);
                this.$el.find('.orig-canvas-container').height(this.vtoHeight);
            } else {
                productImage.css('margin-bottom', '30px');
            }
        }
    }

    modifaceMountPreparing() {
        let pdpMain = $document.find('.l-pdp__main');
        pdpMain.addClass('b-product-modiface__layout--show');

        this.setModifaceLayoutPosition();
    }

    startLive() {
        if (this.currentMode) {
            this.stopMakeupApp();
        }
        this.modifaceMountPreparing();
        this.currentMode = CURRENT_MODE.LIVE;
        this.closeErrorMessage();
        this.closeTryItOnButtons();
        this.loading();
        this.resetAll();
        this.addKeyboardEventListener();

        let cameraVtLookupObject = [];

        this.checkVtLookupObject(this.vtlookupobject)
            .then(() => {
                cameraVtLookupObject = this.vtlookupobject;
                this.startVtoLiveMode(cameraVtLookupObject);
            })
            .catch((error) => {
                this.startVtoLiveMode(cameraVtLookupObject)
                    .finally(() => {
                        this.displayError(error);
                    });
            });
    }

    startVtoLiveMode(lookupObject) {
        return new Promise((resolve) => {
            window.MFE_VTO.startLiveMode()
                .then(() => {
                    window.MFE_VTO.setLiveLook({ lookId: 'canvas', lookObject: lookupObject })
                        .then((canvas) => {
                            window.MFE_VTO.setLiveLook({ lookId: 'canvas-original', lookObject: [] })
                                .then((originalCanvas) => {
                                    this.isLiveModeOn = true;
                                    canvas.renderedCanvas.style.height = '100%';
                                    originalCanvas.renderedCanvas.style.height = '100%';

                                    // Css settings
                                    this.$el.find('.rendered-canvas-container')[0].appendChild(canvas.renderedCanvas);
                                    this.$el.find('.orig-canvas-container')[0].appendChild(originalCanvas.renderedCanvas);
                                    this.adjustMargin('orig-canvas-container', 'rendered-canvas-container');
                                });
                        }).
                        catch((error) => {
                            this.displayError(error);
                        });
                })
                .catch((error) => {
                    this.displayError(error);
                })
                .finally(() => {
                    this.loaded();
                    resolve();
                });
        });
    }

    adjustMargin(id, idRendered) {
        const canvasDiv = this.$el.find('.' + id)[0];
        const canvas = canvasDiv.firstChild;
        const dashboard = this.$el;
        let leftDist = dashboard.clientHeight * canvas.width / canvas.height;
        leftDist = (dashboard.clientWidth - leftDist) / 2;
        canvasDiv.querySelector('canvas').style.left = Math.round(leftDist);
        this.$el.find('.' + idRendered)[0].style.left = Math.round(leftDist);
    }

    resetAll() {
        this.$el.find('.rendered-canvas-container')[0].style.display = 'block';
        this.$el.find('.ui-interface')[0].style.display = 'block';
    }

    toggleBeforeAfter() {
        const compareSlider = this.$el.find('.compare-slider')[0];
        const sliderStyle = window.getComputedStyle(compareSlider);
        if (sliderStyle.getPropertyValue('display') === 'none') {
            this.enableBeforeAfter();
        } else {
            this.disableBeforeAfter();
        }
    }

    resetSlider() {
        this.$el.find('.compare-slider')[0].style.left = '50%';
        this.$el.find('.rendered-canvas-container')[0].style.width = '50%';
        this.$el.find('.orig-canvas-container')[0].style.display = 'block';
    }

    enableBeforeAfter() {
        this.$el.addClass('b-product-modiface__compare-active');
        this.$el.find('.compare-slider')[0].style.display = 'block';
        this.resetSlider();
    }

    disableBeforeAfter() {
        this.$el.removeClass('b-product-modiface__compare-active');
        this.$el.find('.compare-slider')[0].style.display = 'none';
        this.$el.find('.orig-canvas-container')[0].style.display = 'none';
        this.$el.find('.rendered-canvas-container')[0].style.width = '100%';
    }

    touchSlide(element, event) {
        if (event.currentTarget === event.target) {
            return;
        }
        const target = event.currentTarget;

        // prevent default scroll behaviour
        event.preventDefault();

        const max = this.$el[0].clientWidth;
        const touchX = event.originalEvent.touches[0].clientX;

        let startX = window.getComputedStyle(target).left;
        startX = parseFloat(startX); // already in px

        const onTouchMove = (e) => {
            // prevent default scroll behaviour
            e.preventDefault();
            e.stopPropagation();

            const leftDistance = `${this.clamp(startX + (e.touches[0].clientX - touchX), 0, max) / max * 100}%`;
            this.$el.find('.rendered-canvas-container')[0].style.width = leftDistance;
            this.$el.find('.compare-slider')[0].style.left = leftDistance;
        };

        document.addEventListener('touchmove', onTouchMove, { passive: false });

        target.ontouchend = () => {
            document.removeEventListener('touchmove', onTouchMove);
            document.removeEventListener('touchend', target.ontouchend);
            target.ontouchend = null;
        };
        document.addEventListener('touchend', target.ontouchend);
    }

    mouseSlide(element, event) {
        if (event.currentTarget === event.target) {
            return;
        }

        const target = event.currentTarget;

        const max = this.$el[0].clientWidth;
        const mouseX = event.clientX;
        let startX = window.getComputedStyle(target).left;
        startX = parseFloat(startX); // already in px

        const onMouseMove = (e) => {
            const leftDistance = `${this.clamp(startX + (e.clientX - mouseX), 0, max) / max * 100}%`;
            this.$el.find('.rendered-canvas-container')[0].style.width = leftDistance;
            this.$el.find('.compare-slider')[0].style.left = leftDistance;
        };

        document.addEventListener('mousemove', onMouseMove);

        target.onmouseup = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', target.onmouseup);
            target.onmouseup = null;
        };
        document.addEventListener('mouseup', target.onmouseup);
    }

    stopLive() {
        window.MFE_VTO.stopLiveMode().then(window.MFE_VTO.destroyLiveModule()).catch(() => {
            // ignore module does not exist error and removeChild on Node error
        });
    }

    stopPhoto() {
        window.MFE_VTO.destroyPhotoModule().then(() => {
            emitter.emit('clearuploadbutton');
        });
    }

    stopMakeupApp() {
        this.loading();
        if (this.isLiveModeOn) { this.stopLive(); this.isLiveModeOn = false; }
        else if (this.isPhotoModeOn) { this.stopPhoto(); this.isPhotoModeOn = false; }
        if (this.currentMode) { this.removeKeyBoardEventListener(); }
        if (this.currentMode) {
            // Css settings
            this.$el.find('.ui-interface')[0].style.display = 'none';
            this.$el.find('.rendered-canvas-container')[0].style.display = 'none';
            $document.find('.l-pdp__main').removeClass('b-product-modiface__layout--show');
            this.$el.removeClass('m-dark-screen');
        }

        this.currentMode = '';
        this.disableBeforeAfter();
        this.loaded();
        this.openTryItOnButtons();
    }

    clamp(n, min, max) {
        return Math.max(Math.min(n, max), min);
    }

    closeErrorMessage() {
        this.$el.find(errorScreen).removeClass('m-error-show');
        this.setModifaceLayoutPosition();
    }

    displayError(error) {
        this.$el.find(errorScreen).addClass('m-error-show');
        if (error.includes('ERROR_MODULE_CREATED')) {
            this.$el.find(errorMessage).html(Resources.MODIFACE_ERROR_DEFAULT);
            this.$el.addClass('m-dark-screen');
            this.logClientError('modiface_s3', 'Modiface initialization failed, something is not set correctly in the config Object, or the license key is invalid : ' + error);
            return;
        }

        if (error.includes('ERROR_CREATE_LOOK')) {
            this.$el.find(errorMessage).html(Resources.MODIFACE_ERROR_DEFAULT);
            this.$el.addClass('m-dark-screen');
            this.logClientError('modiface_s3', 'Modiface initialization failed, mask or asset prefix are not set correctly : ' + error);
            return;
        }

        this.logClientError('client_side', 'Modiface sdk error: ' + error);

        if (error.includes('ERROR_SMALL_IMG')) {
            this.$el.find(errorMessage).html(Resources.MODIFACE_ERROR_SMALL_IMG);
            this.$el.addClass('m-dark-screen');
        } else if (error.includes('ERROR_WEBCAM')) {
            this.$el.find(errorMessage).html(Resources.MODIFACE_ERROR_WEBCAM);
            this.$el.addClass('m-dark-screen');
            this.openTryItOnButtons();
        } else if (error.includes('ERROR_DIMENSION') || error.includes('ERROR_FILESIZE')) {
            this.$el.find(errorMessage).html(Resources.MODIFACE_ERROR_DIMENSION);
            this.$el.addClass('m-dark-screen');
        } else if (error.includes('ERROR_FILETYPE')) {
            this.$el.find(errorMessage).html(Resources.MODIFACE_ERROR_FILE_TYPE);
            this.$el.addClass('m-dark-screen');
        } else if (error.includes('ERROR_NO_FACE')) {
            this.$el.find(errorMessage).html(Resources.MODIFACE_ERROR_NO_FACE);
            this.$el.addClass('m-dark-screen');
        } else if (error.includes('ERROR_INVALID_LOOKUP_OBJECT')) {
            this.$el.find(errorMessage).html(Resources.MODIFACE_ERROR_INVALID_LOOKUP_OBJECT);
            if (this.currentMode === CURRENT_MODE.PHOTO) {
                this.$el.addClass('m-dark-screen');
            }
        }
        else {
            this.$el.find(errorMessage).html(Resources.MODIFACE_ERROR_DEFAULT);
            if (this.currentMode === CURRENT_MODE.PHOTO) {
                this.$el.addClass('m-dark-screen');
            }
        }

        this.setModifaceLayoutPosition();
    }

    changeMakeup($newContent) {
        const newLookupObject = $newContent.find('.js-current-lookup-object').data('currentLookup');

        if (this.currentMode) {
            if (this.isLiveModeOn || this.isPhotoModeOn) { // camera or photo module started before
                this.checkVtLookupObject(newLookupObject)
                    .then(() => {
                        this.closeErrorMessage();
                        this.closeTryItOnButtons();
                        window.MFE_VTO.lookChangeFromProduct({ mode: this.currentMode, lookId: 'canvas', lookObject: newLookupObject });
                    })
                    .catch((error) => {
                        window.MFE_VTO.lookChangeFromProduct({ mode: this.currentMode, lookId: 'canvas', lookObject: [] });
                        this.closeTryItOnButtons();
                        this.handleError(error);
                        return;
                    });
            } else if (this.currentMode === CURRENT_MODE.LIVE) { // camera module haven't started before
                this.checkVtLookupObject(newLookupObject)
                    .then(() => {
                        this.closeErrorMessage();
                        this.closeTryItOnButtons();
                        this.startLive(newLookupObject);
                    })
                    .catch((error) => {
                        this.closeTryItOnButtons();
                        this.handleError(error);
                        return;
                    });
            }
            else if (this.currentMode === CURRENT_MODE.PHOTO) { // photo module haven't started before
                this.checkVtLookupObject(newLookupObject)
                    .then(() => {
                        this.closeErrorMessage();
                        this.closeTryItOnButtons();
                        this.startPhoto(this.currentImage, newLookupObject);
                    })
                    .catch((error) => {
                        this.closeTryItOnButtons();
                        this.handleError(error);
                        return;
                    });
            }
        } else {
            this.vtlookupobject = newLookupObject;
        }
    }

    initializeModiface() {
        if (!this.vto) {
            try {
                window.MFE_VTO.init({
                    config: {
                        // libraryInfo object could be configured alternatively
                        // based on your library hosting location. Example:
                        libraryInfo: {
                            domain: SitePreferences.MODIFACE_DOMAIN,
                            path: '',
                            version: '',
                            maskPrefix: SitePreferences.MODIFACE_MASK_PREFIX,
                            assetPrefix: SitePreferences.MODIFACE_ASSET_PREFIX,
                            debugMode: SitePreferences.MODIFACE_DEBUG_MODE
                        },
                        moduleMode: SitePreferences.MODIFACE_MODULE_MODE,
                        trackerLicenceKey: SitePreferences.MODIFACE_TRACKER_LICENCE_KEY
                    }
                });
                this.vto = window.MFE_VTO;
            } catch (error) {
                this.logClientError('modiface_s3', 'Modiface initialization failed: ' + error);
            }
        }
    }

    checkVtLookupObject(lookupObject) {
        return new Promise((resolve, reject) => {
            if (lookupObject) {
                if (Object.getPrototypeOf(lookupObject) === Array.prototype && lookupObject.length > 0) {
                    resolve(true);
                }
            }
            reject('ERROR_INVALID_LOOKUP_OBJECT');

            this.vtlookupobject = lookupObject;
        });
    }

    handleError(error) {
        this.$el.find('.gif-loader')[0].style.display = 'none';
        this.displayError(error);
    }

    addKeyboardEventListener() {
        document.addEventListener('keydown', (event) => { this.keyBoardEvent(event, this); });
    }

    keyBoardEvent(event, self) {
        const keyName = event.key;

        if (keyName === 'Escape') {
            self.stopMakeupApp();
        }
    }

    removeKeyBoardEventListener() {
        document.removeEventListener('keydown', this.keyBoardEvent);
    }

    closeTryItOnButtons() {
        $document.find('.b-product-modiface').addClass('h-hidden');
    }

    openTryItOnButtons() {
        $document.find('.b-product-modiface').removeClass('h-hidden');
    }

    logClientError(loggerCategory, message, error, line, column) {
        loggerCategory = loggerCategory || 'client_side';
        const logEnabledCheckName = 'IS_' + loggerCategory.toUpperCase() + '_LOGS_ENABLED';
        var IS_ERROR_JS_LOGS_ENABLED = SitePreferences[logEnabledCheckName];
        var errorJsURL = SitePreferences.ERROR_JS_URL;
        var request = window.XMLHttpRequest;

        if (IS_ERROR_JS_LOGS_ENABLED && request) {
            try {
                var xhr = new request();
                var logurl = errorJsURL;
                var params = 'url=' + window.location.href;

                params += '&loggerCategory=' + loggerCategory;

                if (message) {
                    params += '&message=' + message;
                }
                if (line) {
                    params += '&line=' + line;
                }
                if (column) {
                    params += '&column=' + column;
                }
                if (error) {
                    params += '&stack=' + (error && error.stack);
                }
                params += '&format=ajax';
                xhr.open("POST", logurl, true);
                xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
                xhr.send(params);
            } catch (e) {
                // don't need to show on client side
            }
        }
        return false;
    }
}
