/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');

    s.setAttribute('src', '//ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);

}

require('./js-ext')();

var app = window.app = {
    init: function () {
        let references = require('_core_ext/references-config'),
            ComponentMgr = require('_core_ext/components/ComponentMgr');

        window.componentMgr = window.componentMgr || new ComponentMgr(references);
    },
    initGlobalObjects: function () {
        // Global jquery selected objects.

        window.$window = window.$window || $(window);
        window.$document = window.$document || $(document);
        window.$html = window.$html || $('html, body');
        window.$body = window.$body || $('body');
    },
    initGlobalEvents: function (params) {
        $document.on('scroll', function () {
            params.documentEmitter.emit('scroll');
        });
        $document.ready(function () {
            params.documentEmitter.emit('ready');
        });
        $window.resize(function () {
            params.windowEmitter.emit('resize');
        });
    }
};

// initialize app
$(document).ready(function () {
    let eventMgr = require('_core_ext/eventMgr'),
        documentEmitter = eventMgr.getEmitter('document'),
        windowEmitter = eventMgr.getEmitter('window');

    app.initGlobalObjects();
    app.init();
    app.initGlobalEvents({
        documentEmitter: documentEmitter,
        windowEmitter: windowEmitter
    });

    documentEmitter.emit('ready');
});

// changed accordion behavior for collection points page
$('.collection-points .b-product-details__accordion-item').click(function() {
    var content = $(this).find('.b-product-details__accordion-content');

    // $(this).find('.b-product-details__accordion-title').preventDefault();
    if (content.hasClass('h-hidden')) {
        $('.collection-points .b-product-details__accordion-content').addClass('h-hidden');
        $('.collection-points .b-product-details__accordion-item').removeClass('expand').removeClass('active');
        content.removeClass('h-hidden');
        $(this).addClass('active');
    } else {
        content.addClass('h-hidden');
        $(this).removeClass('active');
    }
});
