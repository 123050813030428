'use strict';

import Component from '_core_ext/components/Component';
import {redirect as pageRedirect} from '_core_ext/page';
import {appendParamsToUrl} from '_core_ext/util';
import eventMgr from '_core_ext/eventMgr';

const emitter = eventMgr.getEmitter('deletecreditcard');

export default class CreditCardList extends Component {
    init() {
        super.init();

        this.deleteCreditCardEmitter = emitter;

        this.event('click', '.js-remove-saved-credit-card', this.removeCC);
        this.event('click', '.js-cancel', this.cancel);
    }

    getEmitter() {
        return emitter;
    }

    removeCC() {
        const hostedDataID = this.$el.find('.js-credit-card').data('hosted-id'),
            url = appendParamsToUrl(Urls.removeCreditCard, {
                format: 'ajax'
            });

        $.ajax({
            url: url,
            data: {
                hostedDataID: hostedDataID
            }
        }).done(function (data) {
            if (data) {
                let $updates = $(data).find('.js-credit-card-list-wrapper');

                $document.find('.js-credit-card-list-wrapper').replaceWith($updates);
            } else {
                pageRedirect(Urls.paymentsList);
            }
        });
        emitter.emit('closedialog');
    }

    cancel(element, event) {
        event.preventDefault();
        emitter.emit('closedialog');
    }
}
