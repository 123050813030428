/** The references object of all modules needed for components initialization */
module.exports = {
    /** Components from extension cartridge */

    dialog: require('_core_ext/components/common/Dialog'),
    informativeDialog: require('_core_ext/components/common/InformativeDialog'),
    inputCheckbox: require('_core_ext/components/forms/InputCheckbox'),
    inputEmail: require('_core_ext/components/forms/InputEmail'),
    inputHidden: require('_core_ext/components/forms/InputHidden'),
    inputPassword: require('_core_ext/components/forms/InputPassword'),
    inputRadio: require('_core_ext/components/forms/InputRadio'),
    inputSelect: require('_core_ext/components/forms/InputSelect'),
    inputText: require('_core_ext/components/forms/InputText'),
    inputTextarea: require('_core_ext/components/forms/InputTextarea'),
    inputNumber: require('_core_ext/components/forms/InputNumber'),
    form: require('_core_ext/components/forms/Form'),

    /** Page module references */
    scrollDown: require('_core_ext/components/global/ScrollDown'),
    ytPlayer: require('_core_ext/components/global/YTPlayer'),
    accordion: require('_core_ext/components/global/Accordion'),
    carousel: require('_core_ext/components/global/Carousel'),
    cookiesCheck: require('_core_ext/components/global/CookiesCheck'),

    dynamicImage: require('_core_ext/components/common/DynamicImage'),
    quantityFieldCMP: require('_core_ext/components/global/QuantityField'),

    accountMenu: require('_core_ext/components/header/AccountMenu'),
    miniCart: require('_core_ext/components/header/MiniCart'),
    miniCartTotal: require('_core_ext/components/header/MiniCartTotal'),
    navigationMenu: require('_core_ext/components/header/NavigationMenu'),
    search: require('_core_ext/components/header/Search'),
    mobileMenuToggler: require('_core_ext/components/header/MobileMenuToggler'),
    stickyHeader: require('_core_ext/components/header/StickyHeader'),
    miniWishlist: require('_core_ext/components/header/MiniWishlist'),

    refinementSearch: require('_core_ext/components/search/RefinementSearch'),
    searchPagination: require('_core_ext/components/search/SearchPagination'),
    deleteSelectedRefinement: require('_core_ext/components/search/DeleteSelectedRefinement'),

    filterForm: require('_core_ext/components/forms/FilterForm'),
    sortForm: require('_core_ext/components/forms/SortForm'),

    flightDetails: require('_core_ext/components/flightdetails/FlightDetails'),
    flightDetailsLink: require('_core_ext/components/flightdetails/FlightDetailsLink'),
    flightDetailsLinkAccountMenu: require('_core_ext/components/flightdetails/FlightDetailsLinkAccountMenu'),
    flightDetailsEducational: require('_core_ext/components/flightdetails/FlightDetailsEducational'),
    flightDetailsCalendar: require('_core_ext/components/flightdetails/FlightDetailsCalendar'),
    checkoutFlightDetailsCalendar: require('_core_ext/components/flightdetails/CheckoutFlightDetailsCalendar'),

    mobileCategoryNavigationToggler: require('_core_ext/components/category/MobileCategoryNavigationToggler'),
    cookiesHint: require('_core_ext/components/footer/CookiesHint'),
    productActionsCMP: require('_core_ext/components/product/ProductActions'),
    addToCartConfirmation: require('_core_ext/components/product/AddToCartConfirmation'),
    socialShare: require('_core_ext/components/global/SocialShare'),
    tooltip: require('_core_ext/components/global/Tooltip'),
    headerContentSlot: require('_core_ext/components/global/HeaderContentSlot'),
    orderHistoryItemsToggler: require('_core_ext/components/global/OrderHistoryItemsToggler'),

    confirmationCancellation: require('_core_ext/components/page/orderhistory/ConfirmationCancellation'),

    zoom: require('_core_ext/components/page/product/Zoom'),

    inputTextGV: require('_core_ext/components/product/InputTextGV'),
    gcForm: require('_core_ext/components/product/GiftCertificateForm'),
    addToCartBtn: require('_core_ext/components/product/AddToCart'),
    addToWishListBtn: require('_core_ext/components/product/AddToWishlist'),
    addToWishListProduct: require('_core_ext/components/product/AddToWishlistProduct'),
    productSearchResult: require('_core_ext/components/page/search/ProductSearchResult'),
    clearRefinement: require('_core_ext/components/page/search/ClearRefinement'),
    refinements: require('_core_ext/components/page/search/Refinements'),
    refinementsToggler: require('_core_ext/components/page/search/RefinementsToggler'),
    plpMobileColumnFilter: require('_core_ext/components/page/search/PlpMobileColumnFilter'),
    infiniteScroll: require('_core_ext/components/page/search/InfiniteScroll'),
    scrollToTop: require('_core_ext/components/page/search/ScrollToTop'),
    searchResultItems: require('_core_ext/components/page/search/SearchResultItems'),

    bonusProductModal: require('_core_ext/components/modal/BonusProductModal'),
    cart: require('_core_ext/components/cart/Cart'),

    contactUs: require('_core_ext/components/customerservice/ContactUs'),

    notifyMe: require('_core_ext/components/product/NotifyMe'),
    productSetActionsCMP: require('_core_ext/components/product/ProductSetActions'),
    creditCardList: require('_core_ext/components/account/CreditCardList'),
    reorder: require('_core_ext/components/account/Reorder'),
    loginForm: require('_core_ext/components/account/LoginForm'),
    subscribeForm: require('_core_ext/components/account/subscribeForm'),
    registerForm: require('_core_ext/components/account/RegisterForm'),
    flightDetailsStaff: require('_core_ext/components/flightdetails/FlightDetailsStaff'),
    mobileAccountNavigationToggler: require('_core_ext/components/account/MobileAccountNavigationToggler'),
    resetPassword: require('_core_ext/components/account/ResetPassword'),
    setPassword: require('_core_ext/components/account/SetPassword'),
    changePasswordForm: require('_core_ext/components/account/ChangePasswordForm'),
    deleteAccount: require('_core_ext/components/account/DeleteAccount'),
    billingForm: require('_core_ext/components/checkout/BillingForm'),
    authipayDialog: require('_core_ext/components/checkout/AuthipayDialog'),
    createEditAddress: require('_core_ext/components/account/CreateEditAddress'),
    checkoutOrderSummary: require('_core_ext/components/checkout/CheckoutOrderSummary'),
    wishlist: require('_core_ext/components/account/Wishlist'),
    wishlistBeforeAddPopup: require('_core_ext/components/account/WishlistBeforeAddPopup'),
    giftVoucher: require('_core_ext/components/global/GiftVoucher'),
    productTile: require('_core_ext/components/global/ProductTile'),
    promotionGtm: require('_core_ext/components/global/PromotionGtm'),
    deleteAddress: require('_core_ext/components/account/DeleteAddress'),
    productDetailsTabs: require('_core_ext/components/product/ProductDetailsTabs'),
    compareWidget: require('_core_ext/components/search/CompareWidget'),
    comparison: require('_core_ext/components/search/Comparison'),

    recaptcha: require('_core_ext/components/global/Recaptcha'),
    gtm: require('_core_ext/analytics/gtm'),

    navSlider: require('_core_ext/components/header/NavSlider'),
    inlineCalendar: require('_core_ext/components/flightdetails/InlineCalendar'),
    flightDetailsSteps: require('_core_ext/components/flightdetails/FlightDetailsSteps'),
    flightDetailsCountry: require('_core_ext/components/flightdetails/FlightDetailsCountry'),
    productColorSelector: require('_core_ext/components/product/ColorSelector'),
    changeAlcoholPrice: require('_core_ext/components/product/AlcoholPrice'),
    timePicker: require('_core_ext/components/checkout/TimePicker'),
    countrySelector: require('_core_ext/components/common/CountrySelector'),
    countrySelectListener: require('_core_ext/components/checkout/CountrySelectListener'),
    goBackShipping: require('_core_ext/components/checkout/GoBackShipping'),
    buttonDisabler: require('_core_ext/components/common/ButtonDisabler'),
    checkEmailAccount: require('_core_ext/components/checkout/CheckEmailAccount'),
    linksSelector: require('_core_ext/components/footer/LinksSelector'),
    calendar: require('_core_ext/components/global/Calendar'),
    languageSwitcherLink: require('_core_ext/components/common/LanguageSwitcherLink'),
    vtoTryItOn: require('_core_ext/components/product/VtoTryItOn'),
    vtoConsent: require('_core_ext/components/product/VtoConsent'),
    vtoLayout: require('_core_ext/components/product/VtoLayout'),
    variationSelect: require('_core_ext/components/product/VariationSelect')
};
