'use strict';
import 'eonasdan-bootstrap-datetimepicker';
import Component from '_core_ext/components/Component';
var moment = require('moment');

require('moment/min/locales.min');

export default class InlineCalendar extends Component {
    init() {
        super.init();
        var locale = $(document).find('.js-calendar-locale').eq(0).val().replace('_', '-').toLowerCase(),
        currDate = new Date(new Date().setHours(0)),
        selectedDate = new Date();

        $('.js-datetimepicker').datetimepicker({
            inline: true,
            sideBySide: true,
            minDate: currDate,
            maxDate: new Date(new Date().setMonth(currDate.getMonth() + 6)),
            defaultDate: selectedDate,
            format: 'LL'
        });
    /* jshint ignore:start */
        if (locale == 'zh-cn') {
            moment.locale('en-nz', {
                months: '一月_二月_三月_四月_五月_六月_七月_八月_九月_十月_十一月_十二月'.split('_'),
                monthsShort: '1月_2月_3月_4月_5月_6月_7月_8月_9月_10月_11月_12月'.split('_'),
                weekdays: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
                weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
                weekdaysMin: '日_一_二_三_四_五_六'.split('_')
            });
        } else {
            let days = 'S M T W T F S'.split(' ');

            moment.locale('en', {
                weekdaysShort: days,
                weekdaysMin: days
            });
        }
    /* jshint ignore:end */
        $('.js-datetimepicker')
        .data('DateTimePicker')
        .locale(moment.locale())
        .format('ddd, DD.MM.YYYY hh:mm A');

        $('.js-datetimepicker').on('dp.change', e => {
            $('.js-fd-button-calendar-date').text(e.date.format('dddd, DD MMMM YYYY')).attr('data-date', e.date.format('x'));
        });

        $('.js-fd-button-calendar-date').text(moment(selectedDate).format('dddd, DD MMMM YYYY')).attr('data-date', selectedDate.getTime());
    }
}

module.exports = InlineCalendar;