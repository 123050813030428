'use strict';
import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';
import 'eonasdan-bootstrap-datetimepicker';

const emitter = eventMgr.getEmitter('calendar');

export default class FlightDetailsCalendar extends Component {
    init() {
        super.init();
        this.eventMgr('flightdetails.showcalendar', function (date) {
            this.open(date);
        });
        this.event('click', '.js-back', this.hidecalendar);
        this.event('click', '.js-save-date', this.saveDateTime);
    }
    open(date) {
        $('.js-datetimepicker').datetimepicker({
            inline: true,
            sideBySide: true,
            minDate: Date()
        });

        $('.js-datetimepicker')
        .data('DateTimePicker')
        .format('ddd, DD.MM.YYYY hh:mm A')
        .date(date);
    }

    hidecalendar() {
        this.$el.addClass('h-hidden');
        emitter.emit('hidecalendarpicker');
    }

    saveDateTime() {
        let date = $('.js-datetimepicker')
            .data('DateTimePicker')
            .date()
            .format('ddd, DD.MM.YYYY hh:mm A');

        emitter.emit('dateselected', date);
        this.hidecalendar();
    }
}

module.exports = FlightDetailsCalendar;
