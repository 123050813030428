'use strict';

import Component from '_core_ext/components/Component';
import eventMgr from '_core_ext/eventMgr';

import {getCookie, setCookie} from '_core_ext/util';

const emitter = eventMgr.getEmitter('flightdetailslink');

export default class FlightDetailsLink extends Component {
    init() {
        super.init();
        this.initCache();
        this.updateFlightInfo();
        this.event('click', this.closeFlyoutIfClickOutside, $document);
        this.event('click', '.js-dismiss', this.dismissInfoPopup);
        this.event('click', '.js-flight-details-link-mobile', this.openFlyout);

        this.eventMgr('flightdetails.closeflyout', this.closeFlyout);
        this.eventMgr('flightdetails.reset', this.updateFlightInfo);
        this.eventMgr('flightdetails.citychanged', function (city) {
            this.updateCity(city);
        });
        this.eventMgr('flightdetails.citytypechanged', function (cityType) {
            this.updateCityType(cityType);
        });
        this.eventMgr('flightdetails.update', function (city, cityType) {
            this.updateFlightInfo(city, cityType);
        });
        this.eventMgr('dialog.close', function () {
            this.updateFlightInfo();
        });
        this.eventMgr('flightdetails.reloadflyoutcontent', function (url) {
            this.reloadFlyout(url);
        });
        this.eventMgr('searchbar.hidefdinfopopup', this.hideInfoPopup);
        this.eventMgr('searchbar.showfdinfopopup', this.showInfoPopup);
    }

    getEmitter() {
        return emitter;
    }

    initCache() {
        this.cache = {};
        this.cache.infoPopup = this.$el.find('.js-info-popup');
        this.cache.savedDetails = this.$el.find('.js-saved-details');
        this.cache.noSavedDetails = this.$el.find('.js-no-details');
    }

    //
    // Event handlers.
    //

    closeFlyoutIfClickOutside(element, event) {
        if (this.$el.find('.js-flight-details-flayout-mobile').children().length > 0) {
            if (!event.target.classList.contains('js-flight-details-flayout-mobile') &&
                $(event.target).parents('.js-flight-details-flayout-mobile').length === 0 ||
                event.target.classList.contains('js-overlay')) {
                    this.closeFlyout();
                    this.cache.noSavedDetails.removeClass('h-hidden');
                    this.cache.savedDetails.addClass('h-hidden');
            }
        }
    }

    openFlyout() {
        let self = this;

        $.ajax({
            url: Urls.flightDetailsShow
        }).done(function (html) {
            if (html) {
                self.$el.find('.js-flight-details-flayout-mobile').html(html);
                $(document).find('.js-flight-details-link-mobile').addClass('b-header-flight-details__mobile--open');
                $(document).find('#wrapper').addClass('b-wrapper b-wrapper--non-scrolling');
            }
        });
    }

    reloadFlyout(url) {
        let self = this;

        $.ajax({
            url: url
        }).done(function (html) {
            if (html) {
                self.$el.find('.js-flight-details-flayout-mobile').html(html);
                $(document).find('.js-flight-details-link-mobile').addClass('b-header-flight-details__mobile--open');
                $(document).find('#wrapper').addClass('b-wrapper b-wrapper--non-scrolling');
            }
        });
    }

    closeFlyout() {
        this.$el.find('.js-flight-details-flayout-mobile').html('');
        $(document).find('.js-flight-details-link-mobile').removeClass('b-header-flight-details__mobile--open');
        $(document).find('#wrapper').removeClass('b-wrapper b-wrapper--non-scrolling');
        this.updateFlightInfo();
    }

    updateCity(city) {
        this.cache.savedDetails.removeClass('h-hidden');
        this.cache.noSavedDetails.addClass('h-hidden');
        this.$el.find('.js-city-info').removeClass('h-hidden');
        var cityTitle = Resources['FLIGHT_DETAILS_' + city.toUpperCase()];

        this.$el.find('.js-city-update').text(cityTitle);
    }

    updateCityType(cityType) {
        var cityTypeSelectedClass = '.js-' + cityType;
        var cityTypeNotSelectedClass = '.js-departure';

        this.cache.savedDetails.removeClass('h-hidden');
        this.cache.noSavedDetails.addClass('h-hidden');

        if (cityType === 'departure') {
            cityTypeNotSelectedClass = '.js-arrival';
        }
        this.$el.find(cityTypeSelectedClass).removeClass('h-hidden');
        this.$el.find(cityTypeNotSelectedClass).addClass('h-hidden');
    }

    updateFlightInfo(city, cityType) {
        var fdCookie = getCookie('fd');
        var flightDetails;

        if (fdCookie) {
            try {
                flightDetails = JSON.parse(fdCookie.replace(/@/g, ','));
            } catch (e) {
                // Can't parse cookie, just update with default info
            }
        } else {
            emitter.emit('noflightdetails');
        }

        if (flightDetails) {
            this.cache.noSavedDetails.addClass('h-hidden');
            this.updateCity(city ? city : flightDetails.city);
            this.updateCityType(cityType ? cityType : flightDetails.cityType);
        } else {
            if (city && cityType) {
                this.cache.noSavedDetails.addClass('h-hidden');
                this.updateCity(city);
                this.updateCityType(cityType);
            } else {
                this.updateCity('dublin');
                this.updateCityType('departure');
                this.cache.noSavedDetails.removeClass('h-hidden');
                this.cache.savedDetails.addClass('h-hidden');
            }
        }
        var fdpCookie = getCookie('fdp');

        if (!fdpCookie) {
            this.cache.infoPopup.removeClass('h-hidden');
        }
    }

    dismissInfoPopup() {
        setCookie('fdp', true, 43200);
        this.cache.infoPopup.addClass('h-hidden');
    }

    hideInfoPopup() {
        this.cache.infoPopup.addClass('b-header-flight-details__popup--search-focus');
    }

    showInfoPopup() {
        this.cache.infoPopup.removeClass('b-header-flight-details__popup--search-focus');
    }

    onMouseover() {
        this._fadeInPopup();
    }

    onMouseout() {
        this._fadeOutPopup();
    }

    //
    // Internally used functions.
    //

    _fadeInPopup() {
        this.cache.infoPopup
            .stop(true)
            .fadeIn(this.config.fadeInSpeed);
    }

    _fadeOutPopup() {
        this.cache.infoPopup
            .stop(true)
            .fadeOut(this.config.fadeOutSpeed);
    }
}

module.exports = FlightDetailsLink;
