'use strict';

import Component from '_core_ext/components/Component';
import Constants from '_core_ext/components/Constants';
import {assign} from 'lodash';

let defaultConfig = Constants.YT.defaultConfig;

export default class YTPlayer extends Component {
    init() {
        let self = this;

        super.init();

        self.initialized = false;
        self.config = assign({}, defaultConfig, self.config);

        self.eventMgr('carousel.sliderchanged', this._stopAllVideos);
        self.eventMgr('zoom.close', this._stopAllVideos);

        if (self.config.thumbnail === '0') { // Show video player immediately.
            self._onYouTubeIframeAPIReadyEventHandler();
            self._initYTPlayerAPIScript();

            if ('isZoom' in self.config && self.config.isZoom) {
                self._initYouTubePlayerInstance();
            }
        } else { // Init opening video by click on thumbnail.
            self._initYTPlayerAPIScript();
            self.event('click', self.$el, function (el, event) {
                event.preventDefault();
                if (!self.initialized || window.YT) {
                    self._initYouTubePlayerInstance();
                }
            });
        }
    }

    _initYTPlayerAPIScript() {
        if ($document.find('#' + Constants.YT.ID).length === 0 && !window.YT && !this.initialized) {
            let ytScript = document.createElement('script'),
                firstScriptTag = document.getElementsByTagName('script')[0];

            ytScript.src = Constants.YT.apiLink;
            ytScript.id = Constants.YT.ID;

            // Init global function which will be called by yt api after itself initialization.
            window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
                let event;

                // This expression needs for correct work in IE.
                if (typeof (Event) === 'function') {
                    event = new Event('youTubeIframeAPIReady');
                } else {
                    event = document.createEvent('Event');
                    event.initEvent('youTubeIframeAPIReady', true, true);
                }

                document.dispatchEvent(event);
            };

            firstScriptTag.parentNode.insertBefore(ytScript, firstScriptTag);
        }
    }

    /**
     * This function stops all youtube video player.
     * It need to correctly stop video in zoom in PDP.
     * As video for zoom clones from the page, it's due to not possible to init
     * two videos with the same ID.
     */
    _stopAllVideos() {
        $document.find('[data-cmp="carousel"], [data-cmp="zoom"]')
        .find('[data-cmp="ytPlayer"] iframe').each(function (idx, iframe) {
            if (iframe) {
                let content = iframe.contentWindow || iframe.contentDocument;

                if (content) {
                    content.postMessage(JSON.stringify({
                        event: 'command',
                        func: 'stopVideo'
                    }), '*');
                }
            }
        });
    }

    _onYouTubeIframeAPIReadyEventHandler() {
        let self = this;

        $document.on('youTubeIframeAPIReady', function () {
            self._initYouTubePlayerInstance();
        });
    }
    /* jshint ignore:start */
    _initYouTubePlayerInstance() {
        let self = this;

        if (window.YT) {
            let config = {
                height: self.config.height,
                width: self.config.width,
                videoId: self.config.videoID,
                playerVars: {
                    autoplay: self.config.autoplay,
                    controls: self.config.controls,
                    disablekb: self.config.disablekb,
                    loop: self.config.loop,
                    modestbranding: self.config.modestbranding,
                    showinfo: self.config.showinfo,
                    color: self.config.color,
                    theme: self.config.theme,
                    rel: self.config.rel,
                    mute: self.config.mute
                }
            };

            this.$el.html('<div id="' + self.config.elementID + '"></div>');

            // Clone video to the zoom popup from main carousel.
            if ('isZoom' in self.config && self.config.isZoom) {
                let ytPlayer = window.$document.find('iframe[id="' + self.config.elementID + '"]');

                if (ytPlayer) {
                    ytPlayer.clone().appendTo(this.$el.find('#' + self.config.elementID));
                }
            }

            new window.YT.Player(self.config.elementID, config);

            self.initialized = true;
        }
    }
    /* jshint ignore:end */
}

module.exports = YTPlayer;
